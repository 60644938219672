import React from "react";
import {Helmet} from "react-helmet";

export const A1Meta = () => (
    <Helmet>
            <title>Jonathan Armour | Vieunite</title>
            <meta name="description"
                  content="Johnathan Armours practice builds from a previous career in engineering and is an interplay between digital and oil-based works, including mappings of non-normative bodies, and digital investigations of the human body as a celebrated, objectified, abstracted form."/>
            <meta name="keywords"
                  content="digital canvas, digital frame, digital art frame,  digital art canvas,picture frame,  frame, the frame, smart frame, smart digital art frame,  digital art, art, photography frame, artworks, gallery, photo frame, digital photo frame, textura, texturite technology, VT27WGV1, vieunite, digital photo to canvas, digital picture frame, best digital picture frame, electronic photo frame, wifi picture frame, video picture frame"/>
            <meta name="twitter:card" value="summary_large_image"/>
            <meta name="twitter:site" value="@VieuniteHQ"/>
            <meta name="twitter:title" value="Jonathan Armour"/>
            <meta name="twitter:description"
                  value="Johnathan Armours practice builds from a previous career in engineering and is an interplay between digital and oil-based works, including mappings of non-normative bodies, and digital investigations of the human body as a celebrated, objectified, abstracted form."/>
            <meta name="twitter:url" value="https://vieunite.com/jonathanarmour"/>
            <meta name="twitter:image" value={'https://vieunite.com/img/Social Card - Jonathan Armour.jpg'}/>

            <meta property="og:url" content="https://vieunite.com/jonathanarmour"/>
            <meta property="og:type" content="article"/>
            <meta property="og:title" content="Jonathan Armour"/>
            <meta property="og:description"
                  content="Johnathan Armours practice builds from a previous career in engineering and is an interplay between digital and oil-based works, including mappings of non-normative bodies, and digital investigations of the human body as a celebrated, objectified, abstracted form."/>
            <meta property="og:image" content={'https://vieunite.com/img/Social Card - Jonathan Armour.jpg'}/>
            <meta property="og:image:width" content="930"/>
            <meta property="og:image:height" content="620"/>
    </Helmet>);