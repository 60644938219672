import React from "react";
import {Helmet} from "react-helmet";

export const A11Meta = () => (
    <Helmet>
            <title> Guille Giagante | Vieunite</title>
            <meta name="description"
                  content="He considers his photography as an 'autobiographical photography' intertwined with his life and fed by the emotions that his interaction with the world triggers."/>
            <meta name="keywords"
                  content="digital canvas, digital frame, digital art frame,  digital art canvas,picture frame,  frame, the frame, smart frame, smart digital art frame,  digital art, art, photography frame, artworks, gallery, photo frame, digital photo frame, textura, texturite technology, VT27WGV1, vieunite, digital photo to canvas, digital picture frame, best digital picture frame, electronic photo frame, wifi picture frame, video picture frame"/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:site" content="@VieuniteHQ"/>
            <meta name="twitter:title" content="Guille Giagante | Vieunite"/>
            <meta name="twitter:description"
                  value="He considers his photography as an 'autobiographical photography' intertwined with his life and fed by the emotions that his interaction with the world triggers."/>
            <meta name="twitter:url" content="https://vieunite.com/guillegiagante"/>
            <meta name="twitter:image" content={'https://vieunite.com/img/Social Card - Guillermo Giagante.jpg'}/>
            <meta property="og:url" content="https://vieunite.com/guillegiagante"/>
            <meta property="og:type" content="article"/>
            <meta property="og:title" content="Guille Giagante | Vieunite"/>
            <meta property="og:description"
                  content="He considers his photography as an 'autobiographical photography' intertwined with his life and fed by the emotions that his interaction with the world triggers."/>
            <meta property="og:image" content="https://vieunite.com/img/Social Card - Guillermo Giagante.jpg"/>
            <meta property="og:image:width" content="930"/>
            <meta property="og:image:height" content="620"/>
    </Helmet>);