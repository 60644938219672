export const FeatureText = {
    'Standard': [
        ['Up to 2 Textura canvases', 'Standard plan allows for media to be displayed concurrently on up to 2 Textura Pro.'],
        ['Display own media up to 500MB', 'Allows for display of own media, including Full HD images and videos.'],
        ['Classic Creative Commons artwork (CC)', 'Access to free artworks under the Creative Commons license.'],
        ['Purchase artwork as priced', 'Paid artwork outside the Creative Commons license.'],
        ['Email & telephone support', 'Customer service support through email and telephone, depending on the subscribed plan.'],
    ],
    'Advanced': [
        ['Up to 10 Textura canvases', 'Advanced plan allows for media to be displayed concurrently on up to 10 Textura Pro.'],
        ['Display own media up to 4GB', 'Allows for display of own media, including Full HD images and videos.'],
        ['Classic Creative Commons artwork (CC)', 'Access to free artworks under the Creative Commons license.'],
        ['Unlimited artwork', 'Unlimited access to paid artwork outside the Creative Commons license.'],
        ['Advanced artwork playlist (CC)', 'Curated artwork playlist delivered on a regular basis, under the Creative Commons license.'],
        ['Priority email & telephone support', 'Customer service support through email and telephone, depending on the subscribed plan.'],
        ['Allocated services manager', 'A dedicated customer services manager will be assigned to provide WhatsApp (WA) Business support under terms of the service level agreement (SLA).'],
    ],
    'Premium': [
        ['Unlimited canvases', 'Premium plan allows for media to be displayed on an unlimited number of Textura Pro.'],
        ['Display own media (unlimited)', 'Allows for unlimited display of own media, including Full HD images and videos.'],
        ['Classic Creative Commons artwork (CC)', 'Access to free artworks under the Creative Commons license.'],
        ['Unlimited artwork', 'Unlimited access to paid artwork outside the Creative Commons license.'],
        ['Advanced artwork playlist (CC)', 'Curated artwork playlist delivered on a regular basis, under the Creative Commons license.'],
        ['Unlimited Premium Playlist', 'Premium-level playlist including paid artwork not included under the Creative Commons license.'],
        ['Quarterly content curation**', 'Art curation on a 3-monthly basis.'],
        ['Platform Management Services', 'Access to Vieunite Pro’s content management service (CMS).'],
        ['Dedicated Account Manager', 'A dedicated account manager will be assigned to manage CMS for the subscriber.'],
        ['Allocated Services Manager', 'A dedicated customer services manager will be assigned to provide WhatsApp (WA) Business support under terms of the service level agreement (SLA).'],
        ['Allocated Designer', 'A dedicated design team member will be assigned, depending on subscribed plan.'],
    ]
}

export const QAText = [
    ['How does the subscription plan work with Textura Pro?',
        'When you purchase a subscription plan for Vieunite Pro, it automatically links to your user account and the Textura Pro device, assigning the right number of licenses to the number of Textura Pros purchased. This streamlined process ensures businesses can now tailor the licenses to their specific needs when deploying Textura Pro displays. In addition, it allows for effortless remote management of all Textura Pro displays through your Vieunite Pro platform.\n' +
        'Additionally, selecting a subscription plan is essential for you to pair all your Textura Pros under your user account. While our Standard plan is a free subscription, it is limited to displaying contents on up to two Textura Pro devices. It is important to note that your subscription plan license is linked to both the Textura Pro hardware and your user account. This means all hardware under each user account must be covered by the same subscription plan, so as to grant you access to all the features offered under the Standard (free), Advanced and Premium subscription plans.\n'],
    ['Do I need a subscription plan for every Textura Pro?',
        'Yes, you need a Vieunite Pro subscription plan license for every Textura Pro device. All subscription license links to your account rather than the Textura Pro device itself. This setup allows for flexibility in assigning required number of licenses to the Textura Pro units. '],
    ['Can I purchase a Textura Pro for my business without a subscription plan?',
        'No, Vieunite Pro subscription plans licenses are absolutely required to unlock the functionality of every Textura Pro device. That is to say, every Textura Pro must be linked with a subscription plan license so that it can be controlled via the Vieunite Pro Platform, where the Textura Pro device can then access all the fantastic features, including the art library, and marketplace artworks etc.'],
    ['How can I purchase additional Textura Pro digital canvases and subscription plans as my business needs grows?',
        'Every Textura Pro device requires a subscription plan license which is linked to your user account. The subscription plan must be the same for all Textura Pro devices so as to allow smooth control through the Vieunite Pro Platform. For example, say if you have subscribed to an annual Premium Plan for 10 Textura Pros on 1 Jun 2024 which ends on 31 May 2025. If at any time during  your subscription period, your business needs grow and you wish to increase the number of Textura Pro from 10  to 20 devices at the same Premium subscription plan,  kindly reach out to us via email (support@vieunite.com) or phone (0121-421-4458). Our dedicated Commercial Support team will assist you in acquiring 10 more Textura Pros (total of 20 devices). We will stack on 10 Premium subscription licenses (total of 20 licenses) at a pro-rated basis to your account, which ends on the same date of your first purchase, i.e., 31 May 2025. Rest assured that you will only be charged a pro-rated subscription for the additional 10 licenses for the remaining subscription period. For all queries, we are always happy to assist at support@vieunite.com  and 0121-421-4458 during office hours.'],
    ['Do you offer trials for Vieunite Pro subscription plans?',
        'Yes, by default, we offer all customers a no-obligation, thirty (30) day free trial to all paid Advanced and Premium Plans. Kindly note that there is no free trial for the Standard Plan as it is already offered free to users for content display on up to two (2) Textura Pro devices. \n' +
        'For the trial period, you will be contacted by email seven (7) days before trial ends to confirm your purchase of Products to link with your desired plans. Payment is done through an authorised FCA-compliant third-party payment provider. \n' +
        'Once you have subscribed to our plans, the minimum subscription period is one year. You will be contacted by email thirty (30) days before subscription period ends to remind you about renewal of your subscription plans. As part of our service commitment, we also further send you a reminder email seven (7) days subscription period ends to confirm the renewal of your plans. \n'],
]