export const TexturaProText = {
    'top': ['Textura Pro', 'The Textura Pro digital canvas uses specially developed texture-accurate display technology to authentically show every brush stroke and intricacy of artwork in great detail. It has an integrated mounting plate that allows for flush wall mounting and allows you to change between landscape and portrait orientations. Choose from four real wood frame finish options to suit any décor. Our Vieunite Pro Portal gives you full control of your Textura Pro management.'],
    'l1': ['Creating Luxury Spaces', 'Vieunite Pro allows you to elevate the atmosphere and ambiance of your business in a completely new and unique way, opening the doors to a world of digital art. Not only can you keep the artwork that is being displayed fresh by updating it as often as you want, but you can also change the frame finish and orientation of your Textura Pro as and when you like.'],
    'r1': ['Give Your Business the Edge', 'Capitalise on all the advantages that displaying art in your business brings, at a fraction of the cost. Our ground breaking platform allows you to enrich your customer experience and improve their overall experience exponentially. You can also strengthen your brand awareness and identity in a completely original way, giving you the advantage over your competition.'],
    'l2': ['Innovative New Display Channel/Medium', 'By replicating the surface textures of traditional artwork, we have created a medium that is almost indistinguishable from the real thing. As well as hanging art on your walls, it also captivates and stimulates your customers so that you can deliver other messaging, such as promotional advertising, in an original and unique way.'],
    'r2': ['Enhance Your Team', 'When displayed in the workplace, art has been proved to enhance creativity and mood, improve productivity and reduce stress of employees. Vieunite Pro also offers the opportunity to communicate and involve with your staff in an engaging way, creating a new internal communications channel.']
}


export const ICON_LIST = [
    [<svg xmlns="http://www.w3.org/2000/svg" width="41" height="40" viewBox="0 0 41 40"
          fill="none">
        <path opacity="0.45"
              d="M20.2521 40C31.2139 40 40.2617 30.9478 40.2617 20C40.2617 9.05223 31.1945 0 20.2327 0C9.29023 0 0.261719 9.05223 0.261719 20C0.261719 30.9478 9.30957 40 20.2521 40ZM20.2521 36.0155C11.3782 36.0155 4.28298 28.8781 4.28298 20C4.28298 11.1219 11.3782 4.00387 20.2327 4.00387C29.1066 4.00387 36.2405 11.1219 36.2598 20C36.2791 28.8781 29.1259 36.0155 20.2521 36.0155ZM10.5082 22.4371H20.2327C21.122 22.4371 21.7987 21.7602 21.7987 20.8897V8.23985C21.7987 7.36944 21.122 6.69246 20.2327 6.69246C19.3821 6.69246 18.7054 7.36944 18.7054 8.23985V19.3424H10.5082C9.63823 19.3424 8.96157 20.0193 8.96157 20.8897C8.96157 21.7602 9.63823 22.4371 10.5082 22.4371Z"
              fill="#1C1C1E"/>
    </svg>,
        '24-7', 'Commercial Use'],
    [<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
        <path opacity="0.45"
              d="M19.9903 40C30.9522 40 40 30.9478 40 20C40 9.05223 30.9328 0 19.971 0C9.02852 0 0 9.05223 0 20C0 30.9478 9.04785 40 19.9903 40ZM19.9903 36.0155C11.1165 36.0155 4.02127 28.8781 4.02127 20C4.02127 11.1219 11.1165 4.00387 19.971 4.00387C28.8449 4.00387 35.9787 11.1219 35.9981 20C36.0174 28.8781 28.8642 36.0155 19.9903 36.0155ZM19.9903 28.3946C27.8975 28.3946 33.2721 22.0116 33.2721 20.0193C33.2721 18.0464 27.8975 11.6441 19.9903 11.6441C12.1025 11.6441 6.66989 18.0464 6.66989 20.0193C6.66989 22.0116 12.1411 28.3946 19.9903 28.3946ZM19.9903 25.4545C16.9744 25.4545 14.5384 22.9787 14.5191 20.0193C14.5191 17.0019 16.9744 14.5841 19.9903 14.5841C22.9869 14.5841 25.4229 17.0019 25.4229 20.0193C25.4229 22.9787 22.9869 25.4545 19.9903 25.4545ZM20.0097 22.3985C21.2856 22.3985 22.3683 21.2959 22.3683 20.0193C22.3683 18.7427 21.2856 17.6402 20.0097 17.6402C18.6757 17.6402 17.593 18.7427 17.593 20.0193C17.593 21.2959 18.6757 22.3985 20.0097 22.3985Z"
              fill="#1C1C1E"/>
    </svg>, ' Texture-', 'Accuracy'],
    [<svg xmlns="http://www.w3.org/2000/svg" width="40" height="31" viewBox="0 0 40 31" fill="none">
        <path opacity="0.45"
              d="M2.66729 13.0757C3.02716 13.4946 3.59266 13.4763 3.96967 13.0393C8.2024 8.28613 13.7718 5.77298 19.9923 5.77298C26.2643 5.77298 31.8508 8.28613 36.0664 13.0757C36.4263 13.4581 36.9747 13.4399 37.3345 13.0393L39.7337 10.4715C40.0764 10.1073 40.0764 9.63377 39.8022 9.28775C35.6723 3.879 27.978 0 19.9923 0C12.0239 0 4.3124 3.86079 0.199626 9.28775C-0.0745588 9.63377 -0.0745588 10.1073 0.251036 10.4715L2.66729 13.0757ZM9.83037 20.6516C10.2416 21.0704 10.79 21.034 11.1842 20.5787C13.2748 18.1566 16.6164 16.4448 20.0095 16.463C23.4197 16.4448 26.7613 18.1931 28.9034 20.6334C29.2632 21.0704 29.7773 21.0522 30.1715 20.6334L32.8619 17.8288C33.1875 17.501 33.2218 17.0458 32.9305 16.6815C30.2571 13.2396 25.3732 10.7629 20.0095 10.7629C14.6286 10.7629 9.74469 13.2396 7.07139 16.6815C6.78006 17.0458 6.81434 17.4828 7.13993 17.8288L9.83037 20.6516ZM20.0095 30.7406C20.4208 30.7406 20.7806 30.5403 21.4832 29.8301L25.6817 25.5322C25.9901 25.2226 26.0587 24.7127 25.7845 24.3485C24.5849 22.7277 22.4086 21.4165 20.0095 21.4165C17.5418 21.4165 15.3312 22.7823 14.1488 24.4942C13.9431 24.822 14.046 25.2226 14.3373 25.5322L18.5357 29.8301C19.2212 30.5221 19.5982 30.7406 20.0095 30.7406Z"
              fill="#1C1C1E"/>
    </svg>, 'Remote', 'Management'],
    [<svg xmlns="http://www.w3.org/2000/svg" width="21" height="36" viewBox="0 0 21 36" fill="none">
        <g opacity="0.45">
            <path d="M2.14697 14.9941L2.14697 2.00068L7.7156 2.00068" stroke="black" stroke-width="4"/>
            <path d="M2.14697 20.562L2.14697 33.5555L7.7156 33.5555" stroke="black" stroke-width="4"/>
            <path d="M18.8528 14.9941L18.8528 2.00068L13.2842 2.00068" stroke="black" stroke-width="4"/>
            <path d="M18.8528 20.562L18.8528 33.5555L13.2842 33.5555" stroke="black" stroke-width="4"/>
        </g>
    </svg>, '4 Frame', 'Options'],
    [<svg xmlns="http://www.w3.org/2000/svg" width="43" height="36" viewBox="0 0 43 36" fill="none">
        <g opacity="0.45">
            <rect x="41.25" y="1.75" width="32.4564" height="16.0884" transform="rotate(90 41.25 1.75)" fill="#F4F4F4"
                  stroke="black" stroke-width="3.5"/>
            <rect x="32.3462" y="26.6362" width="30.3345" height="17.3169" transform="rotate(-180 32.3462 26.6362)"
                  fill="#F4F4F4" stroke="black" stroke-width="3.5"/>
        </g>
    </svg>, 'Landscape', 'or Portrait'],
    [<svg width="66" height="49" viewBox="0 0 66 60" fill="none"
          style={{marginTop: '-0.8vh'}}
          xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.45">
            <path
                d="M42.2137 58.7475V44.9148C45.1455 42.0994 47 38.0078 47 33.4657C47.0176 24.907 40.5006 18 32.5 18C24.4994 18 18 24.907 18 33.4657C18 38.1016 19.9251 42.2871 22.9805 45.1024V58.7475C22.9805 60.0613 23.7046 60.6432 24.6054 60.6432C25.2941 60.6432 25.8063 60.2678 26.3362 59.7047L31.9172 53.8113C32.1821 53.5297 32.394 53.4359 32.6059 53.4359C32.8002 53.4359 33.0122 53.5297 33.2771 53.8113L38.8581 59.7047C39.4056 60.2866 39.9354 60.6432 40.6065 60.6432C41.5073 60.6432 42.2137 60.0613 42.2137 58.7475ZM32.5176 45.1963C26.3715 45.1775 21.5676 39.9597 21.5676 33.4657C21.5676 26.9528 26.3715 21.735 32.5176 21.735C38.6461 21.735 43.45 26.9528 43.4677 33.4657C43.4854 39.9597 38.6461 45.1963 32.5176 45.1963Z"
                fill="#1C1C1E"/>
            <path
                d="M28.3925 37.1003L29.2885 36.0603C29.9605 36.8763 31.0645 37.4203 32.2645 37.4203C33.7365 37.4203 34.6165 36.7163 34.6165 35.5963C34.6165 34.4123 33.6565 33.9163 32.1045 33.9163C31.6725 33.9163 31.1125 33.9163 30.9525 33.9323V32.4763C31.1285 32.4923 31.6885 32.4923 32.1045 32.4923C33.4005 32.4923 34.4565 32.0123 34.4565 30.9083C34.4565 29.8203 33.4325 29.2443 32.1685 29.2443C31.0485 29.2443 30.1685 29.6763 29.3845 30.4763L28.5365 29.4843C29.3365 28.5403 30.6005 27.8203 32.3125 27.8203C34.4885 27.8203 36.0565 28.8923 36.0565 30.6843C36.0565 32.1723 34.7925 32.9563 33.7365 33.1483C34.7445 33.2443 36.2165 34.0763 36.2165 35.7563C36.2165 37.5483 34.7285 38.8443 32.3125 38.8443C30.4565 38.8443 29.0805 38.0603 28.3925 37.1003Z"
                fill="black"/>
        </g>
    </svg>, '3 Year', 'Warranty'],
]