import React from "react";
import {Helmet} from "react-helmet";

export const A6Meta = () => (
    <Helmet>
            <title>Barbara | Vieunite</title>
            <meta name="description"
                  content="Her recent paintings celebrate movement and freedom in the natural world with loosely applied dry pastel and transparent acrylic on wood."/>
            <meta name="keywords"
                  content="digital canvas, digital frame, digital art frame,  digital art canvas,picture frame,  frame, the frame, smart frame, smart digital art frame,  digital art, art, photography frame, artworks, gallery, photo frame, digital photo frame, textura, texturite technology, VT27WGV1, vieunite, digital photo to canvas, digital picture frame, best digital picture frame, electronic photo frame, wifi picture frame, video picture frame"/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:site" content="@VieuniteHQ"/>
            <meta name="twitter:title" content="Barbara"/>
            <meta name="twitter:description"
                  content="Her recent paintings celebrate movement and freedom in the natural world with loosely applied dry pastel and transparent acrylic on wood."/>
            <meta name="twitter:url" content="https://vieunite.com/barbara"/>
            <meta name="twitter:image" content={'https://vieunite.com/img/cardBarbara.jpg'}/>

            <meta property="og:url" content="https://vieunite.com/barbara"/>
            <meta property="og:type" content="article"/>
            <meta property="og:title" content="Barbara"/>
            <meta property="og:description"
                  content="Her recent paintings celebrate movement and freedom in the natural world with loosely applied dry pastel and transparent acrylic on wood."/>
            <meta property="og:image" content={'https://vieunite.com/img/cardBarbara.jpg'}/>
            <meta property="og:image:width" content="930"/>
            <meta property="og:image:height" content="620"/>
    </Helmet>);