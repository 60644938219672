import React from 'react';
import {Col, Row} from 'antd';
import './PageFooter.css';
import {ReactComponent as EmailIcon} from "../../img/SVG/email.svg";
import {ReactComponent as PhoneIcon} from "../../img/SVG/phone-call.svg";

const getLastDayOfMonth = () => {
    const date = new Date();
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const day = lastDay.getDate();
    const suffix = (day) => {
        if (day > 3 && day < 21) return 'th';
        switch (day % 10) {
            case 1:
                return "st";
            case 2:
                return "nd";
            case 3:
                return "rd";
            default:
                return "th";
        }
    }

    const formattedDate = `${day}${suffix(day)} ${lastDay.toLocaleDateString('en-GB', {
        month: 'long',
        year: 'numeric'
    })}`;
    return formattedDate;
}
const ContactRow = () => {
    return (
        <Row justify={"center"} style={{backgroundColor: 'white'}}> {/* Set background to black */}
            <div className={"footer-container"}
                 style={{width: '100%', height: '4rem', margin: '2vh 8vw', color: '#000'}}>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexWrap: "nowrap"
                }}>
                    {/* Contact Text Section */}
                    <p className={'primary-font-footer'} style={{
                        fontSize: '18px',
                        fontWeight: '600',
                        lineHeight: '25px',
                        marginBottom: 0,
                    }}>
                        Contact the Vieunite Team
                        <span className={'primary-font-footer'} style={{
                            color: '#A9A9A9',
                            fontSize: '18px',
                            fontWeight: '400',
                            marginLeft: '0.5vw',
                        }}>
                                    Mon to Fri 9am-5pm (GMT)
                                </span>
                    </p>

                    {/* Contact Info Section (Email and Phone) */}
                    <div style={{display: "flex", alignItems: "center", columnGap: "0.5vw", flexWrap: "nowrap"}}>
                        {/* Email Section */}
                        <a href="mailto:info@vieunite.com"
                           style={{color: '#000', display: "flex", alignItems: "center", columnGap: '5px'}}>
                            <EmailIcon/>
                            <span className={'primary-font-footer'} style={{
                                fontWeight: '600',
                                fontSize: '18px',
                                paddingRight: '2vw'
                            }}>info@vieunite.com</span>
                        </a>

                        {/* Phone Section */}
                        <div style={{display: "flex", alignItems: "center", columnGap: '0.5vw'}}>
                            <PhoneIcon/>
                            <span className={'primary-font-footer'} style={{color: "black"}}>
                                    <a style={{color: "black", fontWeight: '600', fontSize: '16px'}}
                                       href="tel:+44 (0)3455481311">{'+44 (0)3455481311'}</a>
                                </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"footer-container-mobile"}
                 style={{
                     backgroundColor: "white",
                     justify: "center",


                 }}>
                <p className={'primary-font-footer'} style={{
                    textAlign: "center",
                    color: '#000',
                    fontWeight: '600',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    margin: '0'
                }}>
                    Contact the Vieunite Team
                </p>
                <p className={'primary-font-footer'} style={{
                    color: "#A9A9A9",
                    textAlign: "center",
                    fontWeight: '400',
                    fontSize: '18px',
                    fontStyle: 'normal'

                }}>
                    Mon to Fri 9am-5pm (GMT)
                </p>
                <Row style={{justifyContent: "center", alignItems: "center"}}>
                    <div style={{
                        paddingTop: '0.5vw',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}>
                        {/* Email Section */}
                        <a href="mailto:info@vieunite.com" style={{
                            display: 'flex',
                            alignItems: 'center',
                            textDecoration: 'none',
                        }}>
                            <EmailIcon/>
                            <span className={'primary-font-footer'} style={{
                                color: '#000',
                                fontWeight: '600',
                                fontSize: '18px',

                                lineHeight: '25px'
                            }}>
                                info@vieunite.com
                            </span>
                        </a>
                    </div>
                </Row>
                <Row style={{justifyContent: "center", alignItems: "center"}}>
                    <div style={{
                        paddingTop: '2vw',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingBottom: '5vw'
                    }}>
                        {/* Phone Section */}
                        <a href="tel:+44 (0)3455481311" style={{
                            display: 'flex',
                            alignItems: 'center',
                            textDecoration: 'none',
                        }}>
                            <PhoneIcon/>
                            <span className={'primary-font-footer'} style={{
                                color: '#000',
                                fontWeight: '600',
                                fontSize: '18px',

                                lineHeight: '25px'
                            }}>
                                +44 (0)3455481311
                            </span>
                        </a>
                    </div>

                </Row>
            </div>
        </Row>
    )
}
export const PortalFooter = () =>
    <Row justify={"center"} style={{backgroundColor: '#828282',}}>
        <Col lg={8} xs={22} style={{display: "flex"}}>
            <div style={{width: '100%'}}>
                <Row style={{
                    display: 'grid',
                    gridTemplateColumns: '2fr 1fr 1fr',
                    justifyItems: 'center',
                    padding: '1rem',
                }}>
                    <div style={{color: 'white', fontSize: '0.8rem', fontWeight: '600'}}>
                        &copy;2023 Vieunite Limited All Rights Reserved.
                    </div>
                    <div style={{fontSize: '0.8rem', fontWeight: '600'}}>
                        <a href={"/terms-and-conditions"} style={{color: 'white'}} className={"footer-text"}>
                            Terms and Conditions
                        </a>
                    </div>
                    <div style={{fontSize: '0.8rem', fontWeight: '600'}}>
                        <a href={"/end-user-agreement"} style={{color: 'white'}} className={"footer-text"}>
                            End User Agreement
                        </a>
                    </div>
                </Row>
            </div>
        </Col>
    </Row>
export const BottomRow = () => {
    return (
        <Row justify={"center"} style={{backgroundColor: 'black'}}> {/* Set background to black */}

            <div className={"footer-container"} style={{width: '100%'}}>
                <Row justify={"start"} style={{marginBottom: '2vw', marginTop: '2vw', marginLeft: "8vw"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="154" height="27" viewBox="0 0 154 27"
                         fill="none">
                        <path
                            d="M10.2151 26.3009L0.332031 0.714844H6.53638L13.6192 20.3711L20.7021 0.714844H26.9064L17.0234 26.3009H10.2151Z"
                            fill="white"/>
                        <path
                            d="M28.6641 2.85509C28.6641 1.26283 29.9818 0 31.5741 0C33.1663 0 34.4841 1.26283 34.4841 2.85509C34.4841 4.44736 33.1663 5.76509 31.5741 5.76509C30.0367 5.76509 28.6641 4.50226 28.6641 2.85509ZM29.1582 7.7966H34.0448V26.2998H29.1582V7.7966Z"
                            fill="white"/>
                        <path
                            d="M37.6719 17.021C37.6719 11.6403 41.6251 7.30273 47.2804 7.30273C52.8259 7.30273 56.5595 11.4207 56.5595 17.5152V18.6133H42.7781C43.1075 20.9742 44.9743 22.8959 48.104 22.8959C49.6962 22.8959 51.8376 22.1822 53.0455 21.0292L55.1868 24.1588C53.32 25.8609 50.41 26.7393 47.5549 26.7393C41.9545 26.7393 37.6719 22.9509 37.6719 17.021ZM47.2804 11.201C44.2606 11.201 42.9428 13.4522 42.7232 15.319H51.8376C51.7277 13.5071 50.4649 11.201 47.2804 11.201Z"
                            fill="white"/>
                        <path
                            d="M72.5341 26.3001V23.9391C71.2713 25.3667 69.0202 26.7393 66.0003 26.7393C61.9373 26.7393 60.0156 24.4882 60.0156 20.9193V7.79688H64.9022V19.0525C64.9022 21.6331 66.22 22.4567 68.3064 22.4567C70.1732 22.4567 71.6556 21.4135 72.5341 20.3703V7.85178H77.4207V26.355H72.5341V26.3001Z"
                            fill="white"/>
                        <path
                            d="M94.7685 26.2979V15.0972C94.7685 12.5166 93.4508 11.6381 91.3644 11.6381C89.4427 11.6381 87.9602 12.7362 87.1366 13.8343V26.2979H82.25V7.79472H87.1366V10.2106C88.3445 8.78301 90.5957 7.35547 93.6155 7.35547C97.6785 7.35547 99.6551 9.66151 99.6551 13.2853V26.3528H94.7685V26.2979Z"
                            fill="white"/>
                        <path
                            d="M103.938 2.85509C103.938 1.26283 105.255 0 106.847 0C108.44 0 109.758 1.26283 109.758 2.85509C109.758 4.44736 108.44 5.76509 106.847 5.76509C105.31 5.76509 103.938 4.50226 103.938 2.85509ZM104.432 7.7966H109.318V26.2998H104.432V7.7966Z"
                            fill="white"/>
                        <path
                            d="M115.083 21.6886V12.0251H112.008V7.79741H115.083V2.74609H119.969V7.79741H123.703V12.0251H119.969V20.3708C119.969 21.5238 120.573 22.4023 121.671 22.4023C122.385 22.4023 123.099 22.1278 123.373 21.8533L124.416 25.5319C123.703 26.1908 122.385 26.7399 120.353 26.7399C116.949 26.7399 115.083 24.9829 115.083 21.6886Z"
                            fill="white"/>
                        <path
                            d="M125.898 17.021C125.898 11.6403 129.852 7.30273 135.507 7.30273C141.052 7.30273 144.786 11.4207 144.786 17.5152V18.6133H131.005C131.334 20.9742 133.201 22.8959 136.331 22.8959C137.923 22.8959 140.064 22.1822 141.272 21.0292L143.413 24.1588C141.547 25.8609 138.637 26.7393 135.781 26.7393C130.181 26.7393 125.898 22.9509 125.898 17.021ZM135.507 11.201C132.487 11.201 131.169 13.4522 130.95 15.319H140.064C139.954 13.5071 138.691 11.201 135.507 11.201Z"
                            fill="white"/>
                        <path
                            d="M147.699 23.6101C147.699 21.908 149.127 20.4805 150.829 20.4805C152.531 20.4805 153.958 21.908 153.958 23.6101C153.958 25.3122 152.531 26.7397 150.829 26.7397C149.127 26.7397 147.699 25.3122 147.699 23.6101Z"
                            fill="white"/>
                    </svg>
                </Row>

                <Row style={{
                    display: 'flex',
                    justifyItems: 'flex-start',
                    marginBottom: '2vh',
                    marginTop: '-1vh',
                    marginLeft: "8vw",
                    marginRight: "8vw"
                }}>

                    <div className={'primary-font-footer'} style={{
                        color: 'white',

                        display: 'flex',
                    }}>
                        <a href="/terms-and-conditions" style={{color: 'white', fontWeight: '600', fontSize: '16px'}}
                           className="footer-text">
                            Terms & Conditions
                        </a>
                        <a href="/end-user-agreement" style={{color: 'white', fontWeight: '600', fontSize: '16px'}}
                           className="footer-text">
                                <span style={{
                                    marginLeft: '2vw',
                                }}>
                                End User Agreement
                                </span>
                        </a>
                        <a href="/artists-terms-and-conditions"
                           style={{color: 'white', fontWeight: '600', fontSize: '16px'}}
                           className="footer-text">
                                <span style={{
                                    marginLeft: '2vw',
                                }}>
                                Artist's Terms & Conditions
                                </span>
                        </a>
                    </div>
                    <div className="footer-spacer-class" style={{flex: 1}}></div>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',     // Ensure icons are aligned to the right
                        gap: '18px',

                    }}>
                        <a className="social-icon-btn"
                           href={"https://www.instagram.com/vieunite/?hl=en"}
                           target="_blank"
                           rel="noopener noreferrer"
                           style={{
                               display: 'flex',
                               justifyContent: 'center',
                               alignItems: 'center',

                               margin: 0,
                               padding: 0,
                           }}>


                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                 fill="none">
                                <g clip-path="url(#clip0_993_730)">
                                    <path
                                        d="M23.6455 5.8887V18.1019C23.5519 18.5254 23.4972 18.9606 23.359 19.3702C22.5536 21.7511 20.3194 23.3132 17.6973 23.3208C13.7803 23.3312 9.86326 23.3264 5.94626 23.3222C4.68809 23.3208 3.52854 22.9877 2.51006 22.2739C0.826508 21.0945 0.0016479 19.4909 0.000928122 17.4956C0.000208349 13.8451 -0.00195097 10.1952 0.00452699 6.54529C0.00452699 6.17079 0.0239609 5.79076 0.0930591 5.42315C0.490374 3.32923 1.73126 1.86914 3.7819 1.06978C4.30805 0.864936 4.89251 0.797345 5.45033 0.666992C9.69915 0.666992 13.948 0.666992 18.1961 0.666992C18.3616 0.69527 18.5279 0.721478 18.6927 0.751825C20.8628 1.14426 22.383 2.32227 23.2187 4.27274C23.4367 4.78173 23.5073 5.34867 23.6455 5.88939V5.8887ZM21.6611 11.996C21.6611 11.996 21.6668 11.996 21.6697 11.996C21.6697 10.1524 21.6661 8.30885 21.6719 6.46529C21.6733 5.87491 21.5905 5.30039 21.3156 4.77001C20.5692 3.32923 19.3528 2.56849 17.6706 2.56297C13.7688 2.54987 9.86686 2.55815 5.96569 2.5609C5.73608 2.5609 5.50503 2.5816 5.27687 2.61263C3.48607 2.85403 2.00334 4.40585 1.99182 6.14389C1.96519 10.0007 1.98102 13.8568 1.97887 17.7136C1.97887 18.2378 2.08611 18.7433 2.32652 19.2109C3.083 20.6793 4.32245 21.4283 6.03335 21.4304C9.88917 21.4359 13.7443 21.4331 17.6001 21.4304C17.8607 21.4304 18.1227 21.4111 18.3811 21.3773C20.1373 21.1462 21.6308 19.5778 21.6546 17.8805C21.6827 15.9197 21.6611 13.9575 21.6611 11.996Z"
                                        fill="white"/>
                                    <path
                                        d="M17.7344 12.0232C17.7035 15.1599 15.0223 17.6987 11.7834 17.6587C8.51343 17.618 5.88338 15.0682 5.91433 11.9673C5.94528 8.82919 8.62428 6.29317 11.8654 6.33248C15.1361 6.37248 17.7654 8.9223 17.7352 12.0232H17.7344ZM11.828 8.22433C9.65283 8.22088 7.89803 9.89753 7.88867 11.9866C7.87931 14.0675 9.62764 15.7531 11.8093 15.7669C13.9873 15.78 15.7623 14.0854 15.7601 11.9935C15.758 9.90719 14.0053 8.22777 11.828 8.22433Z"
                                        fill="white"/>
                                    <path
                                        d="M18.2398 7.26716C17.4171 7.27268 16.7578 6.64574 16.7578 5.85811C16.7578 5.08288 17.4056 4.45595 18.2175 4.44353C19.0193 4.43112 19.7074 5.08495 19.7067 5.8588C19.7067 6.62988 19.0481 7.26095 18.2398 7.26647V7.26716Z"
                                        fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_993_730">
                                        <rect width="23.6453" height="22.6601" fill="white"
                                              transform="translate(0 0.666992)"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </a>
                        <a className="social-icon-btn"
                           href="https://www.youtube.com/channel/UClpqq46UCV1Ojkfef0HijFQ"
                           target="_blank"
                           rel="noopener noreferrer"
                           style={{
                               display: 'flex',
                               justifyContent: 'center',
                               alignItems: 'center',
                               margin: 0,
                               padding: 0,
                           }}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="22" viewBox="0 0 30 22"
                                 fill="none">
                                <g clip-path="url(#clip0_993_734)">
                                    <path
                                        d="M0.644531 14.7469C0.644531 11.9321 0.644531 9.11738 0.644531 6.30143C0.681559 5.92263 0.713958 5.54265 0.755614 5.16385C0.84124 4.38958 1.00555 3.63914 1.39087 2.95421C2.00066 1.86905 2.99578 1.37113 4.11355 1.0781C5.33661 0.756483 6.58976 0.675483 7.84639 0.6731C12.1983 0.667145 16.5502 0.664762 20.9021 0.669527C21.7942 0.669527 22.6875 0.688586 23.5773 0.740998C24.5435 0.796983 25.4993 0.942307 26.4157 1.28656C27.5855 1.7261 28.3978 2.52419 28.7889 3.76183C29.1002 4.74813 29.1812 5.76778 29.1893 6.79219C29.2101 9.2901 29.2321 11.7892 29.1904 14.2871C29.1742 15.2663 29.0527 16.2549 28.863 17.215C28.4915 19.0887 27.4293 20.3645 25.5988 20.9041C24.5527 21.2126 23.4824 21.3377 22.404 21.3413C17.6992 21.3591 12.9932 21.3544 8.2884 21.3556C7.00169 21.3556 5.72425 21.265 4.48151 20.891C3.13232 20.4848 2.04348 19.732 1.41054 18.3907C1.02869 17.5807 0.84934 16.7135 0.754457 15.8249C0.718586 15.4664 0.681559 15.1066 0.644531 14.7469ZM11.6417 10.8684C11.6417 11.8928 11.6394 12.9161 11.6417 13.9405C11.644 14.8684 12.3198 15.2984 13.1136 14.8732C15.032 13.8452 16.9471 12.81 18.8655 11.7832C19.2324 11.5867 19.4753 11.308 19.473 10.8672C19.4696 10.4265 19.2196 10.1549 18.8528 9.95954C16.9401 8.94228 15.0309 7.91548 13.1182 6.89583C12.3279 6.47415 11.644 6.90655 11.6417 7.82376C11.6394 8.83745 11.6417 9.85234 11.6417 10.866V10.8684Z"
                                        fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_993_734">
                                        <rect width="28.5714" height="20.6896" fill="white"
                                              transform="translate(0.644531 0.666992)"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </a>
                        <a className="social-icon-btn"
                           href={"https://www.facebook.com/Vieunite-106534841663393"}
                           target="_blank"
                           rel="noopener noreferrer"
                           style={{
                               display: 'flex',
                               justifyContent: 'center',
                               alignItems: 'center',
                               margin: 0,
                               padding: 0,
                           }}>

                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="23" viewBox="0 0 12 23"
                                 fill="none">
                                <path
                                    d="M3.42843 22.3416C3.42505 22.2506 3.4183 22.1596 3.4183 22.0669C3.4183 18.9632 3.4183 15.8596 3.4183 12.7577V12.4603H0.214844V8.60607H3.41662C3.41662 8.4976 3.41662 8.41538 3.41662 8.33315C3.41999 7.32542 3.39636 6.3142 3.43687 5.30822C3.48582 4.09406 3.83182 2.98136 4.65884 2.07336C5.28839 1.38405 6.07154 0.993903 6.95764 0.796207C7.67664 0.63875 8.40578 0.657994 9.13322 0.685987C9.72395 0.708731 10.3147 0.762966 10.9037 0.803205C10.9493 0.806704 10.9966 0.8207 11.0523 0.831197V4.26726C10.9814 4.26726 10.9105 4.26726 10.8396 4.26726C10.1122 4.27776 9.38302 4.26726 8.65726 4.30575C7.79817 4.35124 7.31376 4.84635 7.27663 5.74211C7.2395 6.6711 7.26313 7.6036 7.26144 8.53434C7.26144 8.54659 7.26988 8.55884 7.28507 8.59558H10.9324C10.7721 9.89197 10.6134 11.1586 10.4548 12.4498H7.27495C7.26819 12.532 7.25807 12.5932 7.25807 12.6527C7.25807 15.8334 7.25807 19.014 7.25807 22.1946C7.25807 22.2436 7.26482 22.2926 7.26819 22.3416H3.43012H3.42843Z"
                                    fill="white"/>
                            </svg>
                        </a>
                        <a className="social-icon-btn"
                           href={"https://twitter.com/VieuniteHQ"}
                           target="_blank"
                           rel="noopener noreferrer"
                           style={{
                               display: 'flex',
                               justifyContent: 'center',
                               alignItems: 'center',
                               margin: 0,
                               padding: 0,
                           }}>

                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="23" viewBox="0 0 22 23"
                                 fill="none">
                                <g clip-path="url(#clip0_993_737)">
                                    <path
                                        d="M21.0264 0.666992C19.8428 2.01513 18.6597 3.36398 17.4752 4.71141C16.0179 6.36913 14.5591 8.02592 13.1011 9.68317C13.0589 9.731 13.0184 9.78048 12.9625 9.84621C15.8788 13.9964 18.7959 18.1476 21.7293 22.322C21.6505 22.3289 21.5977 22.3374 21.5449 22.3374C19.518 22.3381 17.4911 22.3362 15.4641 22.3416C15.3215 22.3421 15.2473 22.2921 15.1704 22.1826C13.2912 19.5025 11.4084 16.8249 9.52575 14.147C9.4778 14.0786 9.42769 14.0117 9.3672 13.9286C8.8159 14.5546 8.27976 15.1629 7.74412 15.7717C5.85814 17.9155 3.97313 20.0602 2.08234 22.1998C2.01825 22.2721 1.89416 22.3296 1.79657 22.3319C1.23514 22.3444 0.673225 22.3381 0.0546875 22.3381C2.8922 19.1126 5.70056 15.9206 8.51614 12.7204C8.4747 12.6577 8.43831 12.5993 8.39856 12.543C5.67598 8.66771 2.95292 4.79293 0.230827 0.917206C0.174443 0.836864 0.126734 0.750632 0.0751689 0.666992C2.22523 0.666992 4.37505 0.666992 6.52511 0.666992C6.54198 0.697621 6.55644 0.73037 6.57644 0.758879C8.39253 3.34395 10.2091 5.92879 12.025 8.51409C12.052 8.5525 12.0722 8.59561 12.1009 8.64556C14.4656 5.958 16.7937 3.31238 19.1216 0.666992C19.7566 0.666992 20.3915 0.666992 21.0264 0.666992ZM19.1055 20.9788C19.0373 20.8801 18.9944 20.8156 18.9494 20.7527C17.7807 19.1178 16.6121 17.4832 15.4432 15.8485C12.2136 11.3317 8.98288 6.81561 5.75887 2.29503C5.63646 2.12351 5.51189 2.06343 5.30226 2.06649C4.50204 2.07875 3.70134 2.07121 2.90088 2.07191C2.83317 2.07191 2.76546 2.07898 2.67438 2.08393C2.72425 2.15979 2.75654 2.21328 2.79293 2.26393C7.21955 8.45637 11.6469 14.6481 16.0711 20.8422C16.1506 20.9534 16.2328 20.9956 16.3714 20.9944C17.208 20.988 18.0446 20.9913 18.8812 20.9909C18.9433 20.9909 19.0055 20.9845 19.1057 20.9788H19.1055Z"
                                        fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_993_737">
                                        <rect width="21.6748" height="21.6748" fill="white"
                                              transform="translate(0.0546875 0.666992)"/>
                                    </clipPath>
                                </defs>
                            </svg>
                        </a>
                        <a className="social-icon-btn"
                           href={"https://www.linkedin.com/company/vieunite/"}
                           target="_blank"
                           rel="noopener noreferrer"
                           style={{
                               display: 'flex',
                               justifyContent: 'center',
                               alignItems: 'center',
                               margin: 0,
                               padding: 0,
                           }}>

                            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25"
                                 fill="none">
                                <path
                                    d="M13.292 24.3123H11.8142C11.7631 24.2994 11.7132 24.2778 11.6615 24.2747C10.6596 24.2038 9.67811 24.0194 8.73661 23.6703C4.4429 22.0796 1.83761 19.0338 0.91766 14.5504C0.828375 14.1162 0.791429 13.6709 0.730469 13.2305C0.730469 12.7376 0.730469 12.2454 0.730469 11.7526C0.744016 11.686 0.75941 11.6194 0.771725 11.5522C0.906577 10.7972 0.979852 10.025 1.18429 9.28917C3.25017 1.86302 11.7834 -1.61136 18.4786 2.27565C21.6344 4.10813 23.5309 6.88061 24.1978 10.4771C24.276 10.899 24.3172 11.3277 24.3757 11.7533V13.1848C24.3628 13.2588 24.3468 13.3322 24.3363 13.4069C24.2723 13.8707 24.2316 14.3395 24.1424 14.7983C23.2218 19.5575 19.3708 23.3175 14.5882 24.131C14.1584 24.2044 13.7237 24.2525 13.2914 24.3123H13.292ZM10.752 10.4173V18.7045H13.2982C13.2982 18.6021 13.2982 18.512 13.2982 18.422C13.2982 17.1292 13.2945 15.837 13.3006 14.5442C13.3025 14.0816 13.3388 13.6209 13.5513 13.1959C13.8283 12.642 14.3733 12.3972 15.1178 12.4891C15.6375 12.5532 15.9909 12.8986 16.1042 13.4994C16.1627 13.8072 16.1916 14.1248 16.1941 14.4388C16.204 15.7772 16.199 17.1162 16.1996 18.4547C16.1996 18.5361 16.1996 18.6169 16.1996 18.7002H18.7674C18.7723 18.6243 18.7791 18.5638 18.7791 18.504C18.7791 17.0268 18.7951 15.549 18.7705 14.0724C18.7606 13.4833 18.7046 12.8875 18.59 12.3102C18.3973 11.3369 17.856 10.614 16.8567 10.3531C16.4453 10.2458 16.0057 10.1946 15.5808 10.2051C14.6171 10.2285 13.8265 10.6184 13.2908 11.4523C13.2779 11.472 13.2532 11.4849 13.1935 11.5349V10.4167H10.752V10.4173ZM9.10052 18.7032V10.4241H6.5525V18.7032H9.10052ZM7.81543 9.26697C8.65287 9.26512 9.32466 8.58973 9.31912 7.75398C9.31358 6.93735 8.62454 6.25888 7.80742 6.2669C6.98415 6.27492 6.31728 6.9503 6.31789 7.77434C6.31851 8.60083 6.98846 9.26882 7.81481 9.26697H7.81543Z"
                                    fill="white"/>
                            </svg>
                        </a>
                        <a className="social-icon-btn"
                           href={"https://www.pinterest.co.uk/vieunite/"}
                           target="_blank"
                           rel="noopener noreferrer"
                           style={{
                               display: 'flex',
                               justifyContent: 'center',
                               alignItems: 'center',
                               margin: 0,
                               padding: 0,
                           }}>

                            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="23" viewBox="0 0 19 23"
                                 fill="none">
                                <path
                                    d="M4.42601 22.3418C4.37155 22.1336 4.29626 21.9286 4.26689 21.7173C4.10137 20.5355 3.98017 19.3458 4.13234 18.1572C4.22151 17.4622 4.40625 16.7778 4.57338 16.095C4.98292 14.4196 5.40954 12.7484 5.82121 11.0735C5.84737 10.9681 5.83349 10.8378 5.79558 10.7339C5.36148 9.54847 5.28727 8.36511 5.85165 7.20295C6.20672 6.47196 6.76202 5.95074 7.59605 5.78388C8.71947 5.55929 9.648 6.27438 9.70994 7.40847C9.74251 8.01021 9.59781 8.58388 9.42748 9.15172C9.16425 10.0284 8.89033 10.9013 8.62016 11.7759C8.12946 13.3628 9.4152 14.427 10.789 14.3322C12.29 14.2289 13.313 13.411 14.0418 12.1747C14.6457 11.1508 14.8988 10.0236 14.9634 8.84714C15.0099 8.00121 14.9373 7.17435 14.6329 6.37503C14.138 5.07567 13.2473 4.12486 11.9771 3.5745C9.98386 2.71162 7.96714 2.75399 6.02945 3.7207C4.28718 4.58994 3.25827 6.03814 2.98329 7.96678C2.79053 9.3154 3.10289 10.5549 3.93745 11.6498C4.05065 11.7981 4.08055 11.9501 4.03409 12.1249C3.91449 12.5741 3.79916 13.0238 3.67688 13.472C3.59145 13.7845 3.4617 13.8417 3.16109 13.7019C2.45148 13.3713 1.91273 12.8469 1.49198 12.2044C0.527669 10.7366 0.173663 9.13 0.484954 7.40318C1.06962 4.15982 3.05003 2.13212 6.16347 1.16436C8.09208 0.56474 10.0672 0.503294 12.0305 1.00174C14.1625 1.5431 15.8968 2.68301 17.0645 4.56769C17.8265 5.79765 18.1628 7.15157 18.1025 8.6003C18.0422 10.0501 17.7149 11.4305 17.0026 12.7044C15.9822 14.5298 14.4909 15.7788 12.4181 16.264C11.2984 16.5257 10.1793 16.5331 9.10071 16.0479C8.58171 15.8143 8.12038 15.5092 7.79788 15.0298C7.7824 15.0065 7.77278 14.9795 7.73701 14.9059C7.52984 15.658 7.31359 16.335 7.15821 17.0247C6.72518 18.9464 5.76568 20.6123 4.65507 22.2094C4.62357 22.2549 4.5878 22.2984 4.55416 22.3423H4.42601V22.3418Z"
                                    fill="white"/>
                            </svg>
                        </a>

                    </div>
                </Row>
                <Row style={{
                    display: 'flex',
                    justifyItems: 'center',
                    marginBottom: '2vw',
                    marginLeft: "8vw",
                    marginRight: "8vw"
                }}>
                    <div className={'primary-font-footer'} style={{
                        color: 'white',
                        fontSize: '16px',
                        justifySelf: "start",
                        fontWeight: '400',
                        marginRight: '2vw',
                    }}> {/* Text set to white */}
                        &copy;2024 Vieunite Limited All Rights Reserved
                    </div>
                    <div className={'primary-font-footer'} style={{
                        color: 'white',
                        fontSize: '16px',
                        justifySelf: "start",
                        marginRight: '2vw',
                        fontWeight: '400'
                    }}> {/* Text set to white */}
                        VAT Registration No. 411723238
                    </div>
                    <div className={'primary-font-footer'} style={{
                        color: 'white',
                        fontSize: '16px',
                        justifySelf: "start",

                        fontWeight: '400'
                    }}> {/* Text set to white */}
                        Company Registration No. 13883746
                    </div>
                </Row>
            </div>

            <div className={"footer-container-mobile"}
                 style={{backgroundColor: 'black'}}> {/* Background set to black */}

                <Row justify={"start"}
                     style={{marginBottom: '5vw', marginTop: '10vw', marginLeft: '8vw', marginRight: "8vw"}}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="116" height="21" viewBox="0 0 116 21"
                         fill="none">
                        <path
                            d="M7.41228 19.7266L0 0.537109H4.65326L9.96539 15.2793L15.2775 0.537109H19.9308L12.5185 19.7266H7.41228Z"
                            fill="white"/>
                        <path
                            d="M21.248 2.14132C21.248 0.947121 22.2363 0 23.4305 0C24.6247 0 25.6131 0.947121 25.6131 2.14132C25.6131 3.33552 24.6247 4.32382 23.4305 4.32382C22.2775 4.32382 21.248 3.3767 21.248 2.14132ZM21.6187 5.84745H25.2836V19.7249H21.6187V5.84745Z"
                            fill="white"/>
                        <path
                            d="M28.0039 12.7653C28.0039 8.72973 30.9688 5.47656 35.2103 5.47656C39.3694 5.47656 42.1696 8.56501 42.1696 13.1359V13.9595H31.8336C32.0807 15.7302 33.4808 17.1715 35.828 17.1715C37.0222 17.1715 38.6282 16.6361 39.5341 15.7714L41.1401 18.1186C39.74 19.3952 37.5575 20.054 35.4162 20.054C31.2159 20.054 28.0039 17.2127 28.0039 12.7653ZM35.2103 8.40029C32.9454 8.40029 31.9571 10.0886 31.7924 11.4887H38.6282C38.5458 10.1298 37.5987 8.40029 35.2103 8.40029Z"
                            fill="white"/>
                        <path
                            d="M54.1506 19.7251V17.9544C53.2035 19.025 51.5151 20.0545 49.2503 20.0545C46.203 20.0545 44.7617 18.3661 44.7617 15.6895V5.84766H48.4267V14.2894C48.4267 16.2248 49.415 16.8425 50.9798 16.8425C52.3799 16.8425 53.4917 16.0601 54.1506 15.2777V5.88884H57.8156V19.7662H54.1506V19.7251Z"
                            fill="white"/>
                        <path
                            d="M70.8264 19.7225V11.3219C70.8264 9.38648 69.8381 8.72761 68.2733 8.72761C66.832 8.72761 65.7201 9.55119 65.1025 10.3748V19.7225H61.4375V5.84506H65.1025V7.65694C66.0084 6.58628 67.6968 5.51562 69.9616 5.51562C73.0089 5.51562 74.4913 7.24515 74.4913 9.96299V19.7636H70.8264V19.7225Z"
                            fill="white"/>
                        <path
                            d="M77.7031 2.14132C77.7031 0.947121 78.6914 0 79.8856 0C81.0798 0 82.0681 0.947121 82.0681 2.14132C82.0681 3.33552 81.0798 4.32382 79.8856 4.32382C78.7326 4.32382 77.7031 3.3767 77.7031 2.14132ZM78.0737 5.84745H81.7387V19.7249H78.0737V5.84745Z"
                            fill="white"/>
                        <path
                            d="M86.0638 16.2674V9.01984H83.7578V5.84904H86.0638V2.06055H89.7288V5.84904H92.529V9.01984H89.7288V15.2791C89.7288 16.1438 90.1818 16.8027 91.0054 16.8027C91.5407 16.8027 92.076 16.5968 92.2819 16.3909L93.0643 19.1499C92.529 19.6441 91.5407 20.0559 90.0171 20.0559C87.4639 20.0559 86.0638 18.7381 86.0638 16.2674Z"
                            fill="white"/>
                        <path
                            d="M94.1758 12.7653C94.1758 8.72973 97.1407 5.47656 101.382 5.47656C105.541 5.47656 108.341 8.56501 108.341 13.1359V13.9595H98.0055C98.2525 15.7302 99.6526 17.1715 102 17.1715C103.194 17.1715 104.8 16.6361 105.706 15.7714L107.312 18.1186C105.912 19.3952 103.729 20.054 101.588 20.054C97.3878 20.054 94.1758 17.2127 94.1758 12.7653ZM101.382 8.40029C99.1173 8.40029 98.129 10.0886 97.9643 11.4887H104.8C104.718 10.1298 103.771 8.40029 101.382 8.40029Z"
                            fill="white"/>
                        <path
                            d="M110.525 17.7085C110.525 16.432 111.596 15.3613 112.873 15.3613C114.149 15.3613 115.22 16.432 115.22 17.7085C115.22 18.9851 114.149 20.0558 112.873 20.0558C111.596 20.0558 110.525 18.9851 110.525 17.7085Z"
                            fill="white"/>
                    </svg>
                </Row>
                <Row justify={"start"} style={{marginBottom: '5vw', marginLeft: '8vw', marginRight: "8vw"}}>
                    <a className="social-icon-btn"
                       href="https://www.instagram.com/vieunite/?hl=en"
                       target="_blank"
                       rel="noopener noreferrer"
                       style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           margin: 0,
                           padding: 0,
                       }}>


                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23"
                             fill="none">
                            <g clip-path="url(#clip0_1371_79)">
                                <path
                                    d="M23.6455 5.22171V17.4349C23.5519 17.8584 23.4972 18.2936 23.359 18.7032C22.5536 21.0841 20.3194 22.6463 17.6973 22.6538C13.7803 22.6642 9.86326 22.6594 5.94626 22.6552C4.68809 22.6538 3.52854 22.3207 2.51006 21.6069C0.826508 20.4275 0.0016479 18.8239 0.000928122 16.8286C0.000208349 13.1781 -0.00195097 9.52819 0.00452699 5.8783C0.00452699 5.5038 0.0239609 5.12377 0.0930591 4.75616C0.490374 2.66224 1.73126 1.20214 3.7819 0.402784C4.30805 0.197944 4.89251 0.130353 5.45033 0C9.69915 0 13.948 0 18.1961 0C18.3616 0.0282776 18.5279 0.0544862 18.6927 0.0848329C20.8628 0.477271 22.383 1.65528 23.2187 3.60574C23.4367 4.11474 23.5073 4.68167 23.6455 5.2224V5.22171ZM21.6611 11.329C21.6611 11.329 21.6668 11.329 21.6697 11.329C21.6697 9.48543 21.6661 7.64186 21.6719 5.7983C21.6733 5.20791 21.5905 4.6334 21.3156 4.10302C20.5692 2.66224 19.3528 1.9015 17.6706 1.89598C13.7688 1.88288 9.86686 1.89115 5.96569 1.89391C5.73608 1.89391 5.50503 1.9146 5.27687 1.94564C3.48607 2.18703 2.00334 3.73886 1.99182 5.4769C1.96519 9.33369 1.98102 13.1898 1.97887 17.0466C1.97887 17.5708 2.08611 18.0763 2.32652 18.5439C3.083 20.0123 4.32245 20.7613 6.03335 20.7634C9.88917 20.7689 13.7443 20.7661 17.6001 20.7634C17.8607 20.7634 18.1227 20.7441 18.3811 20.7103C20.1373 20.4792 21.6308 18.9108 21.6546 17.2135C21.6827 15.2527 21.6611 13.2905 21.6611 11.329Z"
                                    fill="white"/>
                                <path
                                    d="M17.7344 11.3552C17.7035 14.492 15.0223 17.0307 11.7834 16.9907C8.51343 16.9501 5.88338 14.4002 5.91433 11.2994C5.94528 8.16122 8.62428 5.6252 11.8654 5.66451C15.1361 5.70452 17.7654 8.25433 17.7352 11.3552H17.7344ZM11.828 7.55636C9.65283 7.55291 7.89803 9.22957 7.88867 11.3187C7.87931 13.3995 9.62764 15.0851 11.8093 15.0989C13.9873 15.112 15.7623 13.4174 15.7601 11.3256C15.758 9.23922 14.0053 7.55981 11.828 7.55636Z"
                                    fill="white"/>
                                <path
                                    d="M18.2379 6.59919C17.4152 6.60471 16.7559 5.97777 16.7559 5.19014C16.7559 4.41492 17.4037 3.78798 18.2156 3.77556C19.0174 3.76315 19.7055 4.41698 19.7048 5.19083C19.7048 5.96191 19.0462 6.59298 18.2379 6.5985V6.59919Z"
                                    fill="white"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_1371_79">
                                    <rect width="23.6453" height="22.6601" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </a>
                    <a className="social-icon-btn"
                       href="https://www.youtube.com/channel/UClpqq46UCV1Ojkfef0HijFQ"
                       target="_blank"
                       rel="noopener noreferrer"
                       style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           margin: 0,
                           padding: 0,
                           paddingLeft: '4vw'
                       }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="21" viewBox="0 0 30 21"
                             fill="none">
                            <g clip-path="url(#clip0_1371_83)">
                                <path
                                    d="M0.644531 14.0799C0.644531 11.2651 0.644531 8.45039 0.644531 5.63444C0.681559 5.25564 0.713958 4.87565 0.755614 4.49686C0.84124 3.72259 1.00555 2.97215 1.39087 2.28722C2.00066 1.20205 2.99578 0.70414 4.11355 0.41111C5.33661 0.0894909 6.58976 0.0084906 7.84639 0.00610824C12.1983 0.000152341 16.5502 -0.00223002 20.9021 0.0025347C21.7942 0.0025347 22.6875 0.0215936 23.5773 0.0740055C24.5435 0.129991 25.4993 0.275315 26.4157 0.619566C27.5855 1.05911 28.3978 1.8572 28.7889 3.09484C29.1002 4.08114 29.1812 5.10079 29.1893 6.1252C29.2101 8.62311 29.2321 11.1222 29.1904 13.6201C29.1742 14.5993 29.0527 15.5879 28.863 16.548C28.4915 18.4218 27.4293 19.6975 25.5988 20.2371C24.5527 20.5456 23.4824 20.6707 22.404 20.6743C17.6992 20.6921 12.9932 20.6874 8.2884 20.6886C7.00169 20.6886 5.72425 20.598 4.48151 20.224C3.13232 19.8178 2.04348 19.065 1.41054 17.7237C1.02869 16.9137 0.84934 16.0465 0.754457 15.1579C0.718586 14.7994 0.681559 14.4396 0.644531 14.0799ZM11.6417 10.2014C11.6417 11.2258 11.6394 12.2491 11.6417 13.2735C11.644 14.2014 12.3198 14.6314 13.1136 14.2062C15.032 13.1782 16.9471 12.143 18.8655 11.1162C19.2324 10.9197 19.4753 10.641 19.473 10.2002C19.4696 9.75949 19.2196 9.4879 18.8528 9.29255C16.9401 8.27528 15.0309 7.24848 13.1182 6.22883C12.3279 5.80716 11.644 6.23956 11.6417 7.15676C11.6394 8.17046 11.6417 9.18534 11.6417 10.199V10.2014Z"
                                    fill="white"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_1371_83">
                                    <rect width="28.5714" height="20.6896" fill="white"
                                          transform="translate(0.644531)"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </a>
                    <a className="social-icon-btn"
                       href={"https://www.facebook.com/Vieunite-106534841663393"}
                       target="_blank"
                       rel="noopener noreferrer"
                       style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           margin: 0,
                           padding: 0,
                           paddingLeft: '4vw'
                       }}>

                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="22" viewBox="0 0 12 22"
                             fill="none">
                            <path
                                d="M3.43038 21.6746C3.42701 21.5836 3.42026 21.4926 3.42026 21.3999C3.42026 18.2963 3.42026 15.1926 3.42026 12.0907V11.7933H0.216797V7.93908H3.41857C3.41857 7.83061 3.41857 7.74838 3.41857 7.66616C3.42194 6.65843 3.39831 5.64721 3.43882 4.64123C3.48777 3.42706 3.83377 2.31437 4.6608 1.40637C5.29035 0.717054 6.07349 0.326911 6.95959 0.129215C7.6786 -0.0282424 8.40773 -0.00899769 9.13517 0.0189947C9.72591 0.0417385 10.3166 0.0959736 10.9057 0.136213C10.9513 0.139712 10.9985 0.153708 11.0542 0.164205V3.60027C10.9833 3.60027 10.9124 3.60027 10.8415 3.60027C10.1141 3.61076 9.38497 3.60027 8.65921 3.63876C7.80012 3.68424 7.31572 4.17936 7.27859 5.07511C7.24145 6.00411 7.26508 6.93661 7.2634 7.86735C7.2634 7.8796 7.27183 7.89184 7.28702 7.92858H10.9344C10.774 9.22498 10.6154 10.4916 10.4567 11.7828H7.2769C7.27015 11.865 7.26002 11.9262 7.26002 11.9857C7.26002 15.1664 7.26002 18.347 7.26002 21.5276C7.26002 21.5766 7.26677 21.6256 7.27015 21.6746H3.43207H3.43038Z"
                                fill="white"/>
                        </svg>
                    </a>
                    <a className="social-icon-btn"
                       href={"https://twitter.com/VieuniteHQ"}
                       target="_blank"
                       rel="noopener noreferrer"
                       style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           margin: 0,
                           padding: 0,
                           paddingLeft: '4vw'
                       }}>

                        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22"
                             fill="none">
                            <g clip-path="url(#clip0_1371_86)">
                                <path
                                    d="M21.0264 0C19.8428 1.34814 18.6597 2.69699 17.4752 4.04442C16.0179 5.70214 14.5591 7.35893 13.1011 9.01618C13.0589 9.06401 13.0184 9.11349 12.9625 9.17922C15.8788 13.3294 18.7959 17.4806 21.7293 21.655C21.6505 21.6619 21.5977 21.6704 21.5449 21.6704C19.518 21.6711 17.4911 21.6692 15.4641 21.6746C15.3215 21.6751 15.2473 21.6251 15.1704 21.5156C13.2912 18.8355 11.4084 16.1579 9.52575 13.48C9.4778 13.4117 9.42769 13.3447 9.3672 13.2616C8.8159 13.8876 8.27976 14.4959 7.74412 15.1047C5.85814 17.2485 3.97313 19.3932 2.08234 21.5328C2.01825 21.6051 1.89416 21.6626 1.79657 21.6649C1.23514 21.6774 0.673225 21.6711 0.0546875 21.6711C2.8922 18.4456 5.70056 15.2536 8.51614 12.0534C8.4747 11.9907 8.43831 11.9323 8.39856 11.876C5.67598 8.00072 2.95292 4.12594 0.230827 0.250214C0.174443 0.169872 0.126734 0.0836402 0.0751689 0C2.22523 0 4.37505 0 6.52511 0C6.54198 0.0306288 6.55644 0.0633781 6.57644 0.0918864C8.39253 2.67696 10.2091 5.26179 12.025 7.8471C12.052 7.88551 12.0722 7.92862 12.1009 7.97857C14.4656 5.29101 16.7937 2.64539 19.1216 0C19.7566 0 20.3915 0 21.0264 0ZM19.1055 20.3119C19.0373 20.2131 18.9944 20.1486 18.9494 20.0857C17.7807 18.4508 16.6121 16.8162 15.4432 15.1815C12.2136 10.6647 8.98288 6.14862 5.75887 1.62804C5.63646 1.45652 5.51189 1.39644 5.30226 1.3995C4.50204 1.41175 3.70134 1.40421 2.90088 1.40492C2.83317 1.40492 2.76546 1.41199 2.67438 1.41694C2.72425 1.4928 2.75654 1.54628 2.79293 1.59694C7.21955 7.78938 11.6469 13.9811 16.0711 20.1752C16.1506 20.2864 16.2328 20.3286 16.3714 20.3274C17.208 20.321 18.0446 20.3243 18.8812 20.3239C18.9433 20.3239 19.0055 20.3175 19.1057 20.3119H19.1055Z"
                                    fill="white"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_1371_86">
                                    <rect width="21.6748" height="21.6748" fill="white"
                                          transform="translate(0.0546875)"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </a>
                    <a className="social-icon-btn"
                       href={"https://www.linkedin.com/company/vieunite/"}
                       target="_blank"
                       rel="noopener noreferrer"
                       style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           margin: 0,
                           padding: 0,
                           paddingLeft: '4vw'
                       }}>

                        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24"
                             fill="none">
                            <path
                                d="M13.2901 23.6453H11.8122C11.7611 23.6324 11.7113 23.6108 11.6595 23.6077C10.6577 23.5368 9.67616 23.3524 8.73466 23.0033C4.44095 21.4126 1.83566 18.3668 0.915707 13.8834C0.826422 13.4492 0.789476 13.0039 0.728516 12.5635C0.728516 12.0707 0.728516 11.5785 0.728516 11.0856C0.742062 11.019 0.757456 10.9524 0.769772 10.8852C0.904624 10.1302 0.977899 9.35801 1.18233 8.62218C3.24821 1.19603 11.7814 -2.27835 18.4766 1.60866C21.6324 3.44114 23.529 6.21361 24.1958 9.81012C24.274 10.232 24.3153 10.6607 24.3738 11.0863V12.5178C24.3609 12.5918 24.3448 12.6652 24.3344 12.7399C24.2703 13.2037 24.2297 13.6725 24.1404 14.1314C23.2198 18.8905 19.3689 22.6505 14.5862 23.464C14.1564 23.5374 13.7217 23.5855 13.2894 23.6453H13.2901ZM10.75 9.75029V18.0375H13.2962C13.2962 17.9351 13.2962 17.845 13.2962 17.755C13.2962 16.4622 13.2925 15.17 13.2987 13.8772C13.3005 13.4146 13.3369 12.9539 13.5493 12.5289C13.8264 11.9751 14.3713 11.7302 15.1158 11.8221C15.6355 11.8862 15.989 12.2316 16.1023 12.8324C16.1607 13.1402 16.1897 13.4578 16.1922 13.7718C16.202 15.1102 16.1971 16.4492 16.1977 17.7877C16.1977 17.8691 16.1977 17.9499 16.1977 18.0332H18.7654C18.7703 17.9573 18.7771 17.8969 18.7771 17.837C18.7771 16.3598 18.7931 14.882 18.7685 13.4054C18.7586 12.8164 18.7026 12.2205 18.5881 11.6432C18.3953 10.6699 17.8541 9.94705 16.8547 9.68614C16.4434 9.57882 16.0037 9.52763 15.5789 9.53811C14.6152 9.56155 13.8245 9.95136 13.2888 10.7853C13.2759 10.805 13.2513 10.818 13.1915 10.8679V9.74967H10.75V9.75029ZM9.09857 18.0363V9.75707H6.55055V18.0363H9.09857ZM7.81348 8.59998C8.65091 8.59813 9.32271 7.92274 9.31717 7.08699C9.31162 6.27036 8.62259 5.59189 7.80547 5.59991C6.9822 5.60793 6.31533 6.28331 6.31594 7.10734C6.31656 7.93384 6.98651 8.60183 7.81286 8.59998H7.81348Z"
                                fill="white"/>
                        </svg>
                    </a>
                    <a className="social-icon-btn"
                       href={"https://www.pinterest.co.uk/vieunite/"}
                       target="_blank"
                       rel="noopener noreferrer"
                       style={{
                           display: 'flex',
                           justifyContent: 'center',
                           alignItems: 'center',
                           margin: 0,
                           padding: 0,
                           paddingLeft: '4vw'
                       }}>

                        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="22" viewBox="0 0 19 22"
                             fill="none">
                            <path
                                d="M4.42601 21.6748C4.37155 21.4666 4.29626 21.2616 4.26689 21.0503C4.10137 19.8685 3.98017 18.6788 4.13234 17.4902C4.22151 16.7952 4.40625 16.1108 4.57338 15.428C4.98292 13.7526 5.40954 12.0814 5.82121 10.4065C5.84737 10.3011 5.83349 10.1708 5.79558 10.0669C5.36148 8.88147 5.28727 7.69812 5.85165 6.53596C6.20672 5.80497 6.76202 5.28375 7.59605 5.11689C8.71947 4.8923 9.648 5.60739 9.70994 6.74148C9.74251 7.34322 9.59781 7.91689 9.42748 8.48473C9.16425 9.36138 8.89033 10.2343 8.62016 11.1089C8.12946 12.6959 9.4152 13.76 10.789 13.6652C12.29 13.5619 13.313 12.7441 14.0418 11.5077C14.6457 10.4838 14.8988 9.35662 14.9634 8.18015C15.0099 7.33422 14.9373 6.50735 14.6329 5.70804C14.138 4.40868 13.2473 3.45787 11.9771 2.90751C9.98386 2.04463 7.96714 2.087 6.02945 3.05371C4.28718 3.92294 3.25827 5.37115 2.98329 7.29979C2.79053 8.64841 3.10289 9.88791 3.93745 10.9828C4.05065 11.1311 4.08055 11.2831 4.03409 11.4579C3.91449 11.9071 3.79916 12.3568 3.67688 12.805C3.59145 13.1175 3.4617 13.1747 3.16109 13.0349C2.45148 12.7043 1.91273 12.1799 1.49198 11.5374C0.527669 10.0696 0.173663 8.46301 0.484954 6.73619C1.06962 3.49283 3.05003 1.46513 6.16347 0.497369C8.09208 -0.102253 10.0672 -0.163698 12.0305 0.334751C14.1625 0.876105 15.8968 2.01602 17.0645 3.9007C17.8265 5.13066 18.1628 6.48458 18.1025 7.93331C18.0422 9.3831 17.7149 10.7635 17.0026 12.0374C15.9822 13.8628 14.4909 15.1118 12.4181 15.597C11.2984 15.8587 10.1793 15.8661 9.10071 15.3809C8.58171 15.1473 8.12038 14.8422 7.79788 14.3628C7.7824 14.3395 7.77278 14.3125 7.73701 14.2389C7.52984 14.991 7.31359 15.668 7.15821 16.3577C6.72518 18.2794 5.76568 19.9453 4.65507 21.5424C4.62357 21.5879 4.5878 21.6314 4.55416 21.6753H4.42601V21.6748Z"
                                fill="white"/>
                        </svg>
                    </a>
                </Row>
                <Row justify={"start"} style={{marginBottom: '2vw', marginLeft: '8vw', marginRight: "8vw"}}>
                    <a href={"/terms-and-conditions"} style={{
                        color: "#FFF",
                        fontWeight: '600',
                        fontSize: '16px',
                        fontStyle: 'normal'

                    }}
                       className={'primary-font-footer'}>
                        Terms and Conditions

                    </a>
                </Row>
                <Row justify={"start"} style={{marginBottom: '2vw', marginLeft: '8vw', marginRight: "8vw"}}>
                    <a href={"/end-user-agreement"} style={{
                        color: "#FFF",
                        fontWeight: '600',
                        fontSize: '16px',
                        fontStyle: 'normal'

                    }}
                       className={'primary-font-footer'}>
                        End User Agreement

                    </a>
                </Row>
                <Row justify={"start"} style={{marginBottom: '5vw', marginLeft: '8vw', marginRight: "8vw"}}>
                    <a href="/artists-terms-and-conditions" style={{
                        color: "#FFF",
                        fontWeight: '600',
                        fontSize: '16px',
                        fontStyle: 'normal',

                    }}
                       className={'primary-font-footer'}>
                        Artist's Terms & Conditions

                    </a>
                </Row>

                <Row style={{marginBottom: '10vw', marginLeft: '8vw', marginRight: "8vw"}}>
                    <div className={'primary-font-footer'} style={{
                        color: "#FFF",
                        fontWeight: '400',
                        fontSize: '16px',
                        fontStyle: 'normal',

                    }}> {/* Text set to white */}
                        &copy;2024 Vieunite Limited. All Rights Reserved
                    </div>
                    <div className={'primary-font-footer'} style={{
                        color: "#FFF",
                        fontWeight: '400',
                        fontSize: '16px',
                        fontStyle: 'normal',
                    }}> {/* Text set to white */}
                        VAT Registration No. 411723238
                    </div>
                    <div className={'primary-font-footer'} style={{
                        color: "#FFF",
                        fontWeight: '400',
                        fontSize: '16px',
                        fontStyle: 'normal',
                    }}> {/* Text set to white */}
                        Company Registration No. 13883746
                    </div>
                </Row>


            </div>

        </Row>
    )
}


const BasicFooter = () => {
    return (
        <>
            <Row justify={"center"} style={{backgroundColor: '#F4F4F4'}}>
                <div className={"footer-container"} style={{width: '100%'}}>

                    <div style={{
                        display: "flex",
                        justifyContent: "space-between",
                        backgroundColor: "#F4F4F4",
                        marginTop: "5vw",
                        marginLeft: "8vw",
                        marginRight: "8vw",
                        marginBottom: "5vw"// Background color
                    }}>
                        {/* Column 1 */}
                        <div style={{flex: '1 1 20%',}}>
                            <p className={'primary-font-footer'}
                               style={{fontWeight: "600", color: "black", fontSize: "16px"}}>For Art.</p>
                            <a href="/art?page=artists" style={{
                                color: "#383838",
                                textDecoration: "none",
                                display: "block",
                                fontSize: "16px",
                                fontWeight: "400"
                            }}>
                                Artist
                            </a>
                            <a href="/art?page=galleries" style={{
                                color: "#383838",
                                textDecoration: "none",
                                display: "block",
                                fontSize: "16px",
                                fontWeight: "400"
                            }}>
                                Galleries
                            </a>
                            <a href="/art?page=curation" style={{
                                color: "#383838",
                                textDecoration: "none",
                                display: "block",
                                fontSize: "16px",
                                fontWeight: "400"
                            }}>
                                Curator
                            </a>
                        </div>
                        {/* Column 2 */}
                        <div style={{flex: '1 1 20%',}}>
                            <p className={'primary-font-footer'}
                               style={{fontWeight: "600", color: "black", fontSize: "16px"}}>Research</p>
                            <a href="https://vieutopia.com" style={{
                                color: "#383838",
                                textDecoration: "none",
                                display: "block",
                                margin: "5px 0",
                                fontSize: "16px",
                                fontWeight: "400"
                            }}>
                                Vieutopia
                            </a>
                            <a href="/research/biophilia" style={{
                                color: "#383838",
                                textDecoration: "none",
                                display: "block",
                                fontSize: "16px",
                                fontWeight: "400"
                            }}>
                                Biophilia Classification
                            </a>
                            <a href="/research/compad" style={{
                                color: "#383838",
                                textDecoration: "none",
                                display: "block",
                                fontSize: "16px",
                                fontWeight: "400"
                            }}>
                                COMPaD
                            </a>
                        </div>

                        {/* Column 3 */}
                        <div style={{flex: '1 1 20%',}}>
                            <p className={'primary-font-footer'}
                               style={{fontWeight: "600", color: "black", fontSize: "16px"}}>Company</p>
                            <a href="/contact" style={{
                                color: "#383838",
                                textDecoration: "none",
                                display: "block",
                                fontSize: "16px",
                                fontWeight: "400"
                            }}>
                                Contact Us
                            </a>
                            <a href="/home/support" style={{
                                color: "#383838",
                                textDecoration: "none",
                                display: "block",
                                margin: "5px 0",
                                fontSize: "16px",
                                fontWeight: "400"
                            }}>
                                FAQ
                            </a>
                        </div>
                        {/* Column 4 */}
                        <div style={{
                            flex: '1 1 20%',
                            color: 'black',

                        }}>
                            <p className={'primary-font-footer'}
                               style={{fontWeight: '600', color: 'black', fontSize: "16px"}}>Vieunite Apps</p>
                            <p className={'primary-font-footer'} style={{
                                color: 'black',
                                textDecoration: 'none',
                                display: 'block',
                                fontWeight: '600',
                                fontSize: "16px",

                            }}>
                                Vieunite
                            </p>
                            <a href="https://play.google.com/store/apps/details?id=com.app.vieunite"
                               target={'_blank'}
                               style={{
                                   color: '#383838',
                                   textDecoration: 'none',
                                   display: 'block',
                                   fontSize: "16px",
                                   fontWeight: "400"
                               }}>
                                Android
                            </a>
                            <a href="https://apps.apple.com/gb/app/vieunite-discover-share-art/id1644381065"
                               target={'_blank'}
                               style={{
                                   color: '#383838',
                                   textDecoration: 'none',
                                   display: 'block',
                                   fontSize: "16px",
                                   fontWeight: "400"
                               }}>
                                iOS
                            </a>

                            <p className={'primary-font-footer'} style={{
                                fontWeight: '600',
                                color: 'black',
                                fontSize: "16px",
                                paddingTop: "1vw"

                            }}>
                                Vieutopia
                            </p>
                            {/*<a href="/android" style={{*/}
                            {/*    color: '#383838',*/}
                            {/*    textDecoration: 'none',*/}
                            {/*    display: 'block',*/}
                            {/*    fontSize: "16px",*/}
                            {/*    fontWeight: "400"*/}
                            {/*}}>*/}
                            {/*    Android*/}
                            {/*</a>*/}
                            <a href="https://apps.apple.com/gb/app/vieutopia-ai-art-generator/id1660264569"
                               target={'_blank'}
                               style={{
                                color: '#383838',
                                textDecoration: 'none',
                                display: 'block',
                                fontSize: "16px",
                                fontWeight: "400"
                            }}>
                                iOS
                            </a>

                            <p className={'primary-font-footer'} style={{
                                fontWeight: '600',
                                color: 'black',
                                fontSize: "16px",
                                paddingTop: "1vw",

                            }}>Vieunite Pro Connect
                            </p>
                            <a href="https://play.google.com/store/apps/details?id=com.vieunite.vieunitepro"
                               target={'_blank'}
                               style={{
                                   color: '#383838',
                                   textDecoration: 'none',
                                   display: 'block',
                                   fontSize: "16px",
                                   fontWeight: "400"
                               }}>
                                Android
                            </a>
                            <a href="https://apps.apple.com/gb/app/vieunite-pro-connect/id6499339979"
                               target={'_blank'}
                               style={{
                                color: '#383838',
                                textDecoration: 'none',
                                display: 'block',
                                fontSize: "16px",
                                fontWeight: "400"
                            }}>
                                iOS
                            </a>
                        </div>
                    </div>
                </div>
                <div className={"footer-container-mobile"}>
                    {/* For Art Section */}
                    <div style={{marginTop: '10vw', marginLeft: "8vw", marginRight: "8vw", marginBottom: "5vw"}}>
                        <p className={'primary-font-footer'} style={{
                            color: "#000",
                            textDecoration: "none",
                            display: "block",
                            fontSize: "16px",
                            fontWeight: '600',
                        }}>
                            For Art.
                        </p>

                        <a href="/art?page=artists" style={{
                            color: "#383838",
                            textDecoration: "none",
                            display: "block",
                            fontSize: "16px",
                            fontWeight: "400"

                        }}>
                            Artist
                        </a>
                        <a href="/art?page=galleries" style={{
                            color: "#383838",
                            textDecoration: "none",
                            display: "block",
                            fontSize: "16px",
                            fontWeight: "400"
                        }}>
                            Galleries
                        </a>
                        <a href="/art?page=curation" style={{
                            color: "#383838",
                            textDecoration: "none",
                            display: "block",
                            fontSize: "16px",
                            fontWeight: "400"
                        }}>
                            Curator
                        </a>
                    </div>

                    {/* Research Section */}
                    <div style={{marginLeft: "8vw", marginRight: "8vw", marginBottom: "5vw"}}>
                        <p className={'primary-font-footer'} style={{
                            color: "#000",
                            textDecoration: "none",
                            display: "block",
                            fontSize: "16px",
                            fontWeight: '600',

                        }}>
                            Research
                        </p>

                        <a href="/research" style={{
                            color: "#383838",
                            textDecoration: "none",
                            display: "block",
                            fontSize: "16px",
                            fontWeight: "400"
                        }}>
                            Vieutopia
                        </a>
                        <a href="/research/biophilia" style={{
                            color: "#383838",
                            textDecoration: "none",
                            display: "block",
                            fontSize: "16px",
                            fontWeight: "400"
                        }}>
                            Biophilia Classification
                        </a>
                        <a href="/research" style={{
                            color: "#383838",
                            textDecoration: "none",
                            display: "block",
                            fontSize: "16px",
                            fontWeight: "400"
                        }}>
                            Demo
                        </a>
                    </div>

                    {/* Company Section */}
                    <div style={{marginLeft: "8vw", marginRight: "8vw", marginBottom: "5vw"}}>
                        <p className={'primary-font-footer'} style={{
                            color: "#000",
                            textDecoration: "none",
                            display: "block",
                            fontSize: "16px",
                            fontWeight: '600',
                        }}>
                            Company
                        </p>

                        <a href="/contact" style={{
                            color: "#383838",
                            textDecoration: "none",
                            display: "block",
                            fontSize: "16px",
                            fontWeight: "400"
                        }}>
                            Contact Us
                        </a>
                        <a href="/help-center" style={{
                            color: "#383838",
                            textDecoration: "none",
                            display: "block",
                            fontSize: "16px",
                            fontWeight: "400"
                        }}>
                            FAQ
                        </a>
                    </div>

                    {/* Vieunite Apps Section */}
                    <div style={{marginLeft: "8vw", marginRight: "8vw", marginBottom: "10vw"}}>
                        <p className={'primary-font-footer'} style={{
                            color: "#000",
                            textDecoration: "none",
                            display: "block",
                            fontSize: "16px",
                            fontWeight: '600',
                        }}>
                            Vieunite Apps
                        </p>

                        <p className={'primary-font-footer'} style={{
                            color: "#000",
                            textDecoration: "none",
                            display: "block",
                            fontSize: "16px",
                            fontWeight: '600',
                            paddingTop: '10px',

                        }}>
                            Vieunite
                        </p>

                        <a href="https://play.google.com/store/apps/details?id=com.app.vieunite"
                           target={'_blank'}
                           style={{
                            color: "#383838",
                            textDecoration: "none",
                            display: "block",
                            fontSize: "16px",
                            fontWeight: "400"
                        }}>
                            Android
                        </a>
                        <a href="https://apps.apple.com/gb/app/vieunite-discover-share-art/id1644381065"
                           target={'_blank'}
                           style={{
                            color: "#383838",
                            textDecoration: "none",
                            display: "block",
                            fontSize: "16px",
                            fontWeight: "400"
                        }}>
                            IOS
                        </a>

                        <p className={'primary-font-footer'} style={{
                            color: "#000",
                            textDecoration: "none",
                            display: "block",
                            fontSize: "16px",
                            fontWeight: '600',
                            paddingTop: '2vw'
                        }}>
                            Vieutopia
                        </p>

                        {/*<a href="/vieutopia/android" style={{*/}
                        {/*    color: "#383838",*/}
                        {/*    textDecoration: "none",*/}
                        {/*    display: "block",*/}
                        {/*    fontSize: "16px",*/}
                        {/*    fontWeight: "400"*/}

                        {/*}}>*/}
                        {/*    Android*/}
                        {/*</a>*/}
                        <a href="https://apps.apple.com/gb/app/vieutopia-ai-art-generator/id1660264569" target={'_blank'} style={{
                            color: "#383838",
                            textDecoration: "none",
                            display: "block",
                            fontSize: "16px",
                            fontWeight: "400"
                        }}>
                            IOS
                        </a>

                        <p className={'primary-font-footer'} style={{
                            color: "#000",
                            textDecoration: "none",
                            display: "block",
                            fontSize: "16px",
                            fontWeight: '600',
                            paddingTop: '2vw'
                        }}>
                            Vieunite Pro Connect
                        </p>

                        <a href="https://play.google.com/store/apps/details?id=com.vieunite.vieunitepro"
                           target={'_blank'}
                           style={{
                            color: "#383838",
                            textDecoration: "none",
                            display: "block",
                            fontSize: "16px",
                            fontWeight: "400"
                        }}>
                            Android
                        </a>
                        <a href="https://apps.apple.com/gb/app/vieunite-pro-connect/id6499339979"
                           target={'_blank'}
                           style={{
                            color: "#383838",
                            textDecoration: "none",
                            display: "block",
                            fontSize: "16px",
                            fontWeight: "400"
                        }}>
                            IOS
                        </a>
                    </div>
                </div>
            </Row>
            <BottomRow></BottomRow>
        </>
    )
}

const PageFooter = () => {
    return (
        <div className={'v-footer'}>
            <ContactRow></ContactRow>
            <BasicFooter></BasicFooter>
        </div>
    )
}

export const NoContactFooter = () => {
    return (
        <div className={'v-footer'} style={{borderTop: 'none'}}>
            <BasicFooter></BasicFooter>
        </div>
    )
}

export default PageFooter;
