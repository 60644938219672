import React from 'react';
import {Button, Layout, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import img1 from './img/Explore with line.webp'
import img2 from './img/Curated with line.webp'
import img3 from './img/Partnering with line.webp'
import img4 from './img/Galleries New.webp'
import img5 from './img/Playlists with line.webp'
import {ReactComponent as HeadLogo} from './img/curated_logo.svg'
import {TitleText} from "./text";
import './style.scss'
import {useNavigate} from "react-router-dom";
import {ProCuratedMeta} from "./meta";
import useWindowResize from "../../apps/Hooks/useWindowResize";
import m_img_1 from './img/m_ca_1.png'
import m_img_2 from './img/m_ca_2.png'
import m_img_3 from './img/m_ca_3.png'
import m_img_4 from './img/m_ca_4.png'
import m_img_5 from './img/m_ca_5.png'
import img_hd from './img/20240620-101143.jpeg'

const CuratedCollections = (props) => {
    const navigate = useNavigate()
    const {windowWidth} = useWindowResize()
    return (
        <div>
            <ProCuratedMeta/>
            <Layout>
                {windowWidth > 600 && <Content>
                    <Row justify={"center"} style={{
                        margin: '20vh 0 20vh 0'
                    }}>
                        <HeadLogo/>
                        {/*<img src={img_hd}></img>*/}
                    </Row>
                    <Row justify={"center"} style={{
                        margin: '0 0 2rem 0'
                    }}>
                        {TitleText.map((txt, idx) => {
                            return (
                                idx === 0 ? <p className={'curated-txt'} key={idx}
                                               style={{margin: '-5rem 0 0 0'}}
                                    >
                                        {txt}
                                    </p> :
                                    <p className={'curated-txt'}
                                       key={idx}
                                       style={{margin: '-3rem 0 0 0'}}
                                    >
                                        {txt}
                                    </p>
                            )
                        })}
                    </Row>
                    <Row justify={"center"}>
                        <Button className={'dsa-btn pro-btn-hover-to-white'}
                                onClick={_ => {
                                    if (localStorage.getItem('token')) {
                                        window.location.href = '/proportal/discover-art'
                                    } else {
                                        navigate('/business/prosignin', {state: {nextPage: '/proportal/discover-art'}})
                                    }
                                }}
                        >
                            Discover Art
                        </Button>
                    </Row>
                    <Row justify={"center"}>
                        <div className="curated-image-overlay-container">
                            <img src={img1} alt={img1} className="curated-overlay-image"/>
                            <div className="curated-text-overlay">
                                <p className={'curated-h1'}>
                                    Explore Art, Get Inspired
                                </p>
                                <p className={'curated-p'}>
                                    Vieunite Pro's extensive art library boasts a rich tapestry of styles, from classic
                                    fine
                                    art to contemporary photography. Our curated playlists empower you to find pieces
                                    that
                                    resonate with your brand identity and connect with your target audience. Whether
                                    it's a
                                    calming landscape to soothe customers in a waiting room or a vibrant abstract piece
                                    to
                                    energise employees in a brainstorming session, Vieunite Pro has the perfect artwork
                                    to
                                    set your desired tone.
                                </p>
                            </div>
                        </div>
                    </Row>
                    <Row justify={"center"}>
                        <div className="curated-image-overlay-container" style={{width: '100%'}}>
                            <img src={img2} alt={img2} className="curated-overlay-image" style={{width: '100%'}}/>
                            <div className="curated-text-overlay2 curated-overlay-left">
                                <p className={'curated-h2'}>
                                    Curated Experiences
                                </p>
                                <p className={'curated-p2'}>
                                    With Vieunite Pro you gain access to thousands of high-quality and carefully
                                    selected
                                    artworks, encompassing both modern and historic art. You can browse the artwork
                                    based on
                                    your specific business industry, art styles and mediums, moods, themes and colour
                                    schemes.
                                    <br/> <br/>
                                    Vieunite Pro recognises that art plays a crucial role in conveying a company's
                                    values and mission. We understand that art is more than just decoration. It is a
                                    powerful tool for sculpting space that resonates with employees and customers alike.
                                    With the help of our curatorial know-how, you can easily choose artwork that
                                    reflects
                                    your core principles, fostering a sense of connection and belonging among your
                                    employees
                                    and strengthening brand loyalty with your customers and clients.
                                </p>
                            </div>
                        </div>
                    </Row>
                    <Row justify={"center"}>
                        <div className="curated-image-overlay-container">
                            <img src={img3} alt={img3} className="curated-overlay-image"/>
                            <div className="curated-text-overlay">
                                <p className={'curated-h1'}>
                                    Partnering With Artists
                                </p>
                                <p className={'curated-p'}>
                                    Featuring work from over 200 artists, our collection includes works by aspiring
                                    present-day artists as well as world-renowned, established artists.
                                    <br/> <br/>
                                    Vieunite Pro provides consistent revenue for artists, so by working with us you are
                                    helping art to thrive. You work with us to support your favourite creatives, and
                                    enjoy
                                    exclusive access
                                    to timeless masterpieces from internationally recognised artists.
                                </p>
                            </div>
                        </div>
                    </Row>
                    <Row justify={"center"}>
                        <div className="curated-image-overlay-container" style={{width: '100%'}}>
                            <img src={img4} alt={img4} className="curated-overlay-image" style={{width: '100%'}}/>
                            <div className="curated-text-overlay4 curated-overlay-left">
                                <p className={'curated-h4'} style={{color: "white"}}>
                                    Connecting You to Galleries
                                </p>
                                <p className={'curated-p4'} style={{color: "white"}}>
                                    We actively collaborate with renowned and emerging art institutes and galleries
                                    worldwide, allowing you to explore and access a thoughtfully curated selection of
                                    artwork. You will have exclusive access to the finest art from galleries such as
                                    National Galleries of Scotland, RBSA Gallery and The Estorick Collection of Modern
                                    Italian Art.
                                </p>
                            </div>
                        </div>
                    </Row>
                    <Row justify={"center"}>
                        <div className="curated-image-overlay-container">
                            <img src={img5} alt={img5} className="curated-overlay-image"/>
                            <div className="curated-text-overlay5">
                                <p className={'curated-h5'}>
                                    Curated Playlists
                                </p>
                                <p className={'curated-p5'}>
                                    With our Advanced subscription plan you gain unrestricted access to our Curated
                                    playlists, featuring artworks from yesteryear and today. Each of these collections
                                    has
                                    been tailored to a specific business sector, enabling effortless distinction for our
                                    business users.
                                    <br/> <br/>
                                    Alternatively, you can upgrade to our Premium option to get and enjoy a
                                    personalised and exclusive playlist, crafted by our team to match your style and
                                    brand
                                    aesthetics. It’s the perfect way to express your business values through
                                    unforgettable,
                                    immersive experiences.
                                </p>
                            </div>
                        </div>
                    </Row>
                </Content>}
                {windowWidth <= 600 && <Content style={{background: '#F4F4F4'}}>
                    <Row justify={"center"} style={{
                        margin: '15vh 0 15vh 0'
                    }}>
                        <HeadLogo style={{width:'66vw'}}/>
                    </Row>
                    <Row justify={"center"} style={{
                        margin: '-15vh 0 0 0'
                    }}>
                        <p className={'m-curated-txt'}>
                            Our platform, supported by a highly skilled curation team with deep knowledge in creating
                            experiences through art, offers access to skilfully crafted collections of quality artwork.
                            With our Advanced plan, you can enjoy tailored playlists customised to your specific
                            industry and requirements.
                        </p>
                    </Row>
                    <Row justify={"center"}>
                        <Button className={'m-dsa-btn pro-btn-hover-to-white'}
                                onClick={_ => {
                                        window.location.href = '/business/explore'
                                }}
                        >
                            Discover Art
                        </Button>
                    </Row>
                    <Row justify={"center"} style={{margin: '2vh auto'}}>
                        <img src={m_img_1} alt={img1} style={{width: '100vw'}}/>
                        <p className={'m-curated-h1'}>
                            Explore Art, Get Inspired
                        </p>
                        <p className={'m-curated-p'}>
                            From classic fine art to contemporary photography, Vieunite Pro offers an extensive
                            library with curated playlists that resonate with your brand identity and connect
                            you with your target audience.
                        </p>
                    </Row>
                    <Row justify={"center"}>
                        <img src={m_img_2} alt={img1} style={{width: '100vw'}}/>
                        <p className={'m-curated-h1'}>
                            Curated Experiences
                        </p>
                        <p className={'m-curated-p'}>
                            With Vieunite Pro you gain access to thousands of carefully selected artworks, encompassing
                            both modern and historic art. You can filter the artwork by industry, art styles, moods,
                            themes, and colours, easily finding art that reflects your core principles.
                            {/*<br/> <br/>*/}
                        </p>
                    </Row>
                    <Row justify={"center"}>
                        <img src={m_img_3} alt={img1} style={{width: '100vw'}}/>
                        <p className={'m-curated-h1'}>
                            Partnering With Artists
                        </p>
                        <p className={'m-curated-p'}>
                            Featuring over 200 creatives, our collection includes works by both contemporary talents and
                            renowned, established artists. Vieunite Pro provides consistent revenue for artists, helping
                            art to thrive. By working with us, you can support your favourite creatives and access
                            timeless pieces by globally renowned artists.
                        </p>
                    </Row>
                    <Row justify={"center"}>
                        <img src={m_img_4} alt={img1} style={{width: '100vw'}}/>
                        <p className={'m-curated-h1'}>
                            Connecting You to Galleries
                        </p>
                        <p className={'m-curated-p'}>
                            Vieunite Pro is partnered with world renowned and emerging art institutions, offering a
                            curated selection of artwork from the collections of major museums and art galleries.
                        </p>
                    </Row>
                    <Row justify={"center"}>
                        <img src={m_img_5} alt={img1} style={{width: '100vw'}}/>
                        <p className={'m-curated-h1'}>
                            Curated Playlists
                        </p>
                        <p className={'m-curated-p'}>
                            With our Advanced subscription plan you gain unrestricted access to our curated playlists,
                            carefully tailored to a specific business sector. Alternatively, you can upgrade to our
                            Premium option to enjoy a personalised and exclusive playlist, crafted by our team to match
                            your style and brand aesthetics.
                        </p>
                    </Row>
                </Content>}
            </Layout>
        </div>
    )
}

export default CuratedCollections;
