import React from "react";
import {Helmet} from "react-helmet";

export const A5Meta = () => (
    <Helmet>
            <title>Jenette Coldrick | Vieunite</title>
            <meta name="description"
                  content="Working from her studios in Redcar and micro pottery in Stamford, Jenette uses her work to explore her emotions, memory and the environment and fills her work with the excitement she feels for life."/>
            <meta name="keywords"
                  content="digital canvas, digital frame, digital art frame,  digital art canvas,picture frame,  frame, the frame, smart frame, smart digital art frame,  digital art, art, photography frame, artworks, gallery, photo frame, digital photo frame, textura, texturite technology, VT27WGV1, vieunite, digital photo to canvas, digital picture frame, best digital picture frame, electronic photo frame, wifi picture frame, video picture frame"/>
            <meta name="twitter:card" value="summary_large_image"/>
            <meta name="twitter:site" value="@VieuniteHQ"/>
            <meta name="twitter:title" value="Jenette Coldrick"/>
            <meta name="twitter:description"
                  content="Working from her studios in Redcar and micro pottery in Stamford, Jenette uses her work to explore her emotions, memory and the environment and fills her work with the excitement she feels for life."/>
            <meta name="twitter:url" value="https://vieunite.com/jenettecoldrick"/>
            <meta name="twitter:image" value={'https://vieunite.com/img/Social Card - Jenette Coldrick.jpg'}/>

            <meta property="og:url" content="https://vieunite.com/jenettecoldrick"/>
            <meta property="og:type" content="article"/>
            <meta property="og:title" content="Jenette Coldrick"/>
            <meta property="og:description"
                  content="Working from her studios in Redcar and micro pottery in Stamford, Jenette uses her work to explore her emotions, memory and the environment and fills her work with the excitement she feels for life."/>
            <meta property="og:image" content={'https://vieunite.com/img/Social Card - Jenette Coldrick.jpg'}/>
            <meta property="og:image:width" content="930"/>
            <meta property="og:image:height" content="620"/>
    </Helmet>);