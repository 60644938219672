import React from "react";
import {Helmet} from "react-helmet";

export const A19Meta = () => (
    <Helmet>
        <title> Matthew Stickland | Vieunite</title>
        {/*<meta name="description" content="Canadian-American artist. Her paintings aim to invoke universal human experiences and emotions. Depicting figures without clear identity, her works become representations of shared humanity."/>*/}
        <meta name="keywords" content="digital canvas, digital frame, digital art frame,  digital art canvas,picture frame,  frame, the frame, smart frame, smart digital art frame,  digital art, art, photography frame, artworks, gallery, photo frame, digital photo frame, textura, texturite technology, VT27WGV1, vieunite, digital photo to canvas, digital picture frame, best digital picture frame, electronic photo frame, wifi picture frame, video picture frame"/>
        <meta name="twitter:card" content="summary_large_image"/>
        <meta name="twitter:site" content="@VieuniteHQ"/>
        <meta name="twitter:title" content="Matthew Stickland | Vieunite"/>
        {/*<meta name="twitter:description" value="Canadian-American artist. Her paintings aim to invoke universal human experiences and emotions. Depicting figures without clear identity, her works become representations of shared humanity."/>*/}
        <meta name="twitter:url" content="https://vieunite.com/MatthewStickland"/>
        {/*<meta name="twitter:image" content={'https://vieunite.com/img/Vieunite - Social CardsSocial Card - AngeliqueNagovskaya.jpg'}/>*/}
        <meta property="og:url" content="https://vieunite.com/MatthewStickland"/>
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="Matthew Stickland | Vieunite"/>
        {/*<meta property="og:description" content="Canadian-American artist. Her paintings aim to invoke universal human experiences and emotions. Depicting figures without clear identity, her works become representations of shared humanity."/>*/}
        {/*<meta property="og:image" content="https://vieunite.com/img/Vieunite - Social CardsSocial Card - AngeliqueNagovskaya.jpg"/>*/}
        <meta property="og:image:width" content="930"/>
        <meta property="og:image:height" content="620"/>
    </Helmet>);