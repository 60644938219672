import React, {useEffect, useRef, useState} from 'react';
import {Button, Col, Divider, Layout, Modal, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import "./style.css";
import {Text} from "./text";
import ShadowHeader from "../../components/Header/ShadowHeader";
import {NoContactFooter} from "../../components/Footer/PageFooter";
import {GoogleMap, Marker, useJsApiLoader} from '@react-google-maps/api';
import {NewHeader} from "../../components/NewHeader";
import leftScroll from "../Home/img/LeftScroll.png";
import rightScroll from "../Home/img/RightScroll.png";
import homeExample1 from "../Home/img/homeExample1.png";
import homeExample2 from "../Home/img/homeExample2.png";
import homeExample3 from "../Home/img/homeExample3.png";
import homeExample4 from "../Home/img/homeExample4.png";
import homeExample5 from "../Home/img/homeExample5.png";
import homeExample6 from "../Home/img/homeExample6.png";
import mapPopup1 from "../Home/img/MapPopUp1.png";
import mapPopup2 from "../Home/img/MapPopUp2.png";
import mapPopup3 from "../Home/img/MapPopUp3.png";
import ContactEmail from "./imgs/ContactEmail.png"
import ContactCall from "./imgs/ContactCall.png"
import TexturaStars1 from "../../img/Textura/5 - Textura App/stars1.png";
import useWindowResize from "../../apps/Hooks/useWindowResize";
import LandingMobilePage from "../../LandingPageMobile";
import LandingPage from "../../LandingPage";
import {useMediaQuery} from "react-responsive";


const Primary_Font = {
    fontFamily: "Proxima Nova bold, sans-serif",
}

const TitleFont = {
    fontSize: '2.625rem',
    fontFamily: "Proxima Nova bold, sans-serif",
}

const mapCenter = {
    lat: 52.441174,
    lng: -1.997705,
};

function NewContact(props) {
    const {isLoaded} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: 'AIzaSyAcXHnSYmUPEY9e-ex-N__YbsiFWiDd1RU',
    });

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState({});

    const handleImageClick = (src, alt, mapEmbedUrl, mapTitle, mapAddress, mapCity, mapPostCode, directions, storeUrl, popupImg) => {
        setModalContent({src, alt, mapEmbedUrl, mapTitle, mapAddress, mapCity, mapPostCode, directions, storeUrl, popupImg});
        console.log(popupImg)
        setIsModalVisible(true);
    };

    const handleModalClose = () => {
        setIsModalVisible(false);
    };
    const images = [
        {
            src: homeExample1,
            alt: 'RBSA Gallery | Birmingham',
            mapEmbedUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2429.6796954941015!2d-1.910062223074293!3d52.48493493882477!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4870bced5c404861%3A0x67d30a7e404875a1!2sRBSA%20Gallery!5e0!3m2!1sen!2suk!4v1728554572584!5m2!1sen!2suk',
            mapTitle: 'RBSA Gallery | Birmingham',
            mapAddress: '4 Brook St,',
            mapCity: 'Birmingham',
            mapPostCode: 'B3 1SA',
            directions: '"Textura" is displayed in the ground floor gallery, on the main wall to the right of the entrance.',
            storeUrl: 'https://rbsa.org.uk/rbsa-gallery-joins-forces-with-vieunite/',
            popupImg: mapPopup1 // 这里添加你的图片路径
        },
        {
            src: homeExample2,
            alt: 'Selfridges  | London',
            mapEmbedUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.934012142894!2d-0.15534192312068545!3d51.5144266101897!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876052d5803490d%3A0x811f22bae26bbdbe!2sSelfridges!5e0!3m2!1sen!2suk!4v1728552593267!5m2!1sen!2suk',
            mapTitle: 'Selfridges | London',
            mapAddress: '400 Oxford St,',
            mapCity: 'London',
            mapPostCode: 'W1A 1AB',
            directions: 'Go to the bottom floor and look for the smart tech section, they are against the back wall.',
            storeUrl: 'https://www.selfridges.com/GB/en/product/smartech-vieunite-textura-digital-canvas_R04300545/',
            popupImg: mapPopup2 // 这里添加你的图片路径

        },
        {
            src: homeExample3,
            alt: 'Estorick Gallery | London',
            mapEmbedUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2481.3255005553456!2d-0.10306062311928124!3d51.543930108033685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761b6480214f87%3A0x80ba257b14022d92!2sEstorick%20Collection%20of%20Modern%20Italian%20Art!5e0!3m2!1sen!2suk!4v1728554794806!5m2!1sen!2suk',
            mapTitle: 'Estorick Collection | London',
            mapAddress: '39A Canonbury Square,',
            mapCity: 'London',
            mapPostCode: 'N1 2AN',
            directions: '"Textura" is displayed on the ground floor, on one of the main walls in the hallway.',
            storeUrl: 'https://www.estorickcollection.com/news/2024/05/10/we-are-partnering-with-vieunite',
            popupImg: mapPopup3 // 这里添加你的图片路径
        },
        {
            src: homeExample4,
            alt: 'Printemps | Paris',
            mapEmbedUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20993.46750550073!2d2.2900303743164034!3d48.873779500000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66f051e3f022b%3A0xf443f2dee951e103!2sPrintemps%20Haussmann!5e0!3m2!1sen!2suk!4v1728554980070!5m2!1sen!2suk',
            mapTitle: 'Printemps | Paris',
            mapAddress: '64 Bd Haussmann,',
            mapCity: '75009, Paris',
            mapPostCode: 'France',
            directions: 'Coming soon',
            storeUrl: 'https://www.printemps.com/uk/en/printemps-paris-haussmann',
            popupImg: ' ' // 这里添加你的图片路径
        },
        {
            src: homeExample5,
            alt: 'KaDeWe | Berlin',
            mapEmbedUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2428.7588134982816!2d13.338418076926489!3d52.501605337590924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a850542272bf61%3A0xedabbb0cc3d90eec!2sKaDeWe%20-%20Kaufhaus%20des%20Westens!5e0!3m2!1sen!2suk!4v1728555047665!5m2!1sen!2suk',
            mapTitle: 'KaDeWe | Berlin',
            mapAddress: 'Tauentzienstraße 21-24,',
            mapCity: '10789 Berlin',
            mapPostCode: 'Germany',
            directions: 'Coming soon',
            storeUrl: 'https://www.kadewe.de/',
            popupImg: ' ' // 这里添加你的图片路径
        },
        {
            src: homeExample6,
            alt: 'La Rinascente | Rome',
            mapEmbedUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2969.5398275997586!2d12.48201377646005!3d41.902752663869656!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132f61aca8a05a77%3A0x957def62d797f9e6!2sRINASCENTE%20Rome!5e0!3m2!1sen!2suk!4v1728555111401!5m2!1sen!2suk',
            mapTitle: 'La Rinascente | Rome',
            mapAddress: 'Via del Tritone,',
            mapCity: '61, 00187 Roma RM,',
            mapPostCode: 'Italy',
            directions: 'Coming soon',
            storeUrl: 'https://www.rinascente.it/en/',
            popupImg: ' ' // 这里添加你的图片路径
        },
    ];

    const [index, setIndex] = useState(0);
    const [activeLineIndex, setActiveLineIndex] = useState(0);
    const sliderRef = useRef(null); // 创建ref
    const {windowWidth} = useWindowResize()
    const itemsPerPage = 3;
    const totalLines = windowWidth > 920 ? 4 : 6; // 固定显示4个横线
    const ScrollActive = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="4" viewBox="0 0 30 4" fill="none">
            <path d="M2 2H28" stroke="white" strokeWidth="3" strokeLinecap="round"/>
        </svg>
    );

    const ScrollInactive = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="4" viewBox="0 0 30 4" fill="none">
            <path d="M2 2H28" stroke="#717171" strokeWidth="3" strokeLinecap="round"/>
        </svg>
    );

    const slideRight = () => {
        const newIndex = (index + 1) % totalLines;
        setIndex(newIndex);
        setActiveLineIndex(prevLineIndex => (prevLineIndex + 1) % totalLines);
    };

    const slideLeft = () => {
        const newIndex = (index - 1 + totalLines) % totalLines;
        setIndex(newIndex);
        setActiveLineIndex(prevLineIndex => (prevLineIndex - 1 + totalLines) % totalLines);
    };
    const CloseIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="23" viewBox="0 0 24 25" fill="none">
            <path d="M23 1.70703L1 23.707" stroke="#A9A9A9" stroke-width="2" stroke-linecap="round"/>
            <path d="M1 1L23 23" stroke="#A9A9A9" stroke-width="2" stroke-linecap="round"/>
        </svg>
    );
    const handleDragStart = (e) => {
        if (e.type === 'touchstart') {
            sliderRef.current.startX = e.touches[0].clientX;
        } else {
            sliderRef.current.startX = e.clientX;
        }
    };

    const handleDragMove = (e) => {
        if (!sliderRef.current.startX) return;

        let currentX;
        if (e.type === 'touchmove') {
            currentX = e.touches[0].clientX;
        } else {
            currentX = e.clientX;
        }

        const diff = sliderRef.current.startX - currentX;
        if (diff > 50) {
            // Swipe left
            slideRight();
            sliderRef.current.startX = null;
        } else if (diff < -50) {
            // Swipe right
            slideLeft();
            sliderRef.current.startX = null;
        }
    };

    const handleDragEnd = () => {
        sliderRef.current.startX = null;
    };
    const isMobile = useMediaQuery({query: '(max-width: 1000px)'});


    return isLoaded ? (
            <div>
                {/*<NewHeader/>*/}
                <Layout>
                    {/*<Content style={{background: "#D9D9D9"}}>*/}
                    <Content style={{background: "#F0F0F0"}}>
                        <Row className={'m-top'} justify={"center"} style={{margin: '10rem 0 2rem 0'}} gutter={48}>
                            {isMobile ?
                                <Col span={22}>
                                    <h2 className="title-app-new m-font"
                                        style={{textAlign: "left", ...TitleFont}}>
                                        Contact Vieunite
                                    </h2>
                                    {/*<p style={{...Primary_Font}}>*/}
                                    <p className='context-app-new'>
                                        {Text["contact"]}
                                    </p>
                                    <p style={{...Primary_Font, color: "black", fontWeight: '800'}}>Vieunite Opening
                                        Hours</p>
                                    {/*<p style={{...Primary_Font, lineHeight: '0.1rem'}}>{Text['open']}</p>*/}
                                    <p className='openhours-app-new'>{Text['open']}</p>
                                </Col> :
                                <Col span={8}>
                                    <h2 className="title-app-new m-font"
                                        style={{textAlign: "left", ...TitleFont}}>
                                        Contact Vieunite
                                    </h2>
                                    {/*<p style={{...Primary_Font}}>*/}
                                    <p className='context-app-new'>
                                        {Text["contact"]}
                                    </p>
                                    <p style={{...Primary_Font, color: "black", fontWeight: '800'}}>Vieunite Opening
                                        Hours</p>
                                    {/*<p style={{...Primary_Font, lineHeight: '0.1rem'}}>{Text['open']}</p>*/}
                                    <p className='openhours-app-new'>{Text['open']}</p>
                                </Col>}


                            {isMobile ?
                                <div className='mobile-CallAndEmail'>
                                    <div className='mobile-ContactCall'>
                                        <div className='mobile-ContactCall-Title'>Call Vieunite</div>
                                        <div className='mobile-ContactCall-Context'>Please check our opening hours before
                                            calling
                                        </div>
                                        <div style={{
                                            width: "90vw",
                                            height: "5vh",
                                            paddingLeft: "8%",
                                            paddingRight: "1%",
                                            paddingTop: '2%'
                                        }}>
                                            <button
                                                className="homeMainButton black-border-hover-white"
                                                style={{width: "100%"}}
                                                onClick={_ => window.location.href = 'tel:+44 (0)3455481311'}>
                                                <p className={'homeMainButtonLnk homeOverV'} style={{margin: "0px"}}>
                                                    +44 (0)3455481311
                                                </p>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                :
                                <Col lg={6}>
                                    <div className='ContactEmailTemplate'>
                                        <div className="ContactEmailPic">
                                            <img src={ContactCall}></img>
                                        </div>


                                        <div style={{paddingRight: '5%', flex: 1}}>
                                            <div className="ContactEmailTitle" style={{marginBottom: '1vh'}}>
                                                Call Vieunite
                                            </div>
                                            <div className="ContactEmailContext" style={{marginBottom: '2vh'}}>
                                                Please check our opening hours before calling.
                                            </div>
                                        </div>

                                        <div style={{
                                            width: "100%",
                                            paddingLeft: "10%",
                                            paddingRight: "10%",
                                            marginTop: 'auto',
                                            paddingBottom: '2vh'
                                        }}>
                                            <button
                                                className="homeMainButton black-border-hover-white"
                                                style={{width: "100%"}}
                                                onClick={_ => window.location.href = 'tel:+44 (0)3455481311'}>
                                                <p className={'homeMainButtonLnk homeOverV'} style={{margin: "0px"}}>
                                                    +44 (0)3455481311
                                                </p>
                                            </button>
                                        </div>
                                    </div>
                                </Col>
                                // <Col lg={6}>
                                //     <div className="ContactEmailTemplate" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                                //         <div style={{ display: 'flex', flexDirection: 'row', paddingTop: '5vh' }}> {/* 确保整个内容垂直居中 */}
                                //             <div className="ContactEmailPic" style={{ display: 'flex', alignItems: 'center', marginRight: '1rem' }}> {/* 增加 alignItems 和 marginRight 来微调图标位置 */}
                                //                 <img src={ContactCall} alt="Call Icon" style={{ objectFit: 'contain' }} /> {/* 控制图标高度 */}
                                //             </div>
                                //
                                //             <div style={{ paddingRight: '5%', flex: 1 }}>
                                //                 <div className="ContactEmailTitle" style={{ marginBottom: '1vh' }}>
                                //                     Call Vieunite
                                //                 </div>
                                //                 <div className="ContactEmailContext" style={{ marginBottom: '2vh' }}>
                                //                     Please check our opening hours before calling.
                                //                 </div>
                                //             </div>
                                //         </div>
                                //
                                //         <div style={{
                                //             width: "100%",
                                //             paddingLeft: "10%",
                                //             paddingRight: "10%",
                                //             marginTop: 'auto',
                                //             paddingBottom: '2vh'
                                //         }}>
                                //             <button
                                //                 className="homeMainButton black-border-hover-white"
                                //                 style={{ width: "100%" }}
                                //                 onClick={_ => window.location.href = 'tel:03455-481311'}>
                                //                 <p className="homeMainButtonLnk homeOverV" style={{ margin: "0px" }}>
                                //                     03455 481311
                                //                 </p>
                                //             </button>
                                //         </div>
                                //     </div>
                                // </Col>
                            }

                            {
                                isMobile ?
                                    <div className='mobile-CallAndEmail1'>
                                        <div className='mobile-ContactEmail'>
                                            <div className='mobile-ContactCall-Title'>Email Vieunite</div>
                                            <div className='mobile-ContactCall-Context'>We aim to respond to all emails
                                                within
                                                one working day.
                                            </div>
                                            <div style={{
                                                width: "90vw",
                                                height: "5vh",
                                                paddingLeft: "8%",
                                                paddingRight: "1%",
                                                paddingTop: '2%'
                                            }}>
                                                <button
                                                    className="homeMainButton black-border-hover-white"
                                                    style={{width: "100%"}}
                                                    onClick={_ => window.location.href = 'mailto:info@vieunite.com'}>
                                                    <p className={'homeMainButtonLnk homeOverV'} style={{margin: "0px"}}>
                                                        info@vieunite.com
                                                    </p>
                                                </button>
                                            </div>
                                        </div>
                                    </div> :
                                    <Col lg={6}>
                                        <div className='ContactEmailTemplate'>
                                            <div className="ContactEmailPic">
                                                <img src={ContactEmail}></img>
                                            </div>


                                            <div style={{paddingRight: '5%', flex: 1}}>
                                                <div className="ContactEmailTitle" style={{marginBottom: '1vh'}}>
                                                    Email Vieunite
                                                </div>
                                                <div className="ContactEmailContext" style={{marginBottom: '2vh'}}>
                                                    We aim to respond to all emails within one working day.
                                                </div>
                                            </div>

                                            <div style={{
                                                width: "100%",
                                                paddingLeft: "10%",
                                                paddingRight: "10%",
                                                marginTop: 'auto',
                                                paddingBottom: '2vh'
                                            }}>
                                                <button
                                                    className="homeMainButton black-border-hover-white"
                                                    style={{width: "100%"}}
                                                    onClick={_ => window.location.href = 'mailto:info@vieunite.com'}>
                                                    <p className={'homeMainButtonLnk homeOverV'}
                                                       style={{margin: 0}}>info@vieunite.com</p>
                                                </button>
                                            </div>
                                        </div>
                                    </Col>
                                // <Col lg={6}>
                                //     <div className='ContactEmailTemplate'>
                                //         <div className="ContactEmailPic">
                                //             <img src={ContactEmail}></img>
                                //         </div>
                                //
                                //         <div style={{paddingRight: '5%'}}>
                                //             <div className="ContactEmailTitle">Email Vieunite</div>
                                //             <div className={'ContactEmailContext'}>We aim to respond to all emails
                                //                 within
                                //                 one working day.
                                //             </div>
                                //         </div>
                                //
                                //         <div style={{
                                //             width: "21vw",
                                //             height: "5vh",
                                //             paddingLeft: "10%",
                                //             paddingRight: "1%",
                                //             paddingTop: '6%'
                                //         }}>
                                //             <button
                                //                 className="homeMainButton black-border-hover-white"
                                //                 style={{width: "100%"}}
                                //                 onClick={_ => window.location.href = 'mailto:info@vieunite.com'}>
                                //                 <p className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                                //                     info@vieunite.com
                                //                 </p>
                                //             </button>
                                //         </div>
                                //
                                //     </div>
                                // </Col>
                            }
                        </Row>
                        {
                            isMobile ?
                                <div className='ContactMobileMap'>
                                    <div className='ContactMobileMapTitle'>Vieunite Ltd.</div>
                                    <div className='ContactMobileMapContext'>Woodgate Business Park <br/>38 Kettles Wood
                                        Dr <br/>Birmingham <br/>B32 3DB
                                    </div>
                                    <div className={'google-map'} style={{height: '100vh', width: '100%'}}>
                                        <GoogleMap
                                            // style={{
                                            //     borderTopRightRadius: '1.25rem',
                                            //     borderBottomRightRadius: '1.25rem',
                                            // }}
                                            mapContainerStyle={{
                                                height: '40vh', width: '100%',
                                            }}
                                            center={mapCenter}
                                            zoom={16}
                                        >
                                            <Marker position={mapCenter}/>
                                        </GoogleMap>
                                    </div>
                                </div>
                                :
                                <Row className={'m-map-row'} justify={"center"}
                                     style={{margin: '3rem 0rem 1rem 0', paddingLeft: '3rem'}}>
                                    <Col lg={5} xs={0} style={{
                                        backgroundColor: 'white',
                                        borderTopLeftRadius: '20px',
                                        borderBottomLeftRadius: '20px',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: "center",
                                        alignItems: 'left',
                                        textAlign: 'left',
                                        paddingLeft: '2vw',
                                    }}>
                                        <h2 className="ContactMapTitle">
                                            Vieunite Ltd.
                                        </h2>
                                        <p className={'ContactAddress'}>
                                            {Text["office"].map((txt) => <p
                                                style={{...Primary_Font, lineHeight: '0.9rem'}}>{txt}</p>)}
                                        </p>
                                    </Col>
                                    <Col lg={16} xs={6} style={{
                                        height: '60vh',
                                        width: '100%',
                                    }}>
                                        <div className={'google-map'} style={{height: '100vh', width: '64vw'}}>
                                            <GoogleMap
                                                style={{
                                                    borderTopRightRadius: '1.25rem',
                                                    borderBottomRightRadius: '1.25rem',
                                                }}
                                                mapContainerStyle={{
                                                    height: '60vh', width: '100%', borderTopRightRadius: '1.25rem',
                                                    borderBottomRightRadius: '1.25rem',
                                                }}
                                                center={mapCenter}
                                                zoom={16}
                                            >
                                                <Marker position={mapCenter}/>
                                            </GoogleMap>
                                        </div>
                                    </Col>
                                </Row>
                        }

                        {/* Image gallery section */
                        }
                        <Row>
                            <div className="ContactExampleContainer">
                                <p className="homeSection1TitleText-jack" style={{
                                    color: "white",
                                    marginLeft: windowWidth > 960 ? 'auto' : '10vw',
                                    paddingBottom: "1vw",
                                    fontSize:'2rem',
                                    fontWeight:"bolder"
                                }}>
                                    Experience Textura in Person
                                </p>
                                <div className='ContactPictureText m-no'>There's nothing quite like seeing the Textura
                                    digital
                                    canvas in person. The vibrant
                                    colors, intricate textures, and dynamic designs are best appreciated up close. We're
                                    proud to have Textura installed in art galleries and select stores around the world,
                                    offering you the opportunity to experience it firsthand. Below are some of the places
                                    where you can view the Textura. We invite you to visit and immerse yourself in its
                                    captivating beauty.
                                </div>
                                <div className="example-section-container-contact"
                                     style={{width: windowWidth < 920 ? '100vw' : ''}}
                                >
                                    <div ref={sliderRef}
                                         className="example-section-slider-jack"
                                         style={{
                                             width: windowWidth > 920 ? '80vw' : '100vw',
                                             transform: windowWidth > 920 ?
                                                 `translateX(-${index * 100 / itemsPerPage}%)` :
                                                 `translateX(-${index * 100}vw)`,
                                         }}
                                         onTouchStart={handleDragStart}
                                         onTouchMove={handleDragMove}
                                         onTouchEnd={handleDragEnd}
                                    >

                                        {images.map((image, imageIndex) => (
                                            <div key={imageIndex} className="example-section-image-container-jack"
                                                 style={{width: windowWidth < 920 ? '100vw' : '', margin: 'auto'}}
                                            >
                                                <Col key={index}
                                                     style={{
                                                         display: "flex",
                                                         alignItems: "center",
                                                         justifyContent: "center",
                                                         margin: 'auto',
                                                         width: windowWidth < 920 ? '100vw' : ''
                                                     }}
                                                     onClick={() =>
                                                         handleImageClick(image.src, image.alt, image.mapEmbedUrl,
                                                             image.mapTitle, image.mapAddress, image.mapCity,
                                                             image.mapPostCode, image.directions, image.storeUrl,image.popupImg)}>
                                                    <img src={image.src} alt={image.alt}
                                                         className="example-section-image-jack"
                                                         style={{width: windowWidth < 920 ? '80vw' : '',cursor: 'pointer'}}
                                                    />
                                                </Col>
                                                <div className="example-section-overlay">
                                                    <div className="example-section-text"
                                                         style={{marginLeft: windowWidth < 920 ? '10vw' : ''}}>{image.alt}</div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <div style={{
                                        display: 'flex',
                                        justifyContent: windowWidth > 920 ? 'space-between' : 'center',
                                        alignItems: 'center',
                                        marginTop: '1.25rem'
                                    }}>
                                        <img className={'m-no'} src={leftScroll} alt="Scroll Left" onClick={slideLeft}
                                             style={{cursor: 'pointer'}}/>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            {Array.from({length: totalLines}).map((_, idx) => (
                                                <div key={idx} style={{margin: '0 0.125rem'}}>
                                                    {activeLineIndex === idx ? <ScrollActive/> : <ScrollInactive/>}
                                                </div>
                                            ))}
                                        </div>
                                        <img className={'m-no'} src={rightScroll} alt="Scroll Right" onClick={slideRight}
                                             style={{cursor: 'pointer'}}/>
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </Content>
                    {/*<NoContactFooter/>*/}
                </Layout>


                {
                    isMobile ?
                        <Modal
                            title={null}
                            visible={isModalVisible}
                            onOk={handleModalClose}
                            onCancel={handleModalClose}
                            footer={null}
                            // width={600}
                            // height={800}
                            className="custom-modal-Jack"
                            closeIcon={<CloseIcon/>}
                        >
                            <Row gutter={16} align='middle'>
                                <Col span={30} className="modal-directions">
                                    <div className='Mo-MapTitle'>{modalContent.mapTitle}</div>
                                    <div className='Mo-MapAddress'>{modalContent.mapAddress}</div>
                                    <div className='Mo-MapCity'>{modalContent.mapCity}</div>
                                    <div className='Mo-MapPostcode'>{modalContent.mapPostCode}</div>
                                    <button
                                        className="homeMainButton black-border-hover-white Mo-MapButton"
                                        onClick={_ => window.open(modalContent.storeUrl, "_blank")}>
                                        <p className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                                            VISIT STORE WEBSITE
                                        </p>
                                    </button>
                                    <div className='Mo-MapButtonText'>Please check opening hours on their website</div>
                                    <div className='Mo-popMap'>
                                        <iframe
                                            src={modalContent.mapEmbedUrl}
                                            width="100%"
                                            height="400"
                                            style={{
                                                border: '0',
                                                borderRadius: '1.438rem'
                                            }} // Added border radius as per design
                                            allowFullScreen=""
                                            loading="lazy"
                                        ></iframe>
                                    </div>
                                    {/*<div className="MapTexDic">Textura Directions</div>*/}
                                    {/*<div className="MapTexDicContent">{modalContent.directions}</div>*/}
                                    {modalContent.mapTitle === 'RBSA Gallery | Birmingham' ||
                                    modalContent.mapTitle === 'Selfridges | London' ||
                                    modalContent.mapTitle === 'Estorick Collection | London' ? (
                                        <>
                                            <div className="MapTexDic">Textura Directions</div>
                                            <div className="MapTexDicContent">{modalContent.directions}</div>
                                        </>
                                    ) : null}
                                    <img src={modalContent.popupImg} style={{width: '100%', marginTop: '1.25rem'}}/>
                                </Col>
                            </Row>
                        </Modal>
                        :
                        <Modal
                            title={null}
                            visible={isModalVisible}
                            onOk={handleModalClose}
                            onCancel={handleModalClose}
                            footer={null}
                            width={1200}
                            className="custom-modal-Jack"
                            closeIcon={<CloseIcon/>}
                        >
                            <Row gutter={16}>
                                <Col span={8} className="modal-directions">
                                    <div className='MapTitle'>{modalContent.mapTitle}</div>
                                    <div className='MapAddress'>{modalContent.mapAddress}</div>
                                    <div className='MapCity'>{modalContent.mapCity}</div>
                                    <div className='MapPostcode'>{modalContent.mapPostCode}</div>
                                    <button
                                        className="homeMainButton black-border-hover-white "
                                        onClick={_ => window.open(modalContent.storeUrl, "_blank")}>
                                        <p className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                                            VISIT STORE WEBSITE
                                        </p>
                                    </button>
                                    <div className='MapButtonText'>Please check opening hours on their website</div>
                                    {/*<div className="MapTexDic">Textura Directions</div>*/}
                                    {/*<div className="MapTexDicContent">{modalContent.directions}</div>*/}
                                    {modalContent.mapTitle === 'RBSA Gallery | Birmingham' ||
                                    modalContent.mapTitle === 'Selfridges | London' ||
                                    modalContent.mapTitle === 'Estorick Collection | London' ? (
                                        <>
                                            <div className="MapTexDic">Textura Directions</div>
                                            <div className="MapTexDicContent">{modalContent.directions}</div>
                                        </>
                                    ) : null}
                                    <img src={modalContent.popupImg} style={{width: '100%', marginTop: '1.25rem'}}/>
                                </Col>
                                <Col span={16} className="modal-map-Jack" style={{height: '62vh'}} align='middle'>
                                    <iframe
                                        src={modalContent.mapEmbedUrl}
                                        width="100%"
                                        height="100%"
                                        style={{border: '0', borderRadius: '1.438rem'}} // Added border radius as per design
                                        allowFullScreen=""
                                        loading="lazy"
                                    ></iframe>
                                </Col>
                            </Row>
                        </Modal>
                }
            </div>
        ) :
        <></>
}


export default NewContact;
