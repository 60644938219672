import React from "react";
import {Helmet} from "react-helmet";

export const A8Meta = () => (
    <Helmet>
            <title>Doris Luming | Vieunite</title>
            <meta name="description"
                  content="Filmmaker, photographer and visual artist based in London who sees her works as the expression of emotion and the recreation of scenes in dreams. "/>
            <meta name="keywords"
                  content="digital canvas, digital frame, digital art frame,  digital art canvas,picture frame,  frame, the frame, smart frame, smart digital art frame,  digital art, art, photography frame, artworks, gallery, photo frame, digital photo frame, textura, texturite technology, VT27WGV1, vieunite, digital photo to canvas, digital picture frame, best digital picture frame, electronic photo frame, wifi picture frame, video picture frame"/>
            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:site" content="@VieuniteHQ"/>
            <meta name="twitter:title" content="Doris Luming | Vieunite"/>
            <meta name="twitter:description"
                  value="Filmmaker, photographer and visual artist based in London who sees her works as the expression of emotion and the recreation of scenes in dreams. "/>
            <meta name="twitter:url" content="https://vieunite.com/dorisluming"/>
            <meta name="twitter:image" content={'https://vieunite.com/img/Social Card - Doris Luming.jpg'}/>
            <meta property="og:url" content="https://vieunite.com/dorisluming"/>
            <meta property="og:type" content="article"/>
            <meta property="og:title" content="Doris Luming | Vieunite"/>
            <meta property="og:description"
                  content="Filmmaker, photographer and visual artist based in London who sees her works as the expression of emotion and the recreation of scenes in dreams. "/>
            <meta property="og:image" content="https://vieunite.com/img/Social Card - Doris Luming.jpg"/>
            <meta property="og:image:width" content="930"/>
            <meta property="og:image:height" content="620"/>
    </Helmet>);