import React from "react";
import {Helmet} from "react-helmet";

export const A21Meta = () => (
    <Helmet>
        <title> Paul Gittins | Vieunite</title>
        <meta name="description"
              content="Explore Paul Gittins' innovative multimedia art, blending music, performance, sculpture, and video. His large-scale installations and playful creations transform everyday life into mesmerizing abstract patterns, often featuring shadow dancers and live musicians."/>
        <meta name="keywords"
              content=" digital canvas, digital frame, digital art frame, digital art canvas, Paul Gittins, multimedia art, performance art, sculpture, video art, abstract patterns, shadow dancers, live musicians, Oak Apple Orchestra, improvisational music, large-scale installations, Vieunite artist"/>
    </Helmet>);