import React, {useState} from 'react';
import {Button, Col, Layout, Modal, Row} from "antd";
import {Content} from "antd/es/layout/layout";
import img1 from './imgs/img1.png'
import img2 from './imgs/img2.png'
import img3 from './imgs/Textura Pro_Our packages.webp'
import img4 from './imgs/Textura Pro_Match.webp'
import img5 from './imgs/UPDATED_02.webp'
import m_img_1 from './imgs/m_img_canvas_1.png'
import m_img_2 from './imgs/m_img_canvas_2.png'
import m_img_3 from './imgs/m_img_canvas_3.png'
import m_img_4 from './imgs/m_img_canvas_4.png'
import m_img_5 from './imgs/m_img_canvas_5.png'
import {ICON_LIST, TexturaProText} from "./text";
import {Carousel} from 'antd';
import './style.scss'
import slider1 from './imgs/Image 1.jpg'
import slider2 from './imgs/Image 3.jpg'
import slider3 from './imgs/Image 4.jpg'
import slider4 from './imgs/Image 5.jpg'
import slider5 from './imgs/Image 6.jpg'
import slider6 from './imgs/Video in Carousel.mp4'
import {ProTMeta} from "./meta";
import useWindowResize from "../../apps/Hooks/useWindowResize";
import {post_api} from "../../portal/serverTemp";


const PreArrow = () =>
    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="45" viewBox="0 0 28 45" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd"
              d="M26.4026 7.53936C27.9647 5.97727 27.9647 3.44461 26.4026 1.88251C24.8405 0.320413 22.3079 0.320413 20.7458 1.88251L10.5589 12.0694L0.372007 22.2563L0.563914 22.4482L0.261719 22.7504L20.6355 43.1242C22.1976 44.6863 24.7303 44.6863 26.2924 43.1242C27.8545 41.5621 27.8545 39.0294 26.2924 37.4673L11.3835 22.5585L16.2158 17.7263L26.4026 7.53936Z"
              fill="white"/>
    </svg>
const NxArrow = () => <svg xmlns="http://www.w3.org/2000/svg" width="28" height="45" viewBox="0 0 28 45" fill="none">
    <path fill-rule="evenodd" clip-rule="evenodd"
          d="M1.43329 7.53936C-0.128805 5.97727 -0.128805 3.44461 1.43329 1.88251C2.99539 0.320413 5.52805 0.320413 7.09015 1.88251L17.277 12.0694L27.4639 22.2563L27.272 22.4482L27.5742 22.7504L7.20043 43.1242C5.63834 44.6863 3.10568 44.6863 1.54358 43.1242C-0.0185165 41.5621 -0.0185165 39.0294 1.54358 37.4673L16.4524 22.5585L11.6202 17.7263L1.43329 7.53936Z"
          fill="white"/>
</svg>
// Updated arrow components with additional styling
const StyledPreArrow = ({onClick}) => (
    <div className="carousel-arrow carousel-prev-arrow" onClick={onClick}>
        <PreArrow></PreArrow>
    </div>
);

const StyledNxArrow = ({onClick}) => (
    <div className="carousel-arrow carousel-next-arrow" onClick={onClick}>
        <NxArrow></NxArrow>
    </div>
);
const FiveStars = () => <svg xmlns="http://www.w3.org/2000/svg" width="148" height="20" viewBox="0 0 148 22"
                             fill="none">
    <path
        d="M10.7615 4.61688L12.1538 7.96427L12.5056 8.81021L13.4189 8.88342L17.0327 9.17314L14.2794 11.5317L13.5836 12.1277L13.7961 13.0189L14.6373 16.5453L11.5434 14.6556L10.7615 14.178L9.97966 14.6556L6.88575 16.5453L7.72694 13.0189L7.93952 12.1277L7.24371 11.5317L4.49039 9.17314L8.10417 8.88342L9.01744 8.81021L9.36928 7.96427L10.7615 4.61688Z"
        fill="black" stroke="#030303" stroke-width="3"/>
    <path
        d="M42.2606 4.61688L43.6528 7.96427L44.0047 8.81021L44.9179 8.88342L48.5317 9.17314L45.7784 11.5317L45.0826 12.1277L45.2952 13.0189L46.1364 16.5453L43.0424 14.6556L42.2606 14.178L41.4787 14.6556L38.3848 16.5453L39.226 13.0189L39.4385 12.1277L38.7427 11.5317L35.9894 9.17314L39.6032 8.88342L40.5165 8.81021L40.8683 7.96427L42.2606 4.61688Z"
        fill="black" stroke="#030303" stroke-width="3"/>
    <path
        d="M73.7576 4.61688L75.1499 7.96427L75.5017 8.81021L76.415 8.88342L80.0288 9.17314L77.2755 11.5317L76.5797 12.1277L76.7922 13.0189L77.6334 16.5453L74.5395 14.6556L73.7576 14.178L72.9758 14.6556L69.8818 16.5453L70.723 13.0189L70.9356 12.1277L70.2398 11.5317L67.4865 9.17314L71.1003 8.88342L72.0135 8.81021L72.3654 7.96427L73.7576 4.61688Z"
        fill="black" stroke="#030303" stroke-width="3"/>
    <path
        d="M105.263 4.61688L106.656 7.96427L107.008 8.81021L107.921 8.88342L111.535 9.17314L108.781 11.5317L108.086 12.1277L108.298 13.0189L109.139 16.5453L106.045 14.6556L105.263 14.178L104.482 14.6556L101.388 16.5453L102.229 13.0189L102.441 12.1277L101.746 11.5317L98.9923 9.17314L102.606 8.88342L103.519 8.81021L103.871 7.96427L105.263 4.61688Z"
        fill="black" stroke="#030303" stroke-width="3"/>
    <path
        d="M136.763 4.61688L138.155 7.96427L138.507 8.81021L139.42 8.88342L143.034 9.17314L140.28 11.5317L139.585 12.1277L139.797 13.0189L140.638 16.5453L137.544 14.6556L136.763 14.178L135.981 14.6556L132.887 16.5453L133.728 13.0189L133.94 12.1277L133.245 11.5317L130.491 9.17314L134.105 8.88342L135.018 8.81021L135.37 7.96427L136.763 4.61688Z"
        fill="black" stroke="#030303" stroke-width="3"/>
</svg>

const TexturaPro = (props) => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const {windowWidth} = useWindowResize()
    const [isRemindModalOpen, setIsRemindModalOpen] = useState(false)
    const makeMuted = (elt) => {
        if (elt) {
            elt.muted = true;
        }
    };
    return (
        <div>
            <ProTMeta/>
            <Layout>
                <Content style={{backgroundColor: '#F4F4F4'}}>
                    <Row justify={"center"} style={{marginTop: '8rem'}} gutter={32}>
                        <Col lg={15}>
                            <Carousel arrows={true}
                                      prevArrow={<StyledPreArrow onClick={() => setCurrentSlide(currentSlide - 1)}/>}
                                      nextArrow={<StyledNxArrow onClick={() => setCurrentSlide(currentSlide + 1)}/>}
                                      afterChange={(current) => setCurrentSlide(current)}
                                      initialSlide={currentSlide}
                            >
                                <div>
                                    <video playsInline loop autoPlay ref={makeMuted} className={'slider-img'}
                                           style={{}}
                                           muted rel={"preload"}>
                                        <source src={slider6}></source>
                                    </video>
                                </div>
                                <div>
                                    <img className={'slider-img'} src={slider4}/>
                                </div>
                                <div>
                                    <img className={'slider-img'} src={slider1}/>
                                </div>
                                <div>
                                    <img className={'slider-img'} src={slider2}/>
                                </div>
                                <div>
                                    <img className={'slider-img'} src={slider3}/>
                                </div>

                                <div>
                                    <img className={'slider-img'} src={slider5}/>
                                </div>

                            </Carousel>
                        </Col>
                        <Col lg={5} xs={24} style={windowWidth <= 600 ? {
                            display: "flex", justifyContent: "center",
                            alignItems: "center", flexDirection: "column"
                        } : {}}>
                            <p className={windowWidth > 600 ? 'textura-pro-top-title' : 'm-textura-pro-top-title'}>
                                Textura Pro Digital Canvas
                            </p>
                            <p className={windowWidth > 600 ? 'textura-pro-top-txt' : 'm-textura-pro-top-txt'}>
                                {windowWidth > 600 ?
                                    <ul style={{
                                        margin: '5vh 0 0 -1vw',
                                        fontSize: '0.6vw',
                                        width: '26vw'
                                    }}>
                                        <li>Textura Pro digital canvas uses texture-accurate display technology</li>
                                        <li>Displays every brush stroke and intricacy in great detail</li>
                                        <li>Integrated mounting plate allows for flush wall mounting</li>
                                        <li>Allows you to change between landscape and portrait orientations</li>
                                        <li>Choose from four real wood frame finish options</li>
                                        <li>Vieunite Pro Portal gives full control of Textura Pro management</li>
                                    </ul> :
                                    <ul style={{
                                        margin: '0 0 10vh -8vw',
                                        fontSize: '3.2vw',
                                        width: '90vw',
                                        textAlign:'left'
                                    }}>
                                        <li>Textura Pro digital canvas uses texture-accurate display technology</li>
                                        <li>Displays every brush stroke and intricacy in great detail</li>
                                        <li>Integrated mounting plate allows for flush wall mounting</li>
                                        <li>Allows you to change between landscape and portrait orientations</li>
                                        <li>Choose from four real wood frame finish options</li>
                                        <li>Vieunite Pro Portal gives full control of Textura Pro management</li>
                                    </ul>}
                            </p>
                            <p style={{
                                textDecoration: "underline",
                                margin: '-10vh 0 2vh 1vw',
                                fontWeight: '600',
                                cursor: "pointer",
                                color:"black",
                                fontSize:windowWidth > 600?'0.68vw':'3vw'
                            }}
                               onClick={_=>window.open('https://vieunite.com/TexturaProSpec.pdf')}
                            >
                                View or download the Textura Pro Specification Sheet
                            </p>
                            <p className={'pro-buy-bottom-text-1'}>
                                Cost for hardware £619 + VAT.
                            </p>
                            <p className={'pro-buy-bottom-text-2'}>
                                Subscription fees may apply
                            </p>
                            <Button
                                className={windowWidth > 600 ? 'pro-buy-now-btn pro-btn-hover-to-white' : 'm-pro-buy-now-btn pro-btn-hover-to-white'}
                                onClick={_ =>
                                    windowWidth > 600 ? window.location.href = '/business/shopping' :
                                        setIsRemindModalOpen(true)}
                            >
                                Buy now
                            </Button>
                            {windowWidth > 600 && <div className={'textura-pro-contact-div'}>
                                <p className={'textura-pro-contact-div-1'}>
                                    Need advice what package is best for your business? Our team can provide expert
                                    guidance.
                                </p>
                                <p className={'textura-pro-contact-div-2'}>
                                    Call: 03455-481311
                                </p>
                                <p className={'textura-pro-contact-div-3'}>
                                    or email: info@vieunite.com
                                </p>
                            </div>}
                            <div className={'textura-pro-reviews'} style={windowWidth <= 600 ? {marginTop: '2vh'} : {}}>
                                <FiveStars/>
                                <p className={'textura-pro-rv'}
                                   onClick={_ => window.open('https://www.google.com/search?q=vieunite&rlz=1C5CHFA_enGB1000GB1000&oq=vieunite&gs_lcrp=EgZjaHJvbWUqDAgAECMYJxiABBiKBTIMCAAQIxgnGIAEGIoFMhMIARAuGIMBGK8BGMcBGLEDGIAEMgYIAhAAGAMyBggDEEUYPDIGCAQQRRg8MgYIBRBFGDwyBggGEEUYPDIGCAcQRRg80gEIMTY3MGowajeoAgCwAgA&sourceid=chrome&ie=UTF-8#lrd=0x4870977ec8050345:0x915d3cd29fa952d8,1,,,,')}>
                                    Reviews
                                </p>
                            </div>
                            {windowWidth <= 600 && <div className={'m-textura-pro-contact-div'}>
                                <p className={'m-textura-pro-contact-div-1'}>
                                    Need advice what package is best for your business? Our team can provide expert
                                    guidance.
                                </p>
                                <p className={'m-textura-pro-contact-div-2'}>
                                    Call: 03455-481311
                                </p>
                                <p className={'m-textura-pro-contact-div-3'}>
                                    or email: info@vieunite.com
                                </p>
                            </div>}
                        </Col>
                    </Row>
                    <Row justify={"center"} style={windowWidth > 600 ? {padding: '4rem 0 4rem 0', margin: "auto"} : {
                        padding: '1rem 0 1rem 0',
                        margin: "0 0 0 0",
                        width: "90vw"
                    }}>
                        {ICON_LIST.map((item, idx) =>
                            <Col
                                className={windowWidth > 600 ? 'textura-pro-icon-and-txt' : 'm-textura-pro-icon-and-txt'}>

                                {windowWidth > 600 ? <>{item[0]}
                                    <p className={'textura-pro-small-icon-txt'} style={{
                                        margin: '1vh 0 0 0'
                                    }}>{item[1]}</p>
                                    <p className={'textura-pro-small-icon-txt'}>{item[2]}</p>
                                </> : <Row justify={"center"} style={{
                                    margin: '0 0 0 0'
                                }}>
                                    <span style={{
                                        width: '30vw',
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        margin: '1vh 0 1vh 0'
                                    }}>{item[0]} </span>
                                    <span className={'m-textura-pro-small-icon-txt'} style={{
                                        margin: '1vh  0 1vh 0'
                                    }}>{item[1] + ' ' + item[2]}</span>
                                </Row>}
                            </Col>
                        )}
                    </Row>

                    {windowWidth <= 600 && <Row justify={"center"} className={'m-yes'} style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <p className={'m-pro-home-title'} style={{width: '90vw'}}>
                            Creating Luxury Spaces
                        </p>
                        <img className={'m-j-img'} src={m_img_1}/>
                        <p className={'m-pro-home-txt'}
                           style={{marginBottom: '4vh', fontSize: '3.6vw', marginTop: '4vh'}}>
                            Open the doors to a world of digital art and transform the atmosphere and ambiance of your
                            business with Vieunite Pro. Easily update the artwork displayed or change the frame finish
                            and orientation of your Textura Pro for a fresh look.
                        </p>

                        <p className={'m-pro-home-title'} style={{width: '90vw'}}>
                            Give Your Business
                            the Edge
                        </p>
                        <img className={'m-j-img'} src={m_img_2}/>
                        <p className={'m-pro-home-txt'}
                           style={{marginBottom: '4vh', fontSize: '3.6vw', marginTop: '4vh'}}>
                            Leverage the benefits of displaying art in your business, at a fraction of the cost.
                            Vieunite Pro allows you improve customer experiences while strengthening your brand
                            awareness and identity in an innovative way, giving you the advantage over competition.
                        </p>

                        <p className={'m-pro-home-title'} style={{width: '90vw'}}>
                            Innovative New Display Channel/Medium
                        </p>
                        <img className={'m-j-img'} src={m_img_3}/>
                        <p className={'m-pro-home-txt'}
                           style={{marginBottom: '4vh', fontSize: '3.6vw', marginTop: '4vh'}}>
                            By replicating the surface textures of traditional artwork, we have created a medium that is
                            almost indistinguishable from the real thing. Beyond mere wall decor, it captivates your
                            audience, allowing you to deliver diverse messages, including promotional content, in a
                            unique way.
                        </p>
                        <p className={'m-pro-home-title'} style={{width: '90vw'}}>
                            Enhance Your Team
                        </p>
                        <img className={'m-j-img'} src={m_img_4}/>
                        <p className={'m-pro-home-txt'}
                           style={{marginBottom: '4vh', fontSize: '3.6vw', marginTop: '4vh'}}>
                            Displaying art in the workplace has been proven to boost creativity, uplift mood, increase
                            productivity, and reduce employee stress. Vieunite Pro also creates a new internal
                            communications channel by engaging with your staff in an innovative way.
                        </p>
                        <img className={'m-j-img'} src={m_img_5}/>
                        <p className={'m-pro-home-title'} style={{width: '90vw', color: "white", marginTop: '-33vh'}}>
                            Additional Services
                        </p>
                        <p className={'m-pro-home-txt'} style={{
                            marginBottom: '4vh',
                            width: '70vw',
                            color: "white",
                            marginTop: '-3vh',
                            fontSize: '3.8vw'
                        }}>
                            Vieunite Pro is designed for easy installation, integration, and use, but we offer tailored
                            assistance for those who need it. From site surveys to installation and content management,
                            we've got you covered! We also collaborate with artists for custom artwork or curated
                            playlists for your business. Prices are available on request.
                        </p>
                    </Row>}
                    {windowWidth > 600 && <Row justify={"center"}>
                        <Col lg={10}>
                            <p className={'textura-pro-title'}>
                                {TexturaProText['l1'][0]}
                            </p>
                            <p className={'textura-pro-txt'}>
                                {TexturaProText['l1'][1]}
                            </p>
                            <img style={{borderRadius: '1rem', width: '37vw'}} src={img1}/>
                        </Col>
                        <Col lg={10}>
                            <p className={'textura-pro-title'}>
                                {TexturaProText['r1'][0]}
                            </p>
                            <p className={'textura-pro-txt'}>
                                {TexturaProText['r1'][1]}
                            </p>
                            <img style={{borderRadius: '1rem', width: '37vw'}} src={img2}/>
                        </Col>
                    </Row>}
                    {windowWidth > 600 && <Row justify={"center"} style={{
                        margin: '4rem 0 4rem 0'
                    }}>
                        <Col lg={10}>
                            <p className={'textura-pro-title'}>
                                {TexturaProText['l2'][0]}
                            </p>
                            <p className={'textura-pro-txt'}>
                                {TexturaProText['l2'][1]}
                            </p>
                            <img style={{borderRadius: '1rem', width: '37vw'}} src={img3}/>
                        </Col>
                        <Col lg={10}>
                            <p className={'textura-pro-title'}>
                                {TexturaProText['r2'][0]}
                            </p>
                            <p className={'textura-pro-txt'}>
                                {TexturaProText['r2'][1]}
                            </p>
                            <img style={{borderRadius: '1rem', width: '37vw'}} src={img4}/>
                        </Col>
                    </Row>}
                    {windowWidth > 600 && <Row justify={"center"} className={'j-section'} style={{marginTop: '-1rem'}}>
                        <img src={img5} style={{'width': '78.8vw', margin: '4rem auto 4rem 8vw'}}
                             className={'j-img'}/>
                        <div className="j-text-overlay" style={{top: '12.9vh', left: '13%'}}>
                            <p className={'j-h1-add'}>Additional Services</p>
                            <p className={'j-txt-add-h'}>
                                We have made Vieunite Pro as easy as possible to integrate, install and use. That being
                                said, some end users may require assistance in specific areas; don’t worry, we have you
                                covered!
                            </p>
                            <p className={'j-txt-add-h'}>
                                If necessary, we can conduct site surveys, install the digital canvases at your
                                premises,
                                provide maintenance packages and even manage your content for you. If you’re looking for
                                an
                                even more bespoke offering, we can collaborate with one of our artists to create custom
                                artwork, or curate tailored playlists for your business or brand. Prices are available
                                on
                                request.
                            </p>
                        </div>
                    </Row>}
                </Content>
                <Modal className={'textura-pro-remind-modal'}
                       visible={isRemindModalOpen}
                       onCancel={_ => setIsRemindModalOpen(false)}
                >
                    <p className={'textura-pro-remind-modal-txt'}>
                        The Textura Pro Canvas is currently only available to purchase on desktop devices.
                    </p>
                </Modal>
            </Layout>
        </div>
    )
}

export default TexturaPro;
