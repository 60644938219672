import React from "react";
import {Helmet} from "react-helmet";
import socialCard from "../../img/Social Cards/Social Card - Homepage.jpg"

export const ProTMeta = () => (
    <Helmet>
        <title>Revamp Your Business Aesthetics with Textura Pro Digital Canvas</title>
        <meta name="description"
              content="With the Vieunite Portal you can easily upload content, craft personalised playlists, discover unique art, and more - all in just a few simple steps!"/>
        <meta name="keywords"
              content="digital canvas, digital frame, digital art frame,  digital art canvas,picture frame,  frame, the frame, smart frame, smart digital art frame,  digital art, art, photography frame, artworks, gallery, photo frame, digital photo frame, textura, texturite technology, VT27WGV1, vieunite, digital photo to canvas, digital picture frame, best digital picture frame, electronic photo frame, wifi picture frame, video picture frame"/>
        <meta name="google-site-verification" content="BTT1HZyXXJPWJ5LsEJOnHAU-8CVMkBzFf0hmYuMElV0"/>
        <meta name="twitter:card" value="summary_large_image"/>
        <meta name="twitter:site" value="@VieuniteHQ"/>
        <meta name="twitter:title" value="Vieunite | Official Site"/>
        <meta name="twitter:description"
              value=" Discover art with an innovative system that combines the best in digital canvas technology with an online community"/>
        <meta name="twitter:url" value="https://vieunite.com"/>
        <meta name="twitter:image" value={'https://vieunite.com/img/Social Card - Homepage.jpg'}/>
        <meta property="og:url" content="https://vieunite.com/home"/>
        <meta property="og:type" content="article"/>
        <meta property="og:title" content="Vieunite | Official Site"/>
        <meta name="description" property="og:description"
              content="Discover art with an innovative system that combines the best in digital canvas technology with an online community"/>
        <meta name="image" property="og:image" content="https://vieunite.com/img/Social Card - Homepage.jpg"/>
        <meta property="og:image:width" content="930"/>
        <meta property="og:image:height" content="620"/>
    </Helmet>);