import React from "react";
import {Helmet} from "react-helmet";

export const A4Meta = () => (
    <Helmet>
            <title>Jo Vincent | Vieunite</title>
            <meta name="description"
                  content="Jo Vincent is a self-taught disabled artist: her art focuses on colourful atmospheric, emotion-filled, vivid abstract paintings in oils and acrylics on canvas. "/>
            <meta name="keywords"
                  content="digital canvas, digital frame, digital art frame,  digital art canvas,picture frame,  frame, the frame, smart frame, smart digital art frame,  digital art, art, photography frame, artworks, gallery, photo frame, digital photo frame, textura, texturite technology, VT27WGV1, vieunite, digital photo to canvas, digital picture frame, best digital picture frame, electronic photo frame, wifi picture frame, video picture frame"/>
            <meta name="twitter:card" value="summary_large_image"/>
            <meta name="twitter:site" value="@VieuniteHQ"/>
            <meta name="twitter:title" value="Jo Vincent"/>
            <meta name="twitter:description"
                  value="Jo Vincent is a self-taught disabled artist: her art focuses on colourful atmospheric, emotion-filled, vivid abstract paintings in oils and acrylics on canvas. "/>
            <meta name="twitter:url" value="https://vieunite.com/jovincent"/>
            <meta name="twitter:image" value={'https://vieunite.com/img/Social Card - Jo Vincent.jpg'}/>

            <meta property="og:url" content="https://vieunite.com/jovincent"/>
            <meta property="og:type" content="article"/>
            <meta property="og:title" content="Jo Vincent"/>
            <meta property="og:description"
                  content="Jo Vincent is a self-taught disabled artist: her art focuses on colourful atmospheric, emotion-filled, vivid abstract paintings in oils and acrylics on canvas. "/>
            <meta property="og:image" content={'https://vieunite.com/img/Social Card - Jo Vincent.jpg'}/>
            <meta property="og:image:width" content="930"/>
            <meta property="og:image:height" content="620"/>
    </Helmet>);