export const SINGLE_PRICE = 619;
export const VAT_RATE = 0.2;
export const STRIPE_PUBLIC_KEY = 'pk_test_51LZdAEJnawKkPfwbAqosO6uS18wR1vrslInOAq1Kxp7I9bmYRw2fEgPlPblx1iFqCcMglPZm1a6hbTvON5rkImhH00txl1sA0m'
// export const STRIPE_SECRET_KEY = '<YOUR_StripeTestSecretKey_HERE>00KpwBbh6'

export const IdxToCanvas = {
    0: 'Birch',
    1: 'Black',
    2: 'Pine',
    3: 'Walnut'
};

export const SUB_FEES = [0, 99, 189]
export const DAYS_A_WEEK = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
