import React, {useEffect, useState} from 'react';
import {useMediaQuery} from 'react-responsive';
import 'antd/dist/antd.min.css';
import './App.css';
import HomePage from "./pages/Home/HomePage";
import TestCaroucel from "./AutoplayCarousel/AutoplayCarousel"
// import HomePageTest from "./pages/Home/HomePageJun";
import ArtPage from "./pages/Art/ArtPage";
import ArtPageMobile from "./pages/Art/ArtPageMobile";
import BrochurePage from "./pages/Art/Brochure";
import HomePageTest from "./pages/Home/HomePageJack";
import HomeVieuniteApp from "./pages/Home/VieuniteApp";
import ProductDetail from "./pages/Textura/ProductDetail";
import {Routes, Route, BrowserRouter} from "react-router-dom";
import BasicLayout from "./BasicLayout";//root
import Artists from "./pages/Artists/Artists";
import TermsAndConditions from "./pages/Terms/TermsAndConditions";
import EndUserAgreement from "./pages/Terms/EndUserAgreement";
import AppDownload from "./pages/AppDownload/AppDownload";
import Profile from "./pages/Profile/Profile";

import AutoScrollToTop from "./components/AutoScrollToTop";
import Login from "./apps/Login/Login";
import ArtistPortalHome from "./apps/ArtistPortal/ArtistPortalHome/ArtistPortalHome";
import Contact from "./pages/Contact";
import HelpMain from "./pages/Help/HelpMain";
import HelpVieuniteApp from "./pages/Help/HelpVieuniteApp";
import HelpTextura from "./pages/Help/HelpTextura";
import HelpOnlineStore from "./pages/Help/HelpOnlineStore";
import HelpRequestSupport from "./pages/Help/HelpRequestSupport";
import PortalLayout from "./apps/ArtistPortal/Layout/PortalLayout";
import ArtList from "./apps/ArtistPortal/ArtList";
import ProfileEditor from "./apps/ArtistPortal/ProfileEditor";
import BalanceBoard from "./apps/ArtistPortal/BalanceSystem/BalanceBoard";
import TransactionHistory from "./apps/ArtistPortal/BalanceSystem/TransactionHistory";
import WithdrawHistory from "./apps/ArtistPortal/BalanceSystem/WithdrawHistory";
import ForgotPassword from "./apps/Login/ForgotPassword";
import Apply from "./apps/Apply";
import Finish from "./apps/Apply/finish";
import ApplyLogin from "./apps/Login/ApplyLogin";
import {Checkbox, Modal} from "antd";
import bg_img from './img/background.png'
import SaleModal from "./components/SaleModal";
import ProHome from "./pro/ProHome";
import ProSubPage from "./pro/Subscription";
import ProLayout from "./pro/ProLayout";
import CuratedCollections from "./pro/CuratedCollections";
import Survey from "./pages/Survey/Survey";
import Page2 from "./pages/Survey/Page2";
import Page3 from "./pages/Survey/Page3";
import Page4 from "./pages/Survey/Page4";
import Page5 from "./pages/Survey/Page5";
import Page6 from "./pages/Survey/Page6";
import Page7 from "./pages/Survey/Page7";
import Page8 from "./pages/Survey/Page8";
import Page9 from "./pages/Survey/Page9";
import Page10 from "./pages/Survey/Page10";
import ImagePage from './pages/Survey/ImagePage';
import ImagePage2 from './pages/Survey/ImagePage2';
import ImagePage3 from './pages/Survey/ImagePage3';
import Attention from './pages/Survey/Attention';
import Attention2 from './pages/Survey/Attention2';
import Attention3 from './pages/Survey/Attention3';
import Submit from "./pages/Survey/Submit";
import TexturaPro from "./pro/TexturaPro";
import Shopping from "./pro/Shopping";
import ProLogin from "./pro/ProLogin";
import ProSignIn from "./pro/ProSignIn";
import ProSignUp from "./pro/ProSignUp";
import ProForgotPassword from "./pro/ProForgotPassword";
import Software from "./pro/Software";
import ProPortalHome from "./portal/ProPortalHome";
import DashBoard from "./portal/DashBoard";
import MyLibrary from "./portal/MyLibrary";
import MyAccountHome from "./portal/MyAccount";
import MyAccount from "./portal/MyAccount/ExtraServices";
import DiscoveryCollections from "./portal/ProDiscovery/DiscoveryCollections";
import AuthorDetails from "./portal/ProDiscovery/AuthorDetails";
import GalleryDetails from "./portal/ProDiscovery/GalleryDetails";
import AlbumDetails from "./portal/ProDiscovery/AlbumDetails";
import DiscoveryArtworkSeeAll from "./portal/ProDiscovery/DiscoveryArtworkSeeAll";
import DiscoveryGalleriesSeeAll from "./portal/ProDiscovery/DiscoveryGalleriesSeeAll";
import DiscoveryArtistSeeAll from "./portal/ProDiscovery/DiscoveryArtistSeeAll"
import ImgDisplay from "./portal/MyLibrary/ImgDisplay";
import MyOrdersPro from "./portal/MyAccount/MyOrders";
import AccountSettings from "./portal/MyAccount/AccountSettings";
import Albums from "./portal/MyLibrary/Playlists";
import ShoppingCart from "./portal/ShoppingCart";
import ChangeAccountDetails from "./portal/MyAccount/AccountSettings/ChangeSettings";
import ChangePasswordPro from "./portal/MyAccount/AccountSettings/ChangePassword";
import ProportalSupport from "./portal/ProSupport";
import ProportalQA from "./portal/ProSupport/QA";
import ProContact from "./portal/ProSupport/ProContact";
import ProTutorial from "./portal/ProSupport/ProTutorial";
import UpgradeSubscription from "./portal/MyAccount/ExtraServices/UpgradeSubscription";
import Hardware from "./portal/DashBoard/components/Hardware";
import PortalOrderDetails from "./portal/MyAccount/MyOrders/OrderDetails";
import RefundsProportal from "./portal/MyAccount/MyOrders/MyRefunds";
import ProNewPassword from "./pro/ProNewPassword";
import ProportalGroup from "./portal/ProportalGroup";
import SingleAlbumDetails from "./portal/MyLibrary/SingleAlbumDetails";
import MyAlbums from "./portal/MyLibrary/MyAlbums";
import UpgradeSummary from "./portal/MyAccount/ExtraServices/UpgradeSummary";
import PaymentAgreementTermsAndConditions from "./pro/Payment/PaymentAgreement";
import ProSignUpVerification from "./pro/ProSignUpVerification";
import ProResetPassword from "./pro/ProResetPassword";
import ProCongratulations from "./pro/ProCongratulations";
import ProPasswordSaved from "./pro/ProPasswordSaved";
import UpgradeSubscriptionPremium from "./portal/MyAccount/ExtraServices/UpgradeSubscriptionPremium";
import ProPrivacyPolicy from "./pro/ProPrivatePolicy";
import ProTermsAndConditions from "./pro/ProTermsAndConditions";
import CheckOut from "./pro/Success";
import Explore from "./pro/Explore";
import Success from "./pro/Success";
import ArtistsTermsAndConditions from "./pro/ArtistsTermsAndConditions";
import ProductDetailsNew from "./pages/Textura/ProductDetailsNew";
import LandingPage from "./LandingPage";
import LandingMobilePage from "./LandingPageMobile";
import AutoplayCarousel from "./AutoplayCarousel/AutoplayCarousel";
import AssignDevices from "./portal/MyAccount/AccountSettings/AssignDevices";
import Biophilia from "./pages/Research/Biophilia";
import Compad from "./pages/Research/compad";
import Research from "./pages/Research";
import FeaturedArtist from "./pages/FeaturedArtist";
import NewArtist from "./pages/NewArtist";
import ProductDetailsNewJack from "./pages/Textura/ProductDetailsNewJack";
import NewContact from "./pages/NewContact";
import HomePageJack from "./pages/Home/HomePageJack";
import Museum from "./pages/Museum";
import {Helmet} from "react-helmet";

// const NAMES = ['paulgittins', 'jonathanarmour', 'keyang',
//     'lucynelson', 'jovincent',
//     'jenettecoldrick', 'barbara',
//     'christianredermayer', 'dorisluming',
//     'linwangwang',
//     'richardbattye', 'guillegiagante',
//     'fazarwibisono', 'caitlinflood',
//     'rorywatson', 'anthonywebb', 'angeliquenagovskaya',
//     'ethanplatt', 'matthewstickland', 'alexanderrhys'
// ];
// const MuseumNames = [
//     'NationalGalleriesScotland', 'EstorickCollection', 'RBSA', 'ArtInstituteofChicago', 'NationalGalleryofArt', 'Rijksmuseum'
// ]

const NAMES = ['jonathan-armour', 'ke-yang',
    'lucy-nelson', 'jo-vincent',
    'jenette-coldrick', 'barbara-safran-de-niverville',
    'christian-redermayer', 'doris-luming',
    'linwang-wang',
    'richard-battye', 'guille-giagante',
    'fazarwibisono', 'caitlin-flood-molyneux',
    'rory-watson', 'anthony-webb', 'angelique-nagovskaya',
    'ethan-platt', 'matthew-stickland', 'alexander-rhys','paul-gittins'
];
const MuseumNames = [
    ['national-galleries-scotland','NationalGalleriesScotland'],
    ['estorick-collection','EstorickCollection'],
    ['rbsa-gallery','RBSA'],
    ['art-institute-of-chicago','ArtInstituteofChicago'],
    ['national-gallery-of-art','NationalGalleryofArt'],
    ['rijksmuseum','Rijksmuseum']
]

const App = () => {
    useEffect(() => {
        // only mobile webView will trigger this function and send the token here
        window.setToken = (tokenFromWebView) => {
            localStorage.setItem("token", tokenFromWebView);
            return tokenFromWebView
        };
        return () => {
            delete window.setToken;
        }
    }, []);

    const isTouchDevice = () => {
        return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
    };

    // const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    const isMobile = useMediaQuery({query: '(max-width: 933px)'});

    const HOME_ROUTES = [
        {
            path: "/",
            component: isMobile ? LandingMobilePage : LandingPage,
            title: "Vieunite | Official Site",
            desc: "Discover Vieunite's premium digital art canvas, combining technology and artistry to showcase curated collections and unique pieces. Ideal for homes, offices, and public spaces, Vieunite seamlessly displays stunning digital art, photos, and videos.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, picture frame, the frame, smart frame, smart digital art frame, digital art, art, photography frame, digital photo frame, textura, VT27WGV1, vieunite, best digital picture frame, smart art display, digital galleries",
        },
        {
            path: "/home",
            component: HomePageJack,
            title: "Vieunite Home",
            desc: "Discover Vieunite Home and access exclusive collections of artistic masterpieces to showcase on the Textura digital canvas. Transform any space by sharing your favourite art or by displaying your own memorable photos and videos.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, Vieunite Home, digital art, personal photography, home display, smart art frame, high definition, Vieunite platform",
        },
        {
            path: "/research/overview",
            component: Research,
            title: "Vieunite Research | Vieunite",
            desc: "Explore groundbreaking research from Vieunite, driving innovation in digital art and technology to enhance the way we experience art in the modern world.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, Vieunite Research, digital art research, art and technology, innovation, art experience, Vieunite research",
        },
        {
            path: "/research/biophilia",
            component: Biophilia,
            title: "Vieunite Research – Biophilia | Vieunite",
            desc: "Discover Vieunite’s research on Biophilia, exploring the connection between nature and digital art to create immersive experiences that promote well-being.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, Biophilia, digital art, nature and art, well-being, immersive experiences, art research, Vieunite research",
        },
        {
            path: "/contact",
            component: NewContact,
            title: "Contact Us | Vieunite",
            desc: "Get in touch with Vieunite for inquiries about the Textura digital canvas, artist partnerships, or customer support. We are here to help.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, Contact Vieunite, customer support, artist partnerships, inquiries",
        },
        {
            path: "/terms-and-conditions",
            component: TermsAndConditions,
            title: "Terms and Conditions | Vieunite",
            desc: "Review the Terms and Conditions for using Vieunite's website and digital art products, ensuring a clear understanding of our services and policies.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, Vieunite terms and conditions, website terms, digital art terms, user agreement, policies ",
        },
        {
            path: "/end-user-agreement",
            component: EndUserAgreement,
            title: "End User Agreement | Vieunite",
            desc: "Read the Vieunite End User Agreement for detailed information on usage rights, software licenses, and responsibilities when using our digital art products.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, End User Agreement, Vieunite, software license,user rights, responsibilities, digital art products ",
        },
        {
            path: "/artists-terms-and-conditions",
            component: ArtistsTermsAndConditions,
            title: "Artists Terms and Conditions | Vieunite",
            desc: "View the Terms and Conditions for artists collaborating with Vieunite, outlining the rights, responsibilities, and agreements for showcasing digital art.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, Artists terms, Vieunite, collaboration agreement, digital art terms, artist responsibilities, digital art platform ",
        },
        {
            path: "/home/digital-canvas",
            component: ProductDetailsNewJack,
            title: "Vieunite Home – Textura Digital Canvas",
            desc: "From displaying art that you love to eternalising special moments with loved ones, the Textura digital canvas allows you to transform any space into your own gallery.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, Textura, Vieunite Home, digital art, modern technology, home decor, smart frame, high-definition art, personal photography ",
        },
        {
            path: "/home/vieunite-app",
            component: HomeVieuniteApp,
            title: "Vieunite Home – App",
            desc: "The Vieunite Home app gives you complete control over your digital canvas, allowing you to curate, customise, and manage your art displays effortlessly.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, Vieunite Home app, digital canvas app, curate digital art, manage art display, personal gallery, smart art frame",
        },
        {
            path: "/home/support",
            component: HelpMain,
            title: "Vieunite Home Support | Vieunite",
            desc: "Access our support centre for assistance with the digital canvas, app, and online store.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, Vieunite Home support, digital canvas support, app support, online store, customer service, Vieunite assistance",
        },
        {
            path: "/home/support/request-support",
            component: HelpRequestSupport,
            title: "Request Support | Vieunite",
            desc: "Submit a request for support with your Vieunite Home digital canvas or app, and our team will assist you in resolving any issues.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, Request support, Vieunite Home, digital canvas support, app support, technical assistance, customer service",
        },
        {
            path: "/home/support/vieunite-app",
            component: HelpVieuniteApp,
            title: "Vieunite App Support | Vieunite",
            desc: "Access support for troubleshooting, app setup, and customisation of your digital art displays.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, Vieunite app support, app troubleshooting, digital canvas app, app setup, app customisation, digital art support",
        },
        {
            path: "/home/support/textura",
            component: HelpTextura,
            title: "Textura Digital Canvas Support | Vieunite",
            desc: "Get help with Textura, Vieunite’s premium digital canvas, for troubleshooting, setup, and maintenance. Our support team is here to assist you.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas, Textura support, Vieunite, digital canvas support, troubleshooting, setup, maintenance, customer service",
        },
        {
            path: "/home/support/online-store",
            component: HelpOnlineStore,
            title: "Online Store Support | Vieunite",
            desc: "Get support for Vieunite’s online store, including assistance with orders, shipping, returns, and product information.",
            keywords: "digital canvas, digital frame, digital art frame, digital art canvas,  Online store support, Vieunite store, order assistance, shipping help, returns, product support, customer service",
        },


    ]

    const BUSINESS_ROUTES = [
        {
            path: "/pro",
            component: ProHome,
            title: "",
            desc: "",
            keywords: "",
        },
    ]

    return (
        <div>
            <BrowserRouter>
                <AutoScrollToTop>
                    <Routes>
                        <Route path='test-caroucel' element={<AutoplayCarousel/>}></Route>
                        <Route path="/" element={<BasicLayout/>}>
                            {/*<Route index={true} element={isMobile ? <LandingMobilePage/> : <LandingPage/>}/>*/}
                            <Route path={"landing"} element={isMobile ? <LandingMobilePage/> : <LandingPage/>}/>
                            {/*<Route path='contact' element={<NewContact/>}/>*/}
                            {/*<Route path="home" element={<HomePageJack/>}/>*/}
                            {HOME_ROUTES.map((route) => {
                                const RouteElement = () => (
                                    <>
                                        <Helmet>
                                            <title>{route.title}</title>
                                            <meta name="description" content={route.desc}/>
                                            <meta name="keywords" content={route.keywords}/>
                                        </Helmet>
                                        {React.createElement(route.component)}
                                    </>
                                )
                                return (
                                    <Route key={route.key} path={route.path} element={<RouteElement/>}/>
                                )
                            })}

                            {/*<Route path="research/overview" element={<Research/>}/>*/}
                            {/*<Route path="research/biophilia" element={<Biophilia/>}/>*/}
                            <Route path="research/compad" element={<Compad/>}/>

                            <Route path="textura" element={<ProductDetailsNewJack/>}/>
                            <Route path="product" element={<ProductDetailsNewJack/>}/>
                            <Route path="digital-canvas" element={<ProductDetailsNewJack/>}/>
                            {/*<Route path="home/digital-canvas" element={<ProductDetailsNewJack/>}/>*/}
                            <Route path={'digital-frame'} element={<ProductDetailsNewJack/>}/>
                            {/*<Route path="art/:page" element={isMobile ? <ArtPageMobile/> : <ArtPage/>}/>*/}
                            <Route path="art" element={isMobile ? <ArtPageMobile/> : <ArtPage/>}/>
                            <Route path="brochure" element={<BrochurePage/>}/>
                            {/*<Route path="home/vieunite-app" element={<HomeVieuniteApp/>}/>*/}
                            {/*<Route path="home/support" element={<HelpMain/>}/>*/}
                            {/*<Route path="home/support/form" element={<HelpRequestSupport/>}/>*/}
                            {/*<Route path="home/support/vieunite-app" element={<HelpVieuniteApp/>}/>*/}
                            {/*<Route path="home/support/textura" element={<HelpTextura/>}/>*/}
                            {/*<Route path="home/support/online-store" element={<HelpOnlineStore/>}/>*/}

                            <Route path='artists' element={<Artists/>}/>
                            {/*<Route path="terms-and-conditions" element={<TermsAndConditions/>}/>*/}
                            {/*<Route path="end-user-agreement" element={<EndUserAgreement/>}/>*/}
                            {/*<Route path={'artists-terms-and-conditions'} element={<ArtistsTermsAndConditions/>}/>*/}
                            <Route path="app.html" element={<AppDownload/>}/>
                            {/*{NAMES.map(*/}
                            {/*    (n) => <Route key={n} path={'artist/' + n} element={<NewArtist name={n}/>}/>*/}
                            {/*)}*/}
                            {/*{MuseumNames.map(*/}
                            {/*    (n) => <Route key={n} path={'museum/' + n} element={<Museum name={n}/>}/>*/}
                            {/*)}*/}
                            {/*{NAMES.map(*/}
                            {/*    (n) => <Route key={n} path={'featuredartist/' + n}*/}
                            {/*                  element={<FeaturedArtist name={n}/>}/>*/}
                            {/*)}*/}
                            {NAMES.map(
                                (n) => <Route key={n} path={'artist/' + n} element={<NewArtist name={n}/>}/>
                            )}
                            {MuseumNames.map(
                                (n) => <Route key={n} path={'galleries/' + n[0]} element={<Museum name={n[1]}/>}/>
                            )}
                            {NAMES.map(
                                (n) => <Route key={n} path={'featuredartists/' + n}
                                              element={<FeaturedArtist name={n}/>}/>
                            )}
                            <Route path="Survey" element={<Survey/>}/>
                            <Route path="Page2" element={<Page2/>}/>
                            <Route path="Page3" element={<Page3/>}/>
                            <Route path="Page4" element={<Page4/>}/>
                            <Route path="Attention" element={<Attention/>}/>
                            <Route path="ImagePage" element={<ImagePage/>}/>
                            <Route path="Page5" element={<Page5/>}/>
                            <Route path="Page6" element={<Page6/>}/>
                            <Route path="Attention2" element={<Attention2/>}/>
                            <Route path="ImagePage2" element={<ImagePage2/>}/>
                            <Route path="Page7" element={<Page7/>}/>
                            <Route path="Page8" element={<Page8/>}/>
                            <Route path="Attention3" element={<Attention3/>}/>
                            <Route path="ImagePage3" element={<ImagePage3/>}/>
                            <Route path="Page9" element={<Page9/>}/>
                            <Route path="Page10" element={<Page10/>}/>
                            <Route path="Submit" element={<Submit/>}/>
                        </Route>
                        <Route path="/artistportal/login" element={<Login/>}/>
                        <Route path="/artistportal/mapply" element={<ApplyLogin/>}/>
                        <Route path="/artistportal/forgotpassword" element={<ForgotPassword/>}/>
                        <Route path='/artistportal/apply' element={<Apply/>}/>
                        <Route path='/artistportal/finish' element={<Finish/>}/>
                        <Route path="/artistportal" element={<PortalLayout/>}>
                            <Route index element={<ArtistPortalHome/>}/>
                            {/*Homepage's url needs to receive params: token, id and isArtist*/}
                            <Route path="home/:userInfo" element={<ArtistPortalHome/>}/>
                            <Route path="home" element={<ArtistPortalHome/>}/>
                            <Route path='artlist' element={<ArtList/>}/>
                            <Route path='profile' element={<ProfileEditor/>}/>
                            <Route path='balance' element={<BalanceBoard/>}/>
                            <Route path='transactionhistory' element={<TransactionHistory/>}/>
                            <Route path='withdrawhistory' element={<WithdrawHistory/>}/>
                        </Route>
                        <Route path="/proportal" element={<ProPortalHome/>}/>
                        <Route path="/proportal/dashboard" element={<DashBoard/>}/>
                        <Route path="/proportal/ProportalGroup" element={<ProportalGroup/>}/>
                        <Route path={"/proportal/mylibrary"} element={<MyLibrary/>}/>
                        <Route path="/proportal/MyAccountHome" element={<MyAccountHome/>}/>
                        <Route path="/proportal/MyAccount" element={<MyAccount/>}/>
                        <Route path="/proportal/UpgradeSubscription" element={<UpgradeSubscription/>}/>
                        <Route path="/proportal/UpgradeSubscriptionPremium" element={<UpgradeSubscriptionPremium/>}/>
                        <Route path="/proportal/UpgradeSummary" element={<UpgradeSummary/>}/>
                        <Route path="/proportal/MyOrder" element={<MyOrdersPro/>}/>
                        <Route path="/proportal/RefundsProportal" element={<RefundsProportal/>}/>
                        <Route path="/proportal/ChangeAccountDetails" element={<ChangeAccountDetails/>}/>
                        <Route path="/proportal/AssignDevices" element={<AssignDevices/>}/>
                        <Route path="/proportal/ChangePasswordPro" element={<ChangePasswordPro/>}/>
                        <Route path="/proportal/AccountSettings" element={<AccountSettings/>}/>
                        <Route path={"/proportal/discover-art"} element={<DiscoveryCollections/>}/>
                        <Route path={"/proportal/discover-art/author-details"} element={<AuthorDetails/>}/>
                        <Route path={"/proportal/discover-art/gallery-details"} element={<GalleryDetails/>}/>
                        <Route path={"/proportal/discover-art/album-details"} element={<AlbumDetails/>}/>
                        <Route path={"/proportal/discover-art/artworks-seeall"}
                               element={<DiscoveryArtworkSeeAll/>}/>
                        <Route path={"/proportal/discover-art/galleries-seeall"}
                               element={<DiscoveryGalleriesSeeAll/>}/>
                        <Route path={"/proportal/discover-art/artists-seeall"} element={<DiscoveryArtistSeeAll/>}/>
                        <Route path={"/proportal/images"} element={<ImgDisplay/>}/>
                        <Route path={"/proportal/albums"} element={<Albums/>}/>
                        <Route path={"proportal/my-albums"} element={<MyAlbums/>}/>
                        <Route path={"/proportal/album-details"} element={<SingleAlbumDetails/>}/>
                        <Route path={"/proportal/shopping-cart"} element={<ShoppingCart/>}/>
                        <Route path={"/proportal/ProportalSupport"} element={<ProportalSupport/>}/>
                        <Route path={"/proportal/ProportalQA"} element={<ProportalQA/>}/>
                        <Route path={"/proportal/ProContact"} element={<ProContact/>}/>
                        <Route path={"/proportal/ProTutorial"} element={<ProTutorial/>}/>
                        <Route path={"/proportal/textura"} element={<Hardware/>}/>
                        <Route path="/proportal/PortalOrderDetails" element={<PortalOrderDetails/>}/>
                        <Route path={'/proportal/ProPaymentAgreement'}
                               element={<PaymentAgreementTermsAndConditions/>}/>

                        <Route path={'/business/explore'} element={<Explore/>}/>
                        <Route path={'/business'} element={<ProLayout/>}>
                            <Route index element={<ProHome/>}/>
                            <Route path={'home'} element={<ProHome/>}/>
                            <Route path={'/business/success'} element={<Success/>}/>
                            <Route path={'ProLogin'} element={<ProLogin/>}/>
                            <Route path={'ProSignIn'} element={<ProSignIn/>}/>
                            <Route path={'ProSignUp'} element={<ProSignUp/>}/>
                            <Route path={'ProSignUpVerification'} element={<ProSignUpVerification/>}/>
                            <Route path={'ProResetPassword'} element={<ProResetPassword/>}/>
                            <Route path={'ProCongratulations'} element={<ProCongratulations/>}/>
                            <Route path={'ProForgotPassword'} element={<ProForgotPassword/>}/>
                            <Route path={'ProPasswordSaved'} element={<ProPasswordSaved/>}/>
                            <Route path={'ProNewPassword'} element={<ProNewPassword/>}/>
                            <Route path={'subscription'} element={<ProSubPage/>}/>
                            <Route path={'curated-collections'} element={<CuratedCollections/>}/>
                            <Route path={'textura-pro'} element={<TexturaPro/>}/>
                            <Route path={'shopping'} element={<Shopping/>}/>
                            <Route path={'software'} element={<Software/>}/>
                            <Route path={'ProPrivacyPolicy'} element={<ProPrivacyPolicy/>}/>
                            <Route path={'ProTermsAndConditions'} element={<ProTermsAndConditions/>}/>
                        </Route>
                    </Routes>
                </AutoScrollToTop>
            </BrowserRouter>
        </div>);
}

export default App;