import React from "react";
import {Helmet} from "react-helmet";

export const A3Meta = () => (
    <Helmet>
            <title>Lucy Nelson | Vieunite</title>
            <meta name="description"
                  content="Lucy Nelson is a visual artist primarily working with sculpture and alternative photography techniques as an avenue to explore the themes of growth, impermanence and fragility. "/>
            <meta name="keywords"
                  content="digital canvas, digital frame, digital art frame,  digital art canvas,picture frame,  frame, the frame, smart frame, smart digital art frame,  digital art, art, photography frame, artworks, gallery, photo frame, digital photo frame, textura, texturite technology, VT27WGV1, vieunite, digital photo to canvas, digital picture frame, best digital picture frame, electronic photo frame, wifi picture frame, video picture frame"/>
            <meta name="twitter:card" value="summary_large_image"/>
            <meta name="twitter:site" value="@VieuniteHQ"/>
            <meta name="twitter:title" value="Lucy Nelson"/>
            <meta name="twitter:description"
                  content="Lucy Nelson is a visual artist primarily working with sculpture and alternative photography techniques as an avenue to explore the themes of growth, impermanence and fragility. "/>
            <meta name="twitter:url" value="https://vieunite.com/lucynelson"/>
            <meta name="twitter:image" value={'https://vieunite.com/img/Social Card - Lucy Nelson.jpg'}/>

            <meta property="og:url" content="https://vieunite.com/lucynelson"/>
            <meta property="og:type" content="article"/>
            <meta property="og:title" content="Lucy Nelson"/>
            <meta property="og:description"
                  content="Lucy Nelson is a visual artist primarily working with sculpture and alternative photography techniques as an avenue to explore the themes of growth, impermanence and fragility. "/>
            <meta property="og:image" content={'https://vieunite.com/img/Social Card - Lucy Nelson.jpg'}/>
            <meta property="og:image:width" content="930"/>
            <meta property="og:image:height" content="620"/>
    </Helmet>);