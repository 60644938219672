import React from "react";
import {Helmet} from "react-helmet";

export const NationalGalleriesScotlandMeta = () => (
    <Helmet>
        <title>National Galleries of Scotland | Vieunite</title>
        <meta name="description"
              content="Experience renowned Scottish and international art from the National Galleries of Scotland on Vieunite’s Textura digital canvas, showcasing timeless masterpieces in high definition."/>
        <meta name="keywords"
              content=" digital canvas, digital frame, digital art frame, digital art canvas, National Galleries of Scotland, Scottish art, international art, curated art, Vieunite gallery"/>
    </Helmet>);
export const RijksmuseumMeta = () => (
    <Helmet>
        <title>Rijksmuseum | Vieunite</title>
        <meta name="description"
              content="Experience the masterpieces of Dutch art from the Rijksmuseum on Vieunite’s Textura digital canvas, capturing the brilliance of the Golden Age and beyond."/>
        <meta name="keywords"
              content=" digital canvas, digital frame, digital art frame, digital art canvas, Rijksmuseum, Dutch art, Golden Age art, curated collections, Vieunite gallery"/>
    </Helmet>);
export const EstorickCollectionMeta = () => (
    <Helmet>
        <title>Estorick Collection | Vieunite</title>
        <meta name="description"
              content="Discover Italian modern art from the Estorick Collection on Vieunite’s Textura digital canvas, featuring dynamic works that highlight Italy’s rich artistic legacy."/>
        <meta name="keywords"
              content=" digital canvas, digital frame, digital art frame, digital art canvas, Estorick Collection, digital canvas, Italian modern art, dynamic art, digital art frame, curated art, Vieunite gallery"/>
    </Helmet>);
export const RBSAMeta = () => (
    <Helmet>
        <title>RBSA Gallery | Vieunite</title>
        <meta name="description"
              content="Explore the Royal Birmingham Society of Artists' collection on Vieunite’s Textura digital canvas, featuring contemporary and diverse works that inspire creativity."/>
        <meta name="keywords"
              content=" digital canvas, digital frame, digital art frame, digital art canvas, RBSA, digital canvas, Royal Birmingham Society of Artists, contemporary art, curated collections, Vieunite gallery"/>
    </Helmet>);
export const ArtInstituteofChicagoMeta = () => (
    <Helmet>
        <title>Art Institute of Chicago | Vieunite</title>
        <meta name="description"
              content="Explore iconic art from the Art Institute of Chicago on Vieunite’s Textura digital canvas, bringing masterpieces from across the globe to your space."/>
        <meta name="keywords"
              content="digital canvas, digital frame, digital art frame, digital art canvas, Art Institute of Chicago, global masterpieces, iconic art, curated collections, Vieunite gallery"/>
    </Helmet>);
export const NationalGalleryofArtMeta = () => (
    <Helmet>
        <title>National Gallery of Art | Vieunite</title>
        <meta name="description"
              content="Discover renowned pieces from the National Gallery of Art on Vieunite’s Textura digital canvas, showcasing a diverse array of artistic masterpieces spanning centuries."/>
        <meta name="keywords" content=" digital canvas
            , digital frame, digital art frame, digital art canvas, National Gallery of Art, renowned masterpieces,
            curated art, Vieunite gallery"/>
    </Helmet>
);