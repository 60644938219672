import {A1Meta} from "../../components/PageMeta/A1Meta";
import {A2Meta} from "../../components/PageMeta/A2Meta";
import {A3Meta} from "../../components/PageMeta/A3Meta";
import {A4Meta} from "../../components/PageMeta/A4Meta";
import {A5Meta} from "../../components/PageMeta/A5Meta";
import {A6Meta} from "../../components/PageMeta/A6Meta";
import {A7Meta} from "../../components/PageMeta/A7Meta";
import {A10Meta} from "../../components/PageMeta/A10Meta";
import {A9Meta} from "../../components/PageMeta/A9Meta";
import {A8Meta} from "../../components/PageMeta/A8Meta";
import {A11Meta} from "../../components/PageMeta/A11Meta";
import {A12Meta} from "../../components/PageMeta/A12Meta";
import {A13Meta} from "../../components/PageMeta/A13Meta";
import {A15Meta} from "../../components/PageMeta/A15Meta";
import {A14Meta} from "../../components/PageMeta/A14Meta";
import {A16Meta} from "../../components/PageMeta/A16Meta";
import {A17Meta} from "../../components/PageMeta/A17Meta";
import {A18Meta} from "../../components/PageMeta/A18Meta";
import {A19Meta} from "../../components/PageMeta/A19Meta";
import {A20Meta} from "../../components/PageMeta/A20Meta";
import {A21Meta} from "../../components/PageMeta/A21Meta";


export const PaulGittins = {
    name: 'Paul Gittins',
    headerDt: require('./Paul Gittins/1. Header Image & Video/Paul Gittins Header Landscape.mp4'),
    headerM: require('./Paul Gittins/1. Header Image & Video/Paul Gittins Header Portrait.mp4'),
    avatar_landscape: require('./Paul Gittins/2. Artist Photo/VN-PG-Square.jpg'),
    avatar_portrait: require('./Paul Gittins/2. Artist Photo/VN-PG-Square.jpg'),
    avatar_square: require('./Paul Gittins/2. Artist Photo/VN-PG-Square.jpg'),
    works: [
        {
            img: require('./Paul Gittins/4. Artwork/watermarked_video1838529767891070978.gif'),
            title: 'Moth',
            date: '2024'
        },
        {
            img: require('./Paul Gittins/4. Artwork/watermarked_video1838532883973730305.gif'),
            title: 'Radiator',
            date: '2024'
        },
        {
            img: require('./Paul Gittins/4. Artwork/watermarked_video1838532096245690369.gif'),
            title: 'Bamboo',
            date: '2024'
        },
    ],
    quote: "Vieunite screens provide an ideal method to display my current work, the image is crisp, revealing fine detail and accurate rendering of colour and tone.",
    biography: [
        "Stourbridge-based artist Paul Gittins playfully blends music, performance, sculpture, and video. His fascination with moving images began with the television, sparking creations that transformed the everyday into mesmerising abstract patterns. These evolved into large-scale installations where shadow dancers and live musicians weave captivating performances. Gittins also explores sound with his 'Oak Apple Orchestra'. The rhythmic pulse of an apple on a clock motor inspires musical motifs, with performances often inviting improvisation in unexpected settings like forests and churches."
    ],
    link_to: 'https://www.modernshadows.com',
    social_card: require('../Social Cards/Vieunite - Social CardsSocial Card - AngeliqueNagovskaya.jpg'),
    meta: A21Meta,
    vertical_work: true,
}

export const AlexanderRhys = {
    name: 'Alexander Rhys',
    headerDt: require('./Alexander Rhys/1. Header Image & Video/Alexander Rhys Landscape Video Header.mp4'),
    headerM: require('./Alexander Rhys/1. Header Image & Video/Alexander Rhys Portrait Video Header.mp4'),
    avatar_landscape: require('./Alexander Rhys/2. Artist Photo/VN-AR-Square.jpg'),
    avatar_portrait: require('./Alexander Rhys/2. Artist Photo/VN-AR-Square.jpg'),
    avatar_square: require('./Alexander Rhys/2. Artist Photo/VN-AR-Square.jpg'),
    works: [
        {
            img: 'https://api.vieunite.com/ng/files/1576922223504118305/aStGluAjxtATtdD.jpg',
            title: 'From Tokyo with Love',
            date: '2023'
        },
        {
            img: 'https://api.vieunite.com/ng/files/1576922223504118305/7bUHvp56F49EkPj.jpg',
            title: 'Mt Fuji Rise',
            date: '2023'
        },
        {
            img: 'https://api.vieunite.com/ng/files/1576922223504118305/gc7a3Q345wDdVFP.jpg',
            title: 'Tokyo Notebook',
            date: '2023'
        },
    ],
    quote: "I create paintings for art collectors worldwide, exploring new cultures along the way. I'm excited to share my art with you on Vieunite.",
    biography: [
        "Alexander Rhys is a master of art, creating vibrant and extraordinary pieces that reveal hidden stories and experiences. His art is an amalgamation of the diverse cultures he has immersed himself in during his travels, drawing inspiration from both natural and man-made objects. Every piece showcases his exceptional skill in colour, pattern, and texture, bringing to life a unique fusion of eastern and western approaches to art and culture."
    ],
    link_to: 'https://www.alexanderrhys.com',
    social_card: require('../Social Cards/Vieunite - Social CardsSocial Card - AngeliqueNagovskaya.jpg'),
    meta: A20Meta,
    vertical_work: false,
}
export const MatthewStickland = {
    name: 'Matthew Stickland',
    headerDt: require('./Matthew Stickland/1. Header Image & Video/Matthew Stickland Landscape.mp4'),
    headerM: require('./Matthew Stickland/1. Header Image & Video/Matthew Stickland Portrait.mp4'),
    avatar_landscape: require('./Matthew Stickland/2. Artist Profile/VN-MS-Landscape.jpg'),
    avatar_portrait: require('./Matthew Stickland/2. Artist Profile/VN-MS-Square.jpg'),
    avatar_square: require('./Matthew Stickland/2. Artist Profile/VN-MS-Square.jpg'),
    works: [
        {
            img: 'https://api.vieunite.com/ng/files/1667124136502223249/CBt2QCxrVPOCM7x.jpg',
            title: 'José Miguel',
            date: '2023'
        },
        {
            img: 'https://api.vieunite.com/ng/files/1667124136502223249/ad5443f0985646d4acdef20fc2483bc7_thumbnail.jpg',
            title: 'The Horse and Coach',
            date: '2022'
        },
        {
            img: 'https://api.vieunite.com/ng/files/1667124136502223249/0d56e2ff05234d188ba03cd4e63687eb_thumbnail.jpg',
            title: 'Self Portrait',
            date: '2022'
        },
    ],
    quote: "My work wrestles with the very human desire to leave behind a story by creating a world of images with intricacies that echo the complexities of human experience, hopefully, with a universality which can resonate with people while also remaining personal to the complexities of my human experience.",
    biography: [
        "Matthew Stickland was born in Dublin, but now he lives in London. His paintings are a fusion of his Irish and Trinidadian roots and feature wry figurative scenes. His art is focused on dynamic mark-making and bold colour, and he creates familiar yet flamboyant scenes that cut through the noise of daily life. Though his images appear simplified, closer inspection reveals intricacies that echo the complexities of human experience. By removing visual noise, Stickland's work encourages us to appreciate the flair and nuance in the world around us. His paintings are an ode to human expression, creativity, and the stories we share."
    ],
    link_to: 'https://www.instagram.com/matthew_stickland/?hl=en-gb',
    social_card: require('../Social Cards/Vieunite - Social CardsSocial Card - AngeliqueNagovskaya.jpg'),
    meta: A19Meta,
    vertical_work: false,
}
export const EthanPlatt = {
    name: 'Ethan Platt',
    headerDt: require('./Ethan Platt/1. Header Image & Video/Ethan Platt Landscape.mp4'),
    headerM: require('./Ethan Platt/1. Header Image & Video/Ethan Platt Portrait.mp4'),
    avatar_landscape: require('./Ethan Platt/2. Artist Photo/VN-EP-Landscape.jpg'),
    avatar_portrait: require('./Ethan Platt/2. Artist Photo/VN-EP-Portrait.jpg'),
    avatar_square: require('./Ethan Platt/2. Artist Photo/VN-EP-Square.jpg'),
    works: [
        {
            img: 'https://api.vieunite.com/ng/files/1667124133339493249/J93pLJ0xlnVTC4X.jpg',
            title: 'Putti Chasing Goose',
            date: '2022'
        },
        {
            img: 'https://api.vieunite.com/ng/files/1667124133339493249/65c539e034dc4ca8ae948e9f6c8acace_thumbnail.jpg',
            title: 'Render',
            date: '2021'
        },
        {
            img: 'https://api.vieunite.com/ng/files/1667124133339493249/aAhRSMWjcDSXmsY.jpg',
            title: 'Shine',
            date: '2023'
        },
    ],
    quote: "Vieunite offers a massive opportunity to connect with audiences in an entirely new manner. I think it’s a conceptual innovation to display tangible art through technology. My own practice relates to this framework as I begin each painting’s reference material in the digital and then effectively export it into the world through oil painting. To re-upload this work onto Vieunite furthers the tone of my work by offering a truly innovative and contemporary way of experiencing art. I hope art lovers will appreciate this dynamic way of engaging with art in the same way I have been.",
    biography: [
        'Ethan Platt’s work reimagines the human experience through the cyber gaze. By referencing digitally rendered stills in his oil painting practice, he challenges the boundaries of physical and digital existence, highlighting the synergy between humans and machines. Beyond aesthetics, his paintings engage with concepts of digital surroundings and illuminate the human condition in the post-internet era.'
    ],
    link_to: 'https://www.ethanplatt.ca',
    social_card: require('../Social Cards/Vieunite - Social CardsSocial Card - AngeliqueNagovskaya.jpg'),
    meta: A18Meta,
    vertical_work: false,
}
export const AngeliqueNagovskaya = {
    name: 'Angelique Nagovskaya',
    headerDt: require('./Angelique Nagovskaya/1. Header Image & Video/Angelique Video 1920 x 1080.mp4'),
    headerM: require('./Angelique Nagovskaya/1. Header Image & Video/Angelique Video 1080 x 1920.mp4'),
    avatar_landscape: require('./Angelique Nagovskaya/2. Artist Photo/VN-AN-Square.jpg'),
    avatar_portrait: require('./Angelique Nagovskaya/2. Artist Photo/VN-AN-Square.jpg'),
    avatar_square: require('./Angelique Nagovskaya/2. Artist Photo/VN-AN-Square.jpg'),
    works: [
        {
            img: 'https://api.vieunite.com/ng/files/1667124136509411149/1a7fac4c600f42608877a4172c50042b_thumbnail.jpg',
            title: 'As Warm as Detachment',
            date: '2023'
        },
        {
            img: 'https://api.vieunite.com/ng/files/1667124136509411149/f08557418c414be5ae15a6dc3f834545_thumbnail.jpg',
            title: 'Spanish Summer',
            date: '2023'
        },
        {
            img: 'https://api.vieunite.com/ng/files/1667124136509411149/16617212cc6f4327a88fbc6741a84f26_thumbnail.jpg',
            title: 'Woman in a Cave',
            date: '2023'
        },
    ],
    quote: 'My artistic practice aims to create immersive spaces that evoke a sense of wonder and contemplation. I hope viewers will take a moment to slow down, immerse themselves in the details, and reflect on their presence within these environments.',
    biography: [
        'Angélique Nagovskaya (b. 1998 Montreal, CA) is a Canadian-American artist. She completed her BFA in Fine Art and a BA in Art History at Cornell University (2022) before an MA in Painting at the Royal College of Art in London (2023). Her paintings aim to invoke universal human experiences and emotions. Depicting figures without clear identity, her works become representations of shared humanity.'
    ],
    link_to: 'https://www.angeliquenagovskaya.com',
    social_card: require('../Social Cards/Vieunite - Social CardsSocial Card - AngeliqueNagovskaya.jpg'),
    meta: A17Meta,
    vertical_work: false,
}
export const AnthonyWebb = {
    name: 'Anthony Webb',
    headerDt: require('./Anthony Webb/1. Header Image or Video/Ant Webb_Desktop Header.mp4'),
    headerM: require('./Anthony Webb/1. Header Image or Video/Ant Webb_Mobile Header.mp4'),
    avatar_landscape: require('./Anthony Webb/2. Artist Photo/VN-AW-Landscape.jpg'),
    avatar_portrait: require('./Anthony Webb/2. Artist Photo/VN-AW-Square.jpg'),
    avatar_square: require('./Anthony Webb/2. Artist Photo/VN-AW-Square.jpg'),
    works: [
        {
            img: 'https://api.vieunite.com/ng/files/1667124136509124249/jwwK8YT6kSzWStt.jpg',
            title: 'Untitled(Bookshop)',
            date: '2004'
        },
        {
            img: 'https://api.vieunite.com/ng/files/1667124136509124249/dcg54RenFQ4kbjG.jpg',
            title: 'Untitled(Red Door)',
            date: '2015'
        },
        {
            img: 'https://api.vieunite.com/ng/files/1667124136509124249/f1511d18d4254f91a8b8fcde7aa79aa4_thumbnail.jpg',
            title: 'Untitled(Guitar)',
            date: '2004'
        },
    ],
    quote: '"My aim as a designer was to record fashion expression. My aim as a photographer was to capture a mood. My aim as an artist was to create a feeling."',
    biography: [
        'Ant (Anthony Webb) was a passionate photographer and graphic designer with a rich artistic journey that began in his early teens. He ventured from Hereford to London, where he honed his skills and pioneered courses in Travel and London Street Photography at the leading Central St. Martin’s College of Art and Design.',
        'His creative ventures include founding ‘THEM’, a successful design consultancy, and collaborating with esteemed clients including The Guardian, Thames and Hudson, Transport for London, and Condé Naste Travel. Tragically, Ant’s life ended in 2019 before he could realise his dream of exhibiting his work more widely. However, Vieunite is proud to work with his family showcasing a glimpse of his remarkable work, including his captivating portrayal of East End fashion and culture in the iconic ‘East End Fashionistas’ book, and a selection of his travel photography.'
    ],
    link_to: 'https://anthony-webb.com',
    social_card: require('../Social Cards/Social Card - Ant Webb.jpg'),
    meta: A16Meta,
    vertical_work: false,
}
export const RoryWatson = {
    name: 'Rory Watson',
    headerDt: require('./Rory Watson/1. Header Image or Video/Rory Desktop Header.mp4'),
    headerM: require('./Rory Watson/1. Header Image or Video/Rory Mobile Header.mp4'),
    avatar_landscape: require('./Rory Watson/2. Artist Photo/VN-RW-Square.jpg'),
    avatar_portrait: require('./Rory Watson/2. Artist Photo/VN-RW-Square.jpg'),
    avatar_square: require('./Rory Watson/2. Artist Photo/VN-RW-Square.jpg'),
    works: [
        {
            img: 'https://api.vieunite.com/ng/files/1667121136509493249/79c8a035effa4a9ca1770f65afad5f03_thumbnail.jpg',
            title: 'Intro',
            date: ''
        },
        {
            img: 'https://api.vieunite.com/ng/files/1667121136509493249/e4061c70fa2c401da46f8a63092ca813_thumbnail.jpg',
            title: 'Untitled',
            date: ''
        },
        {
            img: 'https://api.vieunite.com/ng/files/1667121136509493249/7d2308f2f64d46e3b3929024277bd120_thumbnail.jpg',
            title: 'The Stare',
            date: ''
        },
    ],
    quote: '"Since achieving his BA Fine Art Degree from Central Saint Martins in 2019, Rory has completed residencies with the Muse Gallery and Art Inspirations, and had his first London solo exhibition in May 2022. His artworks have featured in multiple group shows, and are part of private collections internationally. Rory currently works from his studio in London."',
    biography: [
        'Rory Watson has developed a visual language entwining reality, form, colour and abstraction. Through attempting to capture emotion, his portraits are deconstructed through a physical approach, yet retain a sense of expression. Remnants of facial features are left intact as intense and visceral medium is scraped and smudged across the canvas. Traditional methods of painting juxtapose more contemporary techniques, whilst colours blend and battle behind colourblind eyes.'
    ],
    link_to: 'https://www.rorywatson.art',
    social_card: require('../Social Cards/Vieunite - Social CardsSocial Card - Rory Watson.jpg'),
    meta: A15Meta,
    vertical_work: true,
}
export const CaitlinFlood = {
    name: 'Caitlin Flood-Molyneux',
    headerDt: require('./Caitlin Flood-Molyneux/1. Header Image or Video/Caitlin Desktop Header.mp4'),
    headerM: require('./Caitlin Flood-Molyneux/1. Header Image or Video/Caitlin Mobile Header.mp4'),
    avatar_landscape: require('./Caitlin Flood-Molyneux/2. Artist Photo/VN-CFM-Landscape.jpg'),
    avatar_portrait: require('./Caitlin Flood-Molyneux/2. Artist Photo/VN-CFM-Portrait.jpg'),
    avatar_square: require('./Caitlin Flood-Molyneux/2. Artist Photo/VN-CFM-Square.jpg'),
    works: [
        {
            img: 'https://api.vieunite.com/ng/files/1667123236509124249/3409d39735824684b0ffcbcf1914562e_thumbnail.jpg',
            title: 'Call Up',
            date: ''
        },
        {
            img: 'https://api.vieunite.com/ng/files/1667123236509124249/861c92e0531f4d4f833b3b81eef46543_thumbnail.jpg',
            title: 'Gratitude',
            date: ''
        },
        {
            img: 'https://api.vieunite.com/ng/files/1667123236509124249/5c31730d1c594df2bcdb2d147422dfc7_thumbnail.jpg',
            title: 'Still Life',
            date: ''
        },
    ],
    quote: '"The process of production often begins with collage, taking physical cut outs of imagery that provokes emotion and memory, before recontextualizing them to relate to the artist’s experience. These composed scenes are painted upon with a range of media from oil paint to aerosol. The destruction of the images with this new media is the moment of creation, as these painted gestures allow the artist to express the emotions unearthed by the scene and figures. From love and loss to grief and anger, the reflective experience of creation allows Flood-Molyneux to channel these emotions into their work in a deeply expressive fashion. These works allow both viewer and artist to muse upon the past as one would when reminiscing to the sound of a nostalgic song, an initially faint memory becomes a vivid recollection. The artwork should be perceived not just as a visual entity, but also as an opportunity for reflection."',
    biography: ['Caitlin Flood-Molyneux is an award-winning contemporary Welsh Artist. Their artistic practice investigates the relationship between pop culture imagery and the way in which we attach emotion and memory to images and use this to narrate their subjective experiences of hardship. The work is both deeply personal and universal, as it charts key moments of their life; a private and enigmatic visual story with which Flood-Molyneux invites the viewer to forge their own connection. Caitlin has exhibited across the UK, Hong Kong and New York. Recently Flood-Molyneux exhibited along side Madonna x Steven Meisel at Christie’s Auction House in London for Christie’s Late Pride Event.'],
    link_to: 'https://www.floodmolyneuxart.com',
    social_card: require('../Social Cards/Vieunite - Social CardsSocial Card - Caitlin Flood-Molyneux.jpg'),
    meta: A14Meta,
    vertical_work: false,
}


export const GuilleGiagante = {
    name: 'Guille Giagante',
    headerDt: require('./Guillermo Giagante/1. Header Image or Video/Guille Desktop.mp4'),
    headerM: require('./Guillermo Giagante/1. Header Image or Video/Guille Mobile.mp4'),
    avatar_landscape: require('./Guillermo Giagante/2. Artist Photo/VN-GG-Landscape.jpg'),
    avatar_portrait: require('./Guillermo Giagante/2. Artist Photo/VN-GG-Portrait.jpg'),
    avatar_square: require('./Guillermo Giagante/2. Artist Photo/VN-GG-Square.jpg'),
    works: [
        {
            img: 'https://api.vieunite.com/ng/files/1602678612478051312/2LxW3apWKdfJO9o.jpg',
            title: 'Antarctica, the desert of infinite blues',
            date: ''
        },
        {
            img: 'https://api.vieunite.com/ng/files/1602678612478051312/rI9dEtOrQJlxyam.jpg',
            title: 'Pehuen-Neuquen',
            date: ''
        },
        {
            img: 'https://api.vieunite.com/ng/files/1602678612478051312/vV97lMA49QLAA31.jpg',
            title: 'White Dunes - Catamarca',
            date: ''
        },
    ],
    quote: '“With Vieunite the potential for expanding and spreading art to new horizons becomes boundless, as it empowers artists to share their creations with an unimaginable audience, while art lovers can have a gallery-like experience at home or office. Sharing and enjoying art has never been so easy, flexible and powerful. Welcome to the future.”',
    biography: ['Guillermo Giagante: Visionary Photographer',
        'Meet Guillermo Giagante, an Argentinian globetrotter and accomplished professional photographer, with an unquenchable passion for capturing life\'s essence through his lens, Giagante\'s journey spans continents, from the captivating landscapes of Antarctica to the vibrant streets of New York or Buenos Aires.',
        'Initially drawn to natural landscapes, Giagante\'s work transcends documentation, aiming to convey the emotional essence of each locale or situation. This dedication led him to explore street photography, abstraction, and intimate landscapes, further expanding his artistic repertoire.',
        'Giagante\'s photographic journey isn\'t solitary; he thrives on collaboration. He shares his creations through exhibitions, publications, and immersive experiences in partnership with fellow artists. An advocate for sharing knowledge, he dedicates time to online photography education, nurturing a global community of like-minded photographers.',
        'As a TEDx speaker, Giagante\'s influence reaches far beyond his images. He is an ambassador for acclaimed brands like Vieunite, Canon Creators, f-stop and OpTech USA, a testament to his impactful presence in the photography realm. In every click, Guillermo Giagante invites us to perceive the world\'s intricacies through his visionary perspective.',
    ],
    link_to: 'https://guillegiagante.com',
    social_card: require('../Social Cards/Social Card - Guillermo Giagante.jpg'),
    meta: A11Meta,
    vertical_work: false,
}
export const FazarWibisono = {
    name: 'Fazar Wibisono',
    headerDt: require('./Fazar Wibisono/1. Header Image or Video/Fazar Wibisono Header Desktop.mp4'),
    headerM: require('./Fazar Wibisono/1. Header Image or Video/Fazar Wibisono Header Mobile.mp4'),
    avatar_landscape: require('./Fazar Wibisono/2. Artist Photo/VN-FW-Landscape.jpg'),
    avatar_portrait: require('./Fazar Wibisono/2. Artist Photo/VN-FW-Portrait.jpg'),
    avatar_square: require('./Fazar Wibisono/2. Artist Photo/VN-FW-Square.jpg'),
    works: [
        {
            img: require('./Fazar Wibisono/4. Artwork/Crystal Drop 1-Digital.Art.2022.jpg'),
            title: 'Crystal Drop 1 Digital Art',
            date: '2022'
        },
        {
            img: require('./Fazar Wibisono/4. Artwork/Crystal Drop 2-Digital.Art-2022.jpg'),
            title: 'Crystal Drop 2 Digital Art ',
            date: '2022'
        },
        {
            img: require('./Fazar Wibisono/4. Artwork/Crystal Drop 3-Digital.Art-2022.jpg'),
            title: 'Crystal Drop 3 Digital Art ',
            date: '2022'
        },
    ],
    quote: '“Regarding the Vieunite website, in my opinion, it is an innovation in the fine arts market, which I think is good because it is presented in the form of a digital frame.”',
    biography: ['Fazar\'s creative journey began at the High School of Fine Arts, where he nurtured his passion for fine arts. He continued his artistic pursuits at the College of Fine Arts and Design, where he delved deeper into the world of art, experimenting with various materials including paper, canvas, watercolors, oil paints, and acrylics.',
        'As his artistic horizons expanded, Fazar ventured beyond traditional mediums, repurposing used materials like skateboard decks and milk cartons, embracing the digital realm, and even crafting captivating wall murals.',
        'Fazar\'s unique approach to his work involves the use of themes or series, providing a structured foundation for his artistic endeavors. This allows him to master various technical styles, including surrealism, cartoons, lowbrow art, symbolism, and semi-realistic techniques, ensuring each piece he creates receives the meticulous attention it deserves.',
    ],
    link_to: 'https://fazarwibisono.simdif.com',
    social_card: require('../Social Cards/Social Card - Fazar Wibisono.jpg'),
    meta: A12Meta,
    vertical_work: true,
}
export const RichardBattye = {
    name: 'Richard Battye',
    headerDt: require('./Richard Battye/1. Header Image or Video/Richard Battye_Desktop.mp4'),
    headerM: require('./Richard Battye/1. Header Image or Video/Richard Battye_Mobile.mp4'),
    avatar_landscape: require('./Richard Battye/2. Artist Photo/VN-RB-Landscape.jpg'),
    avatar_portrait: require('./Richard Battye/2. Artist Photo/VN-RB-Portrait.jpg'),
    avatar_square: require('./Richard Battye/2. Artist Photo/VN-RB-Square.jpg'),
    works: [
        {
            img: 'https://api.vieunite.com/ng/files/1691166721389432833/dwl8zJYr3W43sqV.jpg', title: 'Primal', date: ''
        },
        {
            img: 'https://api.vieunite.com/ng/files/1691166721389432833/q3vFEurELAkYyPq.jpg',
            title: 'Distortion',
            date: ''
        },
        {
            img: 'https://api.vieunite.com/ng/files/1691166721389432833/r6Ksim9wPOU6HDE.jpg',
            title: 'Mouse',
            date: ''
        },
    ],
    quote: 'I had been searching for a new way to display my recent work having toured 2 major exhibitions in the past. Upon seeing Vieunite… all my problems were solved. A fantastic and unique way to present art without all the expense of printing and framing. A game changer if ever I saw one!',
    biography: ["Richard became a freelancer in 1990 and since then has built his career as a professional photographer, mainly in the fields of advertising and editorial. He has worked in 7 countries for a diverse range of clients and has in this time toured 2 major exhibitions of personal work. Richard’s first exhibition; This England, part sponsored by Kodak, toured for over 3 years to 14 UK galleries. It led to a trip to Los Angeles, and on his return, he became a visiting lecturer for Kodak. The second, Still Dancing, was exhibited widely and led to several years' work with the Royal Ballet.",
        "The lecturing work with Kodak was interesting, as he was able to talk about his professional client- led career to students, and also about his personal work and how the two went hand in hand. He has spent half of his career shooting film and the rest digital, so can appreciate the benefits of both. In one of his current pieces of work; “Project” he made a short film. On seeing this, the Royal Ballet commissioned him to shoot for them in that style. He always advocates the importance of personal projects, as the research involved looking into the history of particular genres and the study of various other photographers' work is crucial. ",
        "Over the last 20 years he has lectured on a part time/freelance basis and has given many talks, without compensation on his subject and career. He enjoys passing on the benefit of his experience to those starting out, and seeing what is being taught in Higher Education. He has also taken on many students for work experience. The latter is crucial. During his degree, the connections he made on his work experience placement led to him being offered a full-time job upon graduating. Forging those links with industry and gaining real world experience cannot be spoken highly enough of. ",
        "Probably the most important thing is…he still loves photography."],
    link_to: 'https://www.riverstudio.co.uk/',
    social_card: require('../Social Cards/Social Card - Richard Battye.jpg'),
    meta: A13Meta,
    vertical_work: false,
}
export const ChristianRedermayer = {
    name: 'Christian Redermayer',
    headerDt: require('./christian/1 - Header Image or Video/Christian Redermayer - Art Video - Desktop.mp4'),
    headerM: require('./christian/1 - Header Image or Video/Christian Redermayer - Art Video - Mobile.mp4'),
    avatar_landscape: require('./christian/2 - Artist Photo/VN-CR-Landscape.jpg'),
    avatar_portrait: require('./christian/2 - Artist Photo/VN-CR-Portrait.jpg'),
    avatar_square: require('./christian/2 - Artist Photo/VN-CR-Square.jpg'),
    works: [
        {img: 'https://api.vieunite.com/ng/files/1668538267161030658/5yjoJS7KUkfihwF.jpg',
            title: 'Vantropolis', date: ''},
        {
            img: 'https://api.vieunite.com/ng/files/1668538267161030658/5yjoJS7KUkfihwF.jpg',
            title: 'Harwood Building',
            date: ''
        },
        {
            img: 'https://api.vieunite.com/ng/files/1668538267161030658/qQ3cQeA1VwbVJp1.jpg',
            title: 'The Ivory Tower',
            date: ''
        },
    ],
    quote: '“I strongly believe that artists need more online exposure for their work. Vieunite gives my work the opportunity to reach a wider audience and will help me find the target market and happy collectors for my unique art photography.”',
    biography: ["Christian Redermayer is an architectural and fine art photographer based in Vancouver, Canada. Over the last few years his work has been recognized by some of the World prestigious Photography Awards Organisation's and earned multiple International Awards. He was also nominated as one of the Finalist for the prestigious Photographer of the Year 2020 in British Columbia, Canada, and won the 2021 IIC International Commercial Photographer of the Year title. ",
        "His memberships as an accredited member of the Professional Photographers of Canada and as a member of the Master Photographer International Organization have helped him develop his professional craft. This gives him a lot of inspiration and pushes him forward to discover new techniques and extending his skills.",
        "He sees fine art photography as a manifestation of the artist’s emotion in front of the world, as well as a manifestation of life experiences materialized into artistic vision and eventually into photography. This leads him to consider his photography as an \"autobiographical photography\" intertwined with his life and fed by the emotions that his interaction with the world triggers."],
    link_to: 'https://www.christianredermayer.photo/',
    social_card: require('../Social Cards/Social Card - Christian Redermayer.jpg'),
    meta: A7Meta,
    vertical_work: true,
}
export const DorisLuming = {
    name: 'Doris Luming',
    headerDt: require('./Doris Luming/1 - Header Image or Video/Doris Luming - Art Video - Desktop.mp4'),
    headerM: require('./Doris Luming/1 - Header Image or Video/Doris Luming - Art Video - Mobile.mp4'),
    avatar_landscape: require('./Doris Luming/2 - Artist Photo/VN-DL-Landscape.jpg'),
    avatar_portrait: require('./Doris Luming/2 - Artist Photo/VN-DL-Portrait.jpg'),
    avatar_square: require('./Doris Luming/2 - Artist Photo/VN-DL-Square.jpg'),
    works: [
        {img: 'https://api.vieunite.com/ng/files/1576947573504098305/Q5TsLwLicEjcXMN.jpg', title: 'The Needles', date: '2021'},
        {
            img: 'https://api.vieunite.com/ng/files/1576947573504098305/xjH6FCeG3rtfuyx.jpg',
            title: 'The last piece of ice in Iceland',
            date: '2019'
        },
        {
            img: 'https://api.vieunite.com/ng/files/1576947573504098305/833Q97m0Ei8Ovrs.jpg',
            title: 'Wandering in the Sahara',
            date: '2019'
        },
    ],
    quote: 'By using Vieunite, I can present and share my diverse collection with a significantly broader audience and bringing people together in appreciation of the creativity and artistry of humanity.',
    biography: ['Dorisluming is a filmmaker, photographer and visual artist based in London.',
        'She usually see her works as the expression of emotion and the recreation of scenes in dreams.',
        'With the use of different shooting techniques and performative elements, such as unique perspectives, effects of light and shadow, and performance art, the intangible feelings and dreams from her inner self could be extended and intertwined into the physical reality then being captured.',
        'As a way of expression, she’s always passionate and eager to create, just like fresh air is needed for her to live.'
    ],
    link_to: 'http://www.dorisluming.com',
    social_card: require('../Social Cards/Social Card - Doris Luming.jpg'),
    meta: A8Meta,
    vertical_work: false,
}
export const LinWangWang = {
    name: 'Linwang Wang',
    headerDt: require('./Linwang Wang/1 - Header Image or Video/Linwang Wang - Art Video - Desktop.mp4'),
    headerM: require('./Linwang Wang/1 - Header Image or Video/Linwang Wang - Art Video - Mobile.mp4'),
    avatar_landscape: require('./Linwang Wang/2 - Artist Photo/VN-LW-Landscape.jpg'),
    avatar_portrait: require('./Linwang Wang/2 - Artist Photo/VN-LW-Portrait.jpg'),
    avatar_square: require('./Linwang Wang/2 - Artist Photo/VN-LW-Square.jpg'),
    works: [
        {
            img: 'https://api.vieunite.com/ng/files/1576953608042201089/CvFfX8BjIwRbHaJ.jpg',
            title: 'The Ritual for Urban Living-01',
            date: '2022'
        },
        {
            img: 'https://api.vieunite.com/ng/files/1576953608042201089/tEeJaBBtB9N2BG8.jpg',
            title: 'The Ritual for Urban Living-02',
            date: '2022'
        },
        {
            img: 'https://api.vieunite.com/ng/files/1576953608042201089/4uHHjkQmi5ymQ4G.jpg',
            title: 'The Ritual for Urban Living-03',
            date: '2022'
        },
    ],
    quote: 'Through the Textura, I\'ve been able to present my perspective with creative liberation. It\'s been so rewarding to see my work resonate with people and receive valuable feedback and support from the Vieunite community.',
    biography: ['Shanghai based photographer, Linwang Wang, has been working in fashion industry for over four years. Inspired by his partner, a screenwriter who was born and raised in Shanghai, Linwang recently turns his interests towards documenting the working class residences of Shanghai through photography.',
        'Born in a small industrial town in Shanxi Province in the year of 1996, Linwang found that the workers\' community in Shanghai resonated with his childhood memories. Linwang thinks these commonalities might apply to many fast-growing East Asian cities, where there are always some people and things that are left far behind by the times, and therefore he believes they deserve to be documented in photographs.',
    ],
    link_to: 'https://www.instagram.com/wanglinwangphotography/',
    social_card: require('../Social Cards/Social Card - Linwang Wang.jpg'),
    meta: A9Meta,
    vertical_work: false,
}

export const DavidBickley = {
    name: 'David Bickley',
    headerDt: require('./David Bickley/1 - Header Image or Video/David Bickley - Art Video - Desktop.mp4'),
    headerM: require('./David Bickley/1 - Header Image or Video/David Bickley - Art Video - Mobile.mp4'),
    avatar_landscape: require('./David Bickley/2 - Artist Photo/VN-DB-Landscape.jpg'),
    avatar_portrait: require('./David Bickley/2 - Artist Photo/VN-DB-Portrait.jpg'),
    avatar_square: require('./David Bickley/2 - Artist Photo/VN-DB-Square.jpg'),
    works: [
        {img: require('./David Bickley/4 - Artwork/Cultivate-gif.gif'), title: 'Cultivate', date: '2021'},
        {
            img: require('./David Bickley/4 - Artwork/Falling-Blue-gif.gif'),
            title: 'Falling Blue (David Ian Bickley and Catherine Orr)',
            date: '2021'
        },
        {img: require('./WX20230224-172332.png'), title: '', date: ''},
    ],
    quote: 'As we progress into an ever more digital world it is essential that we develop new tools to allow both digital and analogue artists to interface with this space and disseminate their work effortlessly on a global scale —Vieunite is one of these essential tools.',
    biography: ['David Ian Bickley is an award-winning media artist whose body of work spans the primitive technological of the 1970’s to the digital cutting edge of today. His media work has manifested as television, installation, electronic music and video art.',
        'His work has been shown in many major museums, galleries and festivals around the world; including the CICA Museum, Korea; Science Gallery, Dublin; A & I GALLERY, LOS ANGELES; HAUN TIE ART MUSEUM, BEIJING; The Electric Picnic and the Glucksman, Cork.',
        'LUX London distributes his video art along with [S]edition online. In the early 1980’s Bickley’s video work won 1st prize at the Sony UK Festival and after a move to Ireland in the early 90’s he was fundamental in the evolution of Irish electronic music, earning a Hot press award in the process.  Besides producing video art Bickley has also made important arts documentaries including The Man Who Shot Beckett and the Celtic Songlines. His themes reside in mythic and folklore motif and often use landscape as a form to reflect and process these ideas.',
    ],
    link_to: 'http://www.davidianbickley.com/',
    social_card: require('../Social Cards/Social Card - David Bickley.jpg'),
    meta: A10Meta,
    vertical_work: false,
}
export const Barbara = {
    name: 'Barbara Safran de Niverville',
    headerDt: require('./BarbaraSafrandeNiverville/Barbara Safran de Niverville - Art Video - Desktop.mp4'),
    headerM: require('./BarbaraSafrandeNiverville/Barbara Safran de Niverville - Art Video - Mobile.mp4'),
    avatar_landscape: require('./BarbaraSafrandeNiverville/VN-BSDN-Portrait.jpg'),
    avatar_portrait: require('./BarbaraSafrandeNiverville/VN-BSDN-Portrait.jpg'),
    avatar_square: require('./BarbaraSafrandeNiverville/VN-BSDN-Portrait.jpg'),
    works: [
        {img: 'https://api.vieunite.com/ng/files/1668539867736797186/dCfBgswX7TrT0Hf.jpg', title: 'Autumn Equinox', date: '2021'},
        {img: 'https://api.vieunite.com/ng/files/1668539867736797186/HdiWNGchFQahEgN.jpg', title: 'Dawn or Dusk', date: '2021'},
        {img: 'https://api.vieunite.com/ng/files/1668539867736797186/EbEPdKtH3j87DDz.jpg', title: 'No Mow May', date: '2021'},
    ],
    quote: "Vieunite’s innovative platform connects art lovers world-wide with the benefits of art and nature!  The Textura digital canvas is an exciting new way to exhibit artwork at home, in the office and in public spaces!",
    biography: ['Barbara Safran de Niverville’s recent paintings celebrate movement and freedom in the natural world with loosely applied dry pastel and transparent acrylic on wood. Humanity’s relationship with the natural environment, experimentation and textual research influence each project. ',
        'Awarded numerous grants and honours over the years, artist residencies in Reykjavik, Iceland (2015), Winnipeg-St. Boniface, Manitoba (2016) and Terra Nova National Park, Newfoundland (2018) have prompted Barbara to create eclectic works in response to different landscapes. ',
        'A Canadian artist, she received her Bachelor of Fine Arts degree Cum Laude (1977) from Mount Allison University, Sackville, New Brunswick, Canada. Barbara also holds a Master of Fine Arts degree (2014) from the Lesley University College of Art and Design (formerly the Art Institute of Boston, Cambridge, Massachusetts, USA). Honors include the MFA Alumni Award and the Sheila Hugh Mackay Master of Fine Arts Scholarship. ',
        'Nominated for the Prix Éloizes (2018) and the Strathbutler Award (2023) in visual arts, Barbara has shown her work professionally since the mid -1970’s in 29 solo exhibitions and 34 group shows in Atlantic Canada, Ontario, Manitoba, Iceland and the United States. Barbara participates on peer juries and collaborates with artists on art writing and other projects. She has worked with animator/director Peter de Niverville (husband) and composer Abigail de Niverville (daughter) on the short experimental film “Vanitas Vivace” that had its world premier at the FLIGHT / Mostra Internazionale del Cinema di Genova Film Festival (Genoa, Italy 2021). Based on Barbara’s painting “Vanitas Chicory,” the film presents a whimsical view of wildflowers left to their own devices in a vacant lot. '],
    link_to: 'https://barbsafranart.imprimo.ca/',
    social_card: require('../Social Cards/Social Card - Barbara Safran de Niverville.jpg'),
    meta: A6Meta,
    vertical_work: false,
}

export const JonathanArmour = {
    name: 'Jonathan Armour',
    headerDt: require('./jonathanarmour/1 - Header Image or Video/Jonathan Armour - Desktop.mp4'),
    headerM: require('./jonathanarmour/1 - Header Image or Video/Jonathan Armour - Mobile.mp4'),
    avatar_landscape: require('./jonathanarmour/2 - Artist Photo/VN-JA-Landscape.jpg'),
    avatar_portrait: require('./jonathanarmour/2 - Artist Photo/VN-JA-Portrait.jpg'),
    avatar_square: require('./jonathanarmour/2 - Artist Photo/VN-JA-Square.jpg'),
    works: [
        {img: 'https://api.vieunite.com/ng/files/1576956527395225601/SplSp5ne9C7xXF2.jpg', title: 'Specimen 09', date: '2022'},
        {img: 'https://api.vieunite.com/ng/files/1576956527395225601/V0DCMVZsiqXvWpl.jpg', title: 'Specimen 08', date: '2020'},
        {img: 'https://api.vieunite.com/ng/files/1576956527395225601/UjxXWlifLjOyO9W.jpg', title: 'Specimen 07', date: '2020'},
    ],
    quote: '"I think the Vieunite platform is a great idea. Textura is very smart, very elegant-looking – you can really see the texture on the screen. Often digital work can be a little bit cold and objective. Textura’s way of displaying it brings that out in a way that I find very enjoyable and very pleasing."',
    biography: ['Jonathan Armours practice builds from a previous career in engineering and is an interplay between digital and oil-based works, including mappings of non-normative bodies, and digital investigations of the human body as a celebrated, objectified, abstracted form.', 'Often the work is driven by a desire to get under the skin, to pare away the superficial and reveal the substance, but it can be an urge to access the entire surface of the body in one go, to map the body like as terrain and to inspect its geography.',
        'His practice involves collaboration with real people and invokes two sides of his brain. The scientific side interrogates digitised bodies, often resulting in time-based works – underpinned by an urge to make the pixel more visceral. The instinctive side of his brain has developed skills in oil painting – chosen for its ability to become flesh on the canvas.',
        'His work has been exhibited widely in the UK, Europe, Australia and USA.'],
    link_to: 'https://www.TheArmourStudio.com',
    social_card: require('../Social Cards/Social Card - Jonathan Armour.jpg'),
    meta: A1Meta,
    vertical_work: false,
}

export const KeYang = {
    name: 'Ke Yang',
    headerDt: require('./keyang/1 - Header Image or Video/Ke Yang - Art Video - Desktop.mp4'),
    headerM: require('./keyang/1 - Header Image or Video/Ke Yang - Art Video - Mobile_2.mp4'),
    avatar_landscape: require('./keyang/2 - Artist Photo/VN-KY-landscape.jpg'),
    avatar_portrait: require('./keyang/2 - Artist Photo/VN-KY-portrait.jpg'),
    avatar_square: require('./keyang/2 - Artist Photo/VN-KY-Square.jpg'),
    works: [
        {img: 'https://api.vieunite.com/ng/files/1576948735649267713/ZQTcVzY8fpUkM2w.jpg', title: 'Rusting Wildly', date: '2021'},
        {img: 'https://api.vieunite.com/ng/files/1576948735649267713/DXTcaDjmsACa7D5.jpg', title: 'After Work', date: '2021'},
        {
            img: 'https://api.vieunite.com/ng/files/1576948735649267713/UbFXZQEkqFsX4Kc.jpg',
            title: 'White Trees and Black Shadows',
            date: '2020'
        },
    ],
    quote: '“Vieunite gives me the opportunity to present and share my wider collection to a much bigger audience.”',
    biography: ['Ke Yang is an ARB Registered Architect and a RIBA Chartered Member since 2022. ',
        'Graduated from the Bartlett School of Architecture and Architectural Association, he now works as an Architect at Zaha Hadid Architects. Besides work, Ke has a strong personal interest in experimenting with different architectural and non-architectural materials to create objects at different scales, from a product or a sculpture to an architectural component. His aim is to use modern fabrication techniques to update traditional construction methods. In addition to these, his aerial documentary work of the Gas Holders in London has been featured in several exhibitions and publications.'],
    link_to: 'https://www.keyang.org/',
    social_card: require('../Social Cards/Social Card - Ke Yang.jpg'),
    meta: A2Meta,
    vertical_work: false,
}
export const LucyNelson = {
    name: 'Lucy Nelson',
    headerDt: require('./lucynelson/1 - Header Image or Video/Lucy Nelson - Art Video - Desktop_1.mp4'),
    headerM: require('./lucynelson/1 - Header Image or Video/Lucy Nelson - Art Video - Mobile_1.mp4'),
    avatar_square: require('./lucynelson/2 - Artist Photo/VN-LN-Square.jpg'),
    works: [
        {img: 'https://api.vieunite.com/ng/files/1576948919481417730/RYzvZYH1j02ukeY.jpg', title: 'Untitled', date: '2022'},
        {img: 'https://api.vieunite.com/ng/files/1576948919481417730/CbPUgBTO9YpCfD3.jpg', title: 'Gourd', date: '2021'},
        {img: 'https://api.vieunite.com/ng/files/1576948919481417730/v6IR0lNJNguRrN6.jpg', title: 'Spores', date: '2021'},
    ],
    quote: '“I think Vieunite is an exciting new platform for practitioners who would like to reach wider audiences and are looking to make their work more accessible. As a visual artist who mainly works with sculpture, the concept of Vieunite really appealed to me. I was intrigued to see how my sculptures could work in a two-dimensional format.”',
    biography: ['Lucy Nelson is a visual artist primarily working with sculpture and alternative photography techniques as an avenue to explore the themes of growth, impermanence and fragility. ',
        'Through her practice she contemplates the relationship she has with her own bodily experience and the fragile life cycles encountered in nature. She is also interested in materiality and how her senses are engaged with the materials she uses. '],
    link_to: 'https://www.lucycollardnelson.co.uk',
    social_card: require('../Social Cards/Social Card - Lucy Nelson.jpg'),
    meta: A3Meta,
    vertical_work: false,
}
export const JoVincent = {
    name: 'Jo Vincent',
    headerDt: require('./jovincent/1 - Header Image or Video/Jo Vincent - Art Video - Desktop.mp4'),
    headerM: require('./jovincent/1 - Header Image or Video/Jo Vincent - Art Video - Mobile.mp4'),
    avatar_square: require('./jovincent/2 - Artist Photo/VN-JV-Portrait.jpg'),
    works: [
        {
            img: 'https://api.vieunite.com/ng/files/1576953607983480834/vm7KJVUHl6Xo6Ec.jpg',
            title: 'Abyss',
            date: '2020'
        },
        {
            img: 'https://api.vieunite.com/ng/files/1576953607983480834/3IlJqFSy36eAEuq.jpg',
            title: 'Beginnings',
            date: '2022'
        },
        {img: require('./WX20230224-172332.png'), title: '', date: ''},
    ],
    quote: '“Vieunite is an exciting and initiative way to display, enjoy, buy and sell art. However, more importantly as an artist I am mostly excited by how my own paintings pop into life when exhibited on these screens, in a way that gives them an extra unique quality, that can only be seen when shown on a Vieunite screen by elaborating the strength of colour and movement in my paintings”',
    biography: ['Jo Vincent is a self-taught disabled artist: her art focuses on colourful atmospheric, emotion-filled, vivid abstract paintings in oils and acrylics on canvas.',
        'She found her passion for painting while recovering from two life-changing strokes and open-heart surgery at age 26. Art has remained a significant part of her life ever since.',
        'Through her abstract artwork she expresses emotions, mood and her journey as a person living with invisible disability. Jo’s creative life has been a positive influence on her health and general well-being ever since her strokes and is her sanctuary during the challenging times.',
        'Jo says “My passion for painting continues to grow, I get great satisfaction in improving my artistic skills and exploring different painting practices. As a result, in recent years I have found my voice as an abstract artist and my work has evolved and blossomed into something that I immensely proud of and excited to share with the world.”',
        'In December 2022 Jo was granted funding from Arts Council England to develop her abstract painting and professional practices, giving her the opportunity to attend a variety of professional abstract painting courses in both Florence Italy and London during 2023. Due to this Art Council Support 2023 is set to be the best year of Jo’s creative career to date. ',
        'Jo shares “I also have the great privilege teaching young creatives with additional learning needs and disabilities as a part-time art and life skills tutor at one of Birmingham\'s biggest FE colleges. Showing them the beneficial nature of art can on health and well-being and the joy of finding a voice through creativity.”'
    ],
    link_to: 'https://jovincentart.com/',
    social_card: require('../Social Cards/Social Card - Jo Vincent.jpg'),
    meta: A4Meta,
    vertical_work: true,
}
export const JenetteColdrick = {
    name: 'Jenette Coldrick',
    headerDt: require('./jenettecoldrick/1 - Header Image or Video/Jenette Coldrick - Art Video - Desktop.mp4'),
    headerM: require('./jenettecoldrick/1 - Header Image or Video/Jenette Coldrick - Art Video - Mobile.mp4'),
    avatar_square: require('./jenettecoldrick/2 - Artist Photo/VN-JC-Square.jpg'),
    works: [
        {img: 'https://api.vieunite.com/ng/files/1576956505349963778/oEN8Z0TNvSSB00F.jpg', title: 'You Me', date: '2022'},
        {img: 'https://api.vieunite.com/ng/files/1576956505349963778/zV4p7JQxkg0oUh1.jpg', title: 'Wake Up', date: '2020'},
        {img: 'https://api.vieunite.com/ng/files/1602678612478051312/qaARK4WFQs1sQyG.jpg', title: 'Northern Light', date: '2020'},
    ],
    quote: '"I was impressed with how well the Textura Digital Canvas reproduced and enhanced every mark and brushstroke in my work. I perceive potential beyond  home decor. I can envision this exciting medium of presentation in art galleries, public spaces, recreation areas and the artist gallery".',
    biography: ['Born in Cornwall in 1952, Jenette was inspired by the artists she met as a child, who encouraged her to watch and participate in the creative process.',
        'Working from her studios in Redcar and micro pottery in Stamford, Jenette uses her work to explore her emotions, memory and the environment and fills her work with the excitement she feels for life. Over the years she has experimented with and embraced many different mediums, finding simpler ways of working due to having spinal stenosis. These adjustments, Jenette feels, have enhanced the character of her work.',
        'Jenette studied Ceramics at the Ulster College of Art and Design in Belfast. One of the most progressive art establishments in the 1970\'s. She was tutored in painting by Niel Shawcross and pottery by David Leech, who taught her so much about hand building.'
    ],
    link_to: 'https://www.jenettecoldrick.com/',
    social_card: require('../Social Cards/Social Card - Jenette Coldrick.jpg'),
    meta: A5Meta,
    vertical_work: true,
}