export const left_text = [
    'In the world of hospitality and customer service, ambiance is everything. It\'s the first impression your guests receive, setting the stage for unforgettable experiences. That\'s where the Textura Pro comes in – a cutting-edge digital canvas that allows you to immerse your guests in a visually stunning experience.',
    "With our carefully curated art collections, you can showcase everything from classic to contemporary artwork, customising your canvas to perfectly match your brand aesthetics. "
]

export const right_text = [
    "Plus, with our four frame options - Birch, Walnut, Pine and Black – you can easily transform your business spaces into artistic havens that impress and inspire your guests.",
    "Get in touch to explore tailored digital canvas solutions. With your vision and our technology, we'll redefine hospitality aesthetics."
    ]

export const NEW_HOME_TEXT = ['Vieunite Pro engages your customers by creating a modern and dynamic environment, turning the casual visitors into loyal patrons. Effective communication is essential. Using the Textura Pro digital canvas in conjunction with our Curated Collections, you can captivate and inspire your audience, as well as subtly delivering promotional content or other messaging.',
    'Your brand is your story. Vieunite Pro helps you to establish and strengthen your brand in a compelling and completely new way, setting you apart from your competitors.',
    'Boosting employee morale is crucial for sustaining a positive and productive work environment. Vieunite Pro provides a new and stimulating method to motivate and communicate with your workforce.']
