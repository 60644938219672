import React from 'react';
import {Form, Input, Button} from 'antd';
import './style.scss'
import {Link, useLocation} from "react-router-dom";
import {post_api} from "../../portal/serverTemp";

const subMap = {
    0: 'Standard',
    1: 'Advanced',
    2: 'Premium',
}
const ProSignIn = () => {
    const {state} = useLocation();
    const nextPage = state?.nextPage || ''
    console.log(nextPage)
    const onFinish = (values) => {
        console.log('Received values of form: ', values);
        const data = {
            "account": values.email,
            "password": values.Password,
        }
        post_api('/user/login', data)
            .then(res => {
                console.log(res);
                localStorage.setItem("token", res.token);
                localStorage.setItem("subscription", subMap[res.subscription])
                localStorage.setItem("user_id", res.id)
                localStorage.setItem("tried", res.tried)
            }).then(() => {
                if (nextPage) {
                    window.location.href = nextPage;
                }
                else{
                    window.location.href = '/proportal/';
                }
        });
    };

    return (
        <div style={{marginTop: '5vw'}}>
            <div className="Title">
                Vieunite Pro Business Sign In
            </div>
            <Form
                initialValues={{remember: true}}
                onFinish={onFinish}
                colon={false}
            >
                <Form.Item
                    label={<span className="ProSignIn-email-label">Email</span>}
                    name="email"
                    rules={[
                        {required: true, message: 'Please input your email!'},
                        {type: 'email', message: 'Please enter a valid email!'}
                    ]}
                    labelCol={{span: 8}}
                    wrapperCol={{span: 5}}
                >
                    <Input className={'ProSignIn-input-Email-form'}/>
                </Form.Item>
                <Form.Item
                    label={<span className="ProSignIn-password-label">Password</span>}
                    name="Password"
                    rules={[
                        {required: true, message: 'Please input your password!'},
                        {
                            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{10,}$/,
                            // message: 'Password must be at least 10 characters and include @$!%*?&, lowercase, uppercase, and numbers.'
                            message: ''
                        }
                    ]}
                    labelCol={{span: 8}}
                    wrapperCol={{span: 5}}
                >
                    <Input.Password className={'ProSignIn-input-Password-form'}/>
                </Form.Item>
                <div className="forgot-password-link">
                    <Link to="/business/ProForgotPassword">Forgot your password?</Link>
                </div>
                <div className="ProSignIn-center-button">
                    <Button type="primary"
                            htmlType="submit"
                            className="custom-Login-button"
                    >
                        Login
                    </Button>
                </div>
                <div className="ProSignIn-center-button">
                    <Button type="primary"
                            className="custom-Create-button"
                            onClick={_ => window.location.href = '/business/prosignup'}
                    >
                        Create an account
                    </Button>
                </div>
            </Form>


        </div>
    );
};

export default ProSignIn;
