import React, {useState} from 'react';
import './MobileMenu.scss';
import {ReactComponent as Logo} from "../../img/SVG/Vieunite_logo_Text-black-01.svg";
import {Divider, Row} from "antd";
import {useNavigate} from "react-router-dom";

export default function MobileMenu() {
    const [forHomeShow, setForHomeShow] = useState(false)
    const [forBShow, setForBShow] = useState(false)
    return (
        <div>
            <h1>
                <a href="/"><Logo style={{margin: '2vh 0 0 -30vw'}}/></a>
            </h1>

            <input id="burger" type="checkbox"/>

            <label htmlFor="burger">
                <span></span>
                <span></span>
                <span></span>
            </label>
            <nav>
                <Row justify={"center"} style={{
                    margin: '10vh 0 0 0',
                    columnGap: '10vw'
                }}>
                    <svg onClick={() => {
                        window.open('https://www.instagram.com/vieunite/', '_blank');
                    }} xmlns="http://www.w3.org/2000/svg" width="39" height="37" viewBox="0 0 39 37" fill="none">
                        <g clip-path="url(#clip0_4_6259)">
                            <path
                                d="M38.5919 8.52248V28.4559C38.4392 29.1471 38.3499 29.8574 38.1243 30.526C36.8098 34.4119 33.1633 36.9615 28.8837 36.9739C22.4906 36.9908 16.0976 36.9829 9.70454 36.9762C7.65106 36.9739 5.75852 36.4302 4.09624 35.2651C1.34848 33.3402 0.00220129 30.723 0.00102653 27.4665C-0.00014823 21.5083 -0.00367251 15.5512 0.00690033 9.59412C0.00690033 8.98288 0.0386188 8.36263 0.151396 7.76265C0.799863 4.3451 2.82515 1.96205 6.17204 0.657394C7.03079 0.323069 7.98469 0.212752 8.89513 0C15.8297 0 22.7643 0 29.6978 0C29.968 0.0461526 30.2393 0.0889283 30.5084 0.138458C34.0503 0.778967 36.5313 2.70162 37.8952 5.88503C38.2512 6.71577 38.3663 7.64108 38.5919 8.52361V8.52248ZM35.3531 18.4903C35.3531 18.4903 35.3625 18.4903 35.3672 18.4903C35.3672 15.4814 35.3613 12.4725 35.3707 9.46354C35.373 8.49997 35.2379 7.56228 34.7892 6.69664C33.571 4.3451 31.5856 3.10348 28.8402 3.09448C22.4718 3.07309 16.1035 3.0866 9.73626 3.0911C9.36151 3.0911 8.98441 3.12487 8.61201 3.17553C5.68921 3.56951 3.26921 6.10228 3.25041 8.93898C3.20695 15.2337 3.23279 21.5274 3.22927 27.8222C3.22927 28.6777 3.4043 29.5028 3.79667 30.266C5.03135 32.6626 7.05428 33.885 9.84669 33.8884C16.1399 33.8974 22.4319 33.8929 28.7251 33.8884C29.1503 33.8884 29.5779 33.8569 29.9997 33.8017C32.8661 33.4246 35.3037 30.8649 35.3425 28.0946C35.3883 24.8943 35.3531 21.6917 35.3531 18.4903Z"
                                fill="black"/>
                            <path
                                d="M28.9388 18.5355C28.8883 23.6551 24.5123 27.7987 19.2259 27.7334C13.889 27.667 9.59641 23.5054 9.64693 18.4443C9.69744 13.3225 14.0699 9.1834 19.3598 9.24756C24.6979 9.31285 28.9893 13.4745 28.94 18.5355H28.9388ZM19.2987 12.3353C15.7486 12.3297 12.8846 15.0662 12.8693 18.4758C12.854 21.872 15.7075 24.6231 19.2682 24.6457C22.823 24.667 25.72 21.9013 25.7165 18.4871C25.7129 15.0819 22.8524 12.3409 19.2987 12.3353Z"
                                fill="black"/>
                            <path
                                d="M29.7704 10.7751C28.4276 10.7841 27.3516 9.76082 27.3516 8.4753C27.3516 7.21004 28.4088 6.18681 29.734 6.16654C31.0427 6.14628 32.1657 7.21342 32.1646 8.47643C32.1646 9.73493 31.0896 10.7649 29.7704 10.7739V10.7751Z"
                                fill="black"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_4_6259">
                                <rect width="38.592" height="36.984" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <svg onClick={() => {
                        window.open('https://www.youtube.com/channel/UClpqq46UCV1Ojkfef0HijFQ', '_blank');
                    }} xmlns="http://www.w3.org/2000/svg" width="48" height="35" viewBox="0 0 48 35" fill="none">
                        <g clip-path="url(#clip0_4_6263)">
                            <path
                                d="M0.774414 23.5886C0.774414 18.9945 0.774414 14.4005 0.774414 9.8045C0.834848 9.18626 0.887727 8.56607 0.955715 7.94783C1.09547 6.68413 1.36364 5.45931 1.99253 4.34142C2.98779 2.5703 4.61194 1.75764 6.43628 1.27938C8.43248 0.754459 10.4778 0.622256 12.5287 0.618368C19.6316 0.608647 26.7344 0.604759 33.8372 0.612535C35.2933 0.612535 36.7513 0.643642 38.2036 0.729185C39.7805 0.82056 41.3404 1.05775 42.8362 1.61961C44.7455 2.337 46.0712 3.63958 46.7096 5.65956C47.2176 7.26932 47.3498 8.93352 47.363 10.6055C47.397 14.6824 47.4329 18.7612 47.3649 22.8381C47.3385 24.4362 47.1402 26.0499 46.8304 27.6168C46.2242 30.675 44.4905 32.7572 41.5028 33.6379C39.7956 34.1414 38.0487 34.3456 36.2886 34.3514C28.6097 34.3806 20.929 34.3728 13.2502 34.3747C11.1501 34.3747 9.06514 34.227 7.03684 33.6165C4.83479 32.9536 3.05767 31.7248 2.02463 29.5357C1.40141 28.2137 1.10869 26.7984 0.953826 25.348C0.895281 24.7628 0.834848 24.1757 0.774414 23.5886ZM18.7232 17.2584C18.7232 18.9304 18.7194 20.6004 18.7232 22.2724C18.727 23.7869 19.8299 24.4887 21.1254 23.7946C24.2566 22.1168 27.3822 20.4274 30.5134 18.7515C31.1121 18.4307 31.5086 17.9758 31.5049 17.2564C31.4992 16.5371 31.0913 16.0938 30.4926 15.775C27.3708 14.1147 24.2547 12.4388 21.133 10.7746C19.8431 10.0864 18.727 10.7921 18.7232 12.2891C18.7194 13.9436 18.7232 15.6 18.7232 17.2545V17.2584Z"
                                fill="black"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_4_6263">
                                <rect width="46.632" height="33.768" fill="white"
                                      transform="translate(0.774414 0.608398)"/>
                            </clipPath>
                        </defs>
                    </svg>
                    <svg onClick={() => {
                        window.open('https://www.linkedin.com/company/vieunite', '_blank');
                    }} xmlns="http://www.w3.org/2000/svg" width="38" height="37" viewBox="0 0 38 37" fill="none">
                        <path
                            d="M20.2357 36.984H17.9242C17.8442 36.9638 17.7662 36.93 17.6853 36.9252C16.1183 36.8142 14.5831 36.5258 13.1105 35.9797C6.39458 33.4917 2.31959 28.7279 0.880681 21.7152C0.741028 21.0361 0.68324 20.3395 0.587891 19.6507C0.587891 18.8799 0.587891 18.11 0.587891 17.3392C0.609079 17.235 0.633158 17.1308 0.65242 17.0257C0.863345 15.8448 0.977957 14.637 1.29771 13.4861C4.529 1.87072 17.876 -3.56361 28.3481 2.51613C33.2841 5.38234 36.2505 9.71881 37.2936 15.3442C37.4159 16.004 37.4804 16.6745 37.5719 17.3402V19.5793C37.5517 19.6951 37.5266 19.8099 37.5103 19.9266C37.4101 20.6521 37.3465 21.3853 37.2069 22.1031C35.767 29.5469 29.7436 35.4279 22.263 36.7004C21.5908 36.8152 20.9108 36.8904 20.2347 36.984H20.2357ZM16.2628 15.2506V28.2127H20.2453C20.2453 28.0526 20.2453 27.9117 20.2453 27.7708C20.2453 25.7488 20.2395 23.7277 20.2491 21.7056C20.252 20.982 20.3089 20.2614 20.6411 19.5967C21.0745 18.7304 21.9269 18.3474 23.0913 18.4911C23.9042 18.5914 24.457 19.1317 24.6343 20.0713C24.7257 20.5527 24.771 21.0496 24.7749 21.5406C24.7903 23.6341 24.7826 25.7285 24.7835 27.822C24.7835 27.9493 24.7835 28.0757 24.7835 28.2059H28.7998C28.8075 28.0873 28.8181 27.9927 28.8181 27.8992C28.8181 25.5886 28.8431 23.2771 28.8046 20.9676C28.7892 20.0463 28.7015 19.1143 28.5224 18.2113C28.2209 16.689 27.3743 15.5583 25.8112 15.1502C25.1678 14.9824 24.4802 14.9023 23.8156 14.9187C22.3083 14.9554 21.0716 15.5651 20.2337 16.8694C20.2135 16.9003 20.175 16.9205 20.0816 16.9987V15.2496H16.2628V15.2506ZM13.6797 28.2108V15.2612H9.69425V28.2108H13.6797ZM11.6696 13.4514C12.9795 13.4485 14.0302 12.3921 14.0216 11.0849C14.0129 9.80756 12.9352 8.74636 11.6571 8.7589C10.3694 8.77144 9.32633 9.82782 9.32729 11.1167C9.32826 12.4094 10.3761 13.4542 11.6687 13.4514H11.6696Z"
                            fill="black"/>
                    </svg>
                </Row>
                <ul style={{margin: '6vh 0 0 0'}}>
                    <li>
                        <div onClick={() => {
                            setForHomeShow(!forHomeShow)
                        }}
                             style={{display: "flex", alignItems: 'center', justifyContent: 'space-between'}}>
                            <a className={'new-m-hd-a'}>Vieunite Home</a>
                            {forHomeShow ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 15 10"
                                     fill="none">
                                    <path d="M1 8.5L7.5 2L14 8.5" stroke="black" stroke-width="2"
                                          stroke-linecap="round"/>
                                </svg> :
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 15 10"
                                     fill="none">
                                    <path d="M14 1.25L7.5 7.75L1 1.25" stroke="black" stroke-width="2"
                                          stroke-linecap="round"/>
                                </svg>}
                        </div>
                        <Divider className={'m-menu-divider'}/>
                        {forHomeShow && <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                            justifyContent: 'start',
                            paddingTop: '4vh'
                        }}>
                            <p className={'m-new-hd-p'} onClick={() => {
                                window.location.href = '/home'
                            }}>Overview</p>
                            <p className={'m-new-hd-p'} onClick={() => {
                                window.location.href = '/home/digital-canvas'
                            }}>Textura</p>
                            <p className={'m-new-hd-p'} onClick={() => {
                                window.location.href = '/home/vieunite-app'
                            }}>Vieunite App</p>
                            <p className={'m-new-hd-p'} onClick={() => {
                                window.location.href = '/home/support'
                            }}>Support</p>
                        </div>}

                    </li>
                    <li>
                        <div
                            onClick={() => {
                                setForBShow(!forBShow)
                            }}
                            style={{display: "flex", alignItems: 'center', justifyContent: 'space-between'}}>
                            <a className={'new-m-hd-a'}>Vieunite Pro</a>
                            {forBShow ?
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 15 10"
                                     fill="none">
                                    <path d="M1 8.5L7.5 2L14 8.5" stroke="black" stroke-width="2"
                                          stroke-linecap="round"/>
                                </svg> :
                                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="10" viewBox="0 0 15 10"
                                     fill="none">
                                    <path d="M14 1.25L7.5 7.75L1 1.25" stroke="black" stroke-width="2"
                                          stroke-linecap="round"/>
                                </svg>}
                        </div>
                        <Divider className={'m-menu-divider'}/>
                        {forBShow && <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "start",
                            justifyContent: 'start',
                            paddingTop: '4vh'
                        }}>
                            <p className={'m-new-hd-p'} onClick={() => {
                                window.location.href = '/business'
                            }}>Overview</p>
                            <p className={'m-new-hd-p'} onClick={() => {
                                window.location.href = '/business/textura-pro'
                            }}>Textura Pro</p>
                            <p className={'m-new-hd-p'} onClick={() => {
                                window.location.href = '/business/subscription'
                            }}>Subscription</p>
                            <p className={'m-new-hd-p'} onClick={() => {
                                window.location.href = '/business/software'
                            }}>Software</p>
                            <p className={'m-new-hd-p'} onClick={() => {
                                window.location.href = '/business/curated-collections'
                            }}>Curated Collection</p>
                            <p className={'m-new-hd-p'} onClick={() => {
                                window.location.href = '/contact'
                            }}>Vieunite Pro Support</p>
                        </div>}
                    </li>
                    {/*<li><a href="/digital-canvas">Textura</a><Divider className={'m-menu-divider'}/></li>*/}
                    {/*<li><a href="/artists">Vieunite Artists</a><Divider className={'m-menu-divider'}/></li>*/}
                    <li onClick={() => {
                        if ((/Android/i.test(navigator.userAgent))) {
                            window.location.href = 'https://play.google.com/store/apps/details?id=com.app.vieunite&gl=GB';
                        }
                        if (/iPad|iPhone|iPod/i.test(navigator.userAgent)) {
                            window.location.href = 'https://apps.apple.com/gb/app/vieunite/id1644381065';
                        }
                    }}><a href={"/art"}>Art</a><Divider className={'m-menu-divider'}/></li>
                    {/*<li>*/}
                    {/*    <a href={'/artistportal'}>Artist Portal</a>*/}
                    {/*</li>*/}
                    <li><a href="/research/overview">Research</a><Divider className={'m-menu-divider'}/></li>
                    <li><a href="/news">News</a><Divider className={'m-menu-divider'}/></li>
                    <li><a href="/contact">Contact Us</a><Divider className={'m-menu-divider'}/></li>
                    {/*<li><a href="/artistportal">Artist Portal</a><Divider className={'m-menu-divider'}/></li>*/}
                    <li style={{flexGrow: 1}}></li>
                    {/* This will push the last <li> to the bottom */}
                    <li style={{marginBottom: '16vh'}}>
                        {/*<Divider className={'m-menu-divider'} style={{}}/>*/}
                        <div style={{display: "flex", alignItems: "center", justifyContent: "start", columnGap:'1vw'}}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16"
                                 fill="none">
                                <path
                                    d="M7.63551 0C7.87485 0 8.11404 0 8.35338 0C8.38536 0.00473141 8.41717 0.0122364 8.44915 0.0138679C8.99636 0.0422564 9.53509 0.12579 10.063 0.270995C11.9078 0.778561 13.392 1.8105 14.5078 3.3624C15.3203 4.49255 15.7938 5.75698 15.9421 7.14181C15.9597 7.30611 15.9734 7.47089 15.9889 7.63535V8.35322C15.9842 8.38503 15.9767 8.41685 15.9749 8.44882C15.9439 9.03699 15.8522 9.61601 15.6843 10.1804C15.0292 12.3837 13.6821 14.0252 11.6536 15.1017C10.7762 15.5673 9.83561 15.8396 8.84691 15.9419C8.68246 15.9589 8.51784 15.9731 8.35322 15.9887H7.63535C7.60353 15.984 7.57172 15.9765 7.53974 15.9747C6.7447 15.9323 5.97218 15.776 5.22788 15.4954C3.4412 14.8215 2.05849 13.6607 1.08692 12.0171C0.508055 11.038 0.165273 9.97788 0.0464983 8.8461C0.0293673 8.68213 0.0154994 8.51767 0 8.35338C0 8.11404 0 7.87485 0 7.63551C0.0101154 7.52799 0.0213729 7.42048 0.0301831 7.3128C0.0941387 6.52771 0.269527 5.76807 0.565811 5.03862C1.28564 3.26614 2.48464 1.91296 4.15499 0.982664C5.08169 0.466451 6.07806 0.159073 7.13366 0.0474772C7.30072 0.0298568 7.46828 0.0158257 7.63551 0ZM3.15813 13.2926C3.16939 13.2406 3.17934 13.191 3.19093 13.1417C3.41983 12.1688 3.92854 11.3728 4.71852 10.763C5.33964 10.2835 6.04201 10.0061 6.83362 9.99746C7.61381 9.98898 8.39433 9.98686 9.17436 10.0012C9.43524 10.0059 9.70379 10.0397 9.95374 10.112C11.2903 10.499 12.1656 11.3862 12.6581 12.6689C12.7352 12.8693 12.7807 13.0818 12.8405 13.2874C14.7103 11.6353 15.7743 8.75082 14.8582 5.84068C13.8885 2.76004 11.0245 0.726026 7.79328 0.801076C4.6265 0.874821 1.85765 3.06465 1.03553 6.1722C0.276216 9.0427 1.41534 11.7727 3.15813 13.2926Z"
                                    fill="black"/>
                                <path
                                    d="M7.98917 9.19092C6.43482 9.18374 5.1828 7.92633 5.19046 6.3803C5.19813 4.82775 6.45946 3.57767 8.00745 3.58795C9.55804 3.59823 10.8066 4.85597 10.7982 6.39874C10.7895 7.94884 9.53275 9.1981 7.98901 9.19092H7.98917Z"
                                    fill="black"/>
                            </svg>
                            <a className={'m-new-hd-login'} href="/business/prologin" style={{display: "flex"}}>
                                Log In
                            </a>
                        </div>
                    </li>
                </ul>
            </nav>
        </div>
    )
}