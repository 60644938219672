import React from 'react';
import {Button, Col, Layout, Row} from 'antd';
import img_1 from './imgs/Artist Login.webp';
import img_2 from './imgs/Business Login.webp';
import img_3 from './imgs/Home User Login.webp';
import m_img_1 from './imgs/m_pro_login_1.png'
import m_img_2 from './imgs/m_pro_login_2.png'
import m_img_3 from './imgs/m_pro_login_3.png'
import jkQR from './imgs/WX20231221-105402.png';
import './style.scss';
import useWindowResize from "../../apps/Hooks/useWindowResize";
import {Content} from "antd/es/layout/layout";

const ProLogin = () => {
    const {windowWidth} = useWindowResize()
    return (
        <Layout>
            {windowWidth > 600 && <div className="pro-login-container">
                <Row justify={'center'}>
                    <p className={'p-login-title'}>How will you use Vieunite Pro?</p>
                </Row>
                <Row justify="center" gutter={0}
                     className="image-row"
                     style={{margin: '0 0 -2.2rem 0'}}
                >
                    <Col lg={8} className="image-col">
                        <div className="image-overlay-container">
                            {/*<img src={img_1} alt="Artist Portal" className="img-full"/>*/}
                            <div className="jk-overlay">
                                <p className="overlay-title">Vieunite Artist</p>
                                <Button className="overlay-button"
                                        onClick={_ =>
                                            window.location.href = '/artistportal/login'}>
                                    Artist Portal Log In
                                </Button>
                            </div>
                        </div>
                    </Col>
                    <Col lg={8} className="image-col">
                        <div className="image-overlay-container2">
                            {/*<img src={img_2} alt="Business Portal" className="img-full"/>*/}
                            <div className="jk-overlay">
                                <p className="overlay-title">Vieunite Pro</p>
                                <Row justify={"center"} gutter={24}>
                                    <Col>
                                        <Button className="overlay-button"
                                                onClick={() => {
                                                    const token = localStorage.getItem('token')
                                                    if (token === '' || token === null || token.length === 0) {
                                                        window.location.href = '/business/prosignin'
                                                    } else {
                                                        window.location.href = '/proportal'
                                                    }
                                                }
                                                }>
                                            Business Portal Log In
                                        </Button>
                                    </Col>
                                    <Col>
                                        <Button className="overlay-button"
                                                onClick={_ => window.location.href = '/business/prosignup'}
                                        >
                                            Sign up
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col lg={8} className="image-col">
                        <div className="image-overlay-container3">
                            {/*<img src={img_3} alt="Vieunite Home" className="img-full"/>*/}
                            <div className="jk-overlay">
                                <p className="overlay-title">Vieunite Home</p>
                                <p className="overlay-text">For our home users, we invite you to join the Vieunite app
                                    and embark on an exploration of the captivating Vieunite world.</p>
                                <div>
                                    <span
                                        className="overlay-text-qr">Scan to download the Vieunite App&nbsp;&nbsp;&nbsp;</span>
                                    <img src={jkQR} alt="jkQR" className="jk-qr-img"
                                         style={{width: '20%'}}/>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>}
            {windowWidth <= 600 &&
                <Content style={{background: '#F4F4F4', marginTop: '11vh', padding: 0}}>
                    <Row justify={"center"}>
                        <p className={'m-curated-h1'}
                           style={{
                               textAlign: "center", width: '60vw'
                           }}>How will you use Vieunite Pro?</p>
                        <img src={m_img_1} className={'m_pro_login_img'}/>
                        <Button className={'m-pro-login-btn'}
                                onClick={_ => {
                                    window.location.href = '/artistportal/login'
                                }}
                        >
                            Artist Portal
                        </Button>
                        <img src={m_img_2} className={'m_pro_login_img'}/>
                        <Button className={'m-pro-login-btn'}
                                onClick={_ => {
                                    window.location.href = '/business/explore'
                                }}
                        >
                            Business Portal
                        </Button>
                        <img src={m_img_3} className={'m_pro_login_img'}/>
                        <Button className={'m-pro-login-btn'}
                                onClick={_ => {
                                    window.location.href = 'https://apps.apple.com/gb/app/vieunite-pro-connect/id6499339979'
                                }}
                        >
                            Download App
                        </Button>
                    </Row>

                </Content>
            }
        </Layout>
    )
}

export default ProLogin;
