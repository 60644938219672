import React, {useRef} from 'react';
import {Link, redirect, useNavigate} from 'react-router-dom';
import {Layout, Col, Row, Card, Space, Button} from 'antd';
import '../../App.css';
import "../Textura/Textura.css"
import "./HomePage.scss";
import "./HomePageJack.scss";
import {Content} from "antd/es/layout/layout";
import {DiscoverText, HOME_TEXT, TEXTURA_TEXT} from "../../text.js";
import landscape_vd from "../../img/HomePage/1 - Header Video/Vieunite Launch - Homepage - Landscape 2.mp4";
import section2_img from "./img/desktop-album-evergreen-1x 1.png"
import section3_1_img from "./img/Group 1904.png"
import section3_2_img from "./img/Mask group.png"
import vertical_vd from "../../img/HomePage/1 - Header Video/3334ce07-9121-4893-ba16-c3d5db436bc3.mp4";
import {HomepageMeta} from "../../components/PageMeta/HomepageMeta";
import NTULogo from "../../img/HomePage/9 - Logos/ntu.svg"
import UKTILogo from "../../img/HomePage/9 - Logos/innovate - 2024.svg"
import RBSALogo from "../../img/HomePage/9 - Logos/rbsa_logo.png"
import NationalGalleryLogo from "../Art/img/l2.png"
import EstorikLogo from "../../img/HomePage/9 - Logos/estorik_logo.png"
import RoyalLogo from "../../img/HomePage/9 - Logos/the royal society.svg"

import section1_pic from "./img/section_1.png"
import homeExample1 from "./img/homeExample1.png"
import homeExample2 from "./img/homeExample2.png"
import homeExample3 from "./img/homeExample3.png"
import homeExample4 from "./img/homeExample4.png"
import homeExample5 from "./img/homeExample5.png"
import homeExample6 from "./img/homeExample6.png"
import mapPopup from "./img/MapPopUp1.png"

import leftScroll from "./img/LeftScroll.png"
import rightScroll from "./img/RightScroll.png"
import scrollActive from "./img/ScrollActive.png"
import scrollInactive from "./img/ScrollInactive.png"
import {useState} from 'react';
import {Modal} from 'antd';
import useWindowResize from "../../apps/Hooks/useWindowResize";
import {useMediaQuery} from "react-responsive";
import mapPopup1 from "./img/MapPopUp1.png";
import mapPopup2 from "./img/MapPopUp2.png";
import mapPopup3 from "./img/MapPopUp3.png";

const Primary_Font = {
    fontFamily: "Proxima Nova bold, sans-serif",
}

const TitleFont = {
    fontSize: '38px',
    fontFamily: "Proxima Nova bold, sans-serif",
}

const Img_Radius = {
    borderRadius: '0.625rem',
}


function HomePage() {
    // For SSG through "react-snap", can't assign the "mute" property directly on <video> tags.
    // So using the following function to do it
    const makeMuted = (elt) => {
        if (elt) {
            elt.muted = true;
        }
    };

    const navigate = useNavigate();

    const [activeDirection, setActiveDirection] = useState('right'); // 'left', 'right', or 'none'

    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalContent, setModalContent] = useState({});

    const handleImageClick = (src, alt, mapEmbedUrl, mapTitle, mapAddress, mapCity, mapPostCode, directions, storeUrl, popupImg) => {
        setModalContent({
            src,
            alt,
            mapEmbedUrl,
            mapTitle,
            mapAddress,
            mapCity,
            mapPostCode,
            directions,
            storeUrl,
            popupImg
        });
        setIsModalVisible(true);
    };


    const handleModalClose = () => {
        setIsModalVisible(false);
    };


    const images = [
        {
            src: homeExample1,
            alt: 'RBSA Gallery | Birmingham',
            mapEmbedUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2429.6796954941015!2d-1.910062223074293!3d52.48493493882477!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4870bced5c404861%3A0x67d30a7e404875a1!2sRBSA%20Gallery!5e0!3m2!1sen!2suk!4v1728554572584!5m2!1sen!2suk',
            mapTitle: 'RBSA Gallery | Birmingham',
            mapAddress: '4 Brook St,',
            mapCity: 'Birmingham',
            mapPostCode: 'B3 1SA',
            directions: '"Textura" is displayed in the ground floor gallery, on the main wall to the right of the entrance.',
            storeUrl: 'https://rbsa.org.uk/rbsa-gallery-joins-forces-with-vieunite/',
            popupImg: mapPopup1 // 这里添加你的图片路径
        },
        {
            src: homeExample2,
            alt: 'Selfridges  | London',
            mapEmbedUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.9341941116795!2d-0.1553419233794988!3d51.51442327181495!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876052d5803490d%3A0x811f22bae26bbdbe!2sSelfridges!5e0!3m2!1sen!2suk!4v1728553412827!5m2!1sen!2suk',
            mapTitle: 'Selfridges | London',
            mapAddress: '400 Oxford St,',
            mapCity: 'London',
            mapPostCode: 'W1A 1AB',
            directions: 'Go to the bottom floor and look for the smart tech section, they are against the back wall.',
            storeUrl: 'https://www.selfridges.com/GB/en/product/smartech-vieunite-textura-digital-canvas_R04300545/',
            popupImg: mapPopup2 // 这里添加你的图片路径

        },
        {
            src: homeExample3,
            alt: 'Estorick Gallery | London',
            mapEmbedUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2481.3255005553456!2d-0.10306062311928124!3d51.543930108033685!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761b6480214f87%3A0x80ba257b14022d92!2sEstorick%20Collection%20of%20Modern%20Italian%20Art!5e0!3m2!1sen!2suk!4v1728554794806!5m2!1sen!2suk',
            mapTitle: 'Estorick Collection | London',
            mapAddress: '39A Canonbury Square,',
            mapCity: 'London',
            mapPostCode: 'N1 2AN',
            directions: '"Textura" is displayed on the ground floor, on one of the main walls in the hallway.',
            storeUrl: 'https://www.estorickcollection.com/news/2024/05/10/we-are-partnering-with-vieunite',
            popupImg: mapPopup3 // 这里添加你的图片路径
        },
        {
            src: homeExample4,
            alt: 'Printemps | Paris',
            mapEmbedUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d20993.46750550073!2d2.2900303743164034!3d48.873779500000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66f051e3f022b%3A0xf443f2dee951e103!2sPrintemps%20Haussmann!5e0!3m2!1sen!2suk!4v1728554980070!5m2!1sen!2suk',
            mapTitle: 'Printemps | Paris',
            mapAddress: '64 Bd Haussmann,',
            mapCity: '75009, Paris',
            mapPostCode: 'France',
            directions: 'Coming soon',
            storeUrl: 'https://www.printemps.com/uk/en/printemps-paris-haussmann',
            popupImg: ' ' // 这里添加你的图片路径
        },
        {
            src: homeExample5,
            alt: 'KaDeWe | Berlin',
            mapEmbedUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2428.7588134982816!2d13.338418076926489!3d52.501605337590924!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47a850542272bf61%3A0xedabbb0cc3d90eec!2sKaDeWe%20-%20Kaufhaus%20des%20Westens!5e0!3m2!1sen!2suk!4v1728555047665!5m2!1sen!2suk',
            mapTitle: 'KaDeWe | Berlin',
            mapAddress: 'Tauentzienstraße 21-24,',
            mapCity: '10789 Berlin',
            mapPostCode: 'Germany',
            directions: 'Coming soon',
            storeUrl: 'https://www.kadewe.de/',
            popupImg: ' ' // 这里添加你的图片路径
        },
        {
            src: homeExample6,
            alt: 'La Rinascente | Rome',
            mapEmbedUrl: 'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2969.5398275997586!2d12.48201377646005!3d41.902752663869656!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132f61aca8a05a77%3A0x957def62d797f9e6!2sRINASCENTE%20Rome!5e0!3m2!1sen!2suk!4v1728555111401!5m2!1sen!2suk',
            mapTitle: 'La Rinascente | Rome',
            mapAddress: 'Via del Tritone,',
            mapCity: '61, 00187 Roma RM,',
            mapPostCode: 'Italy',
            directions: 'Coming soon',
            storeUrl: 'https://www.rinascente.it/en/',
            popupImg: ' ' // 这里添加你的图片路径
        },
    ];

    const [index, setIndex] = useState(0);
    const [activeLineIndex, setActiveLineIndex] = useState(0);
    const sliderRef = useRef(null); // 创建ref
    const {windowWidth} = useWindowResize()
    const itemsPerPage = 3;
    const totalLines = windowWidth > 920 ? 4 : 6; // 固定显示4个横线
    const ScrollActive = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="4" viewBox="0 0 30 4" fill="none">
            <path d="M2 2H28" stroke="white" strokeWidth="3" strokeLinecap="round"/>
        </svg>
    );

    const ScrollInactive = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="4" viewBox="0 0 30 4" fill="none">
            <path d="M2 2H28" stroke="#717171" strokeWidth="3" strokeLinecap="round"/>
        </svg>
    );

    const slideRight = () => {
        const newIndex = (index + 1) % totalLines;
        setIndex(newIndex);
        setActiveLineIndex(prevLineIndex => (prevLineIndex + 1) % totalLines);
    };

    const slideLeft = () => {
        const newIndex = (index - 1 + totalLines) % totalLines;
        setIndex(newIndex);
        setActiveLineIndex(prevLineIndex => (prevLineIndex - 1 + totalLines) % totalLines);
    };
    const CloseIcon = () => (
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="23" viewBox="0 0 24 25" fill="none">
            <path d="M23 1.70703L1 23.707" stroke="#A9A9A9" stroke-width="2" stroke-linecap="round"/>
            <path d="M1 1L23 23" stroke="#A9A9A9" stroke-width="2" stroke-linecap="round"/>
        </svg>
    );
    const handleDragStart = (e) => {
        if (e.type === 'touchstart') {
            sliderRef.current.startX = e.touches[0].clientX;
        } else {
            sliderRef.current.startX = e.clientX;
        }
    };

    const handleDragMove = (e) => {
        if (!sliderRef.current.startX) return;

        let currentX;
        if (e.type === 'touchmove') {
            currentX = e.touches[0].clientX;
        } else {
            currentX = e.clientX;
        }

        const diff = sliderRef.current.startX - currentX;
        if (diff > 50) {
            // Swipe left
            slideRight();
            sliderRef.current.startX = null;
        } else if (diff < -50) {
            // Swipe right
            slideLeft();
            sliderRef.current.startX = null;
        }
    };

    const handleDragEnd = () => {
        sliderRef.current.startX = null;
    };
    const isMobile = useMediaQuery({query: '(max-width: 1000px)'});
    return (
        <div>
            <HomepageMeta></HomepageMeta>
            <Layout>
                <Content>
                    <div className="homeVideoContainer">
                        <video playsInline loop autoPlay ref={makeMuted} muted rel={"preload"}>
                            <source src={landscape_vd} type={'video/mp4'}></source>
                        </video>
                    </div>
                    <div className="homeVideoTextContainer">
                        <p>Bringing art to life with the<br/>Digital Canvas</p>
                        <button
                            className="homeMainButton" style={{width: "20vw", minWidth: "200px"}}
                            onClick={_ => window.location.href = "/home/digital-canvas"}
                        >
                            <a className={'homeMainButtonLnk homeOverV'}>
                                LEARN MORE
                            </a>
                        </button>
                    </div>
                    <Content style={{width: '100%', backgroundColor: '#f4f4f4'}}>
                        {/*What is Vieunite*/}
                        <Row>
                            <p className="homeDescriptionText">
                                {HOME_TEXT["Home Section title"]}
                            </p>
                        </Row>
                        <Row className="homeSection1">
                            <Col span={24} lg={12} style={{padding: "5%"}}>
                                <div className="section1TextContainer">
                                    <p className="homeSection1TitleText">Texture Accuracy Excellence.</p>
                                    <p className="homeSection1BodyText">{HOME_TEXT["Home Section 1"]}</p>
                                    <button
                                        className="homeSection1Button"
                                        onClick={_ => window.location.href = "/home/digital-canvas"}
                                    >
                                        <a className={'homeSection1ButtonLnk homeOverV'} href="/home/digital-canvas">
                                            LEARN MORE
                                        </a>
                                    </button>
                                </div>
                            </Col>

                            <Col span={24} lg={12}>
                                <img src={section1_pic} alt={'preview'}
                                     style={{width: "100%", height: "100%", objectFit: "cover"}}/>
                            </Col>
                        </Row>

                        <Row className="homeSection2">
                            <Col xs={24} md={12} xl={6}>
                                <div style={{width: "100%", padding: "4vw"}}>
                                    <p className="homeSection1TitleText" style={{textAlign: "center"}}>Simplicity</p>
                                    <p className="homeSection1BodyText" style={{textAlign: "center"}}>Textura was
                                        designed
                                        with user accessibility in mind
                                        and offers a simple setup and install process that requires little to no
                                        technical
                                        knowledge.</p>
                                </div>
                            </Col>
                            <Col xs={24} md={12} xl={6}>
                                <div style={{width: "100%", padding: "4vw"}}>
                                    <p className="homeSection1TitleText" style={{textAlign: "center"}}>Accuracy</p>
                                    <p className="homeSection1BodyText" style={{textAlign: "center"}}>The advanced
                                        texture-accurate display technology perfectly replicates artistic finishes such
                                        as
                                        brush strokes and preserves them for a lifelike aesthetic.</p>
                                </div>
                            </Col>
                            <Col xs={24} md={12} xl={6}>
                                <div style={{width: "100%", padding: "4vw"}}>
                                    <p className="homeSection1TitleText" style={{textAlign: "center"}}>Efficiency</p>
                                    <p className="homeSection1BodyText" style={{textAlign: "center"}}>Schedule times for
                                        the
                                        digital canvas to turn on and off automatically, only using power when needed
                                        and
                                        improving the energy efficiency.</p>
                                </div>
                            </Col>
                            <Col xs={24} md={12} xl={6}>
                                <div style={{width: "100%", padding: "4vw"}}>
                                    <p className="homeSection1TitleText" style={{textAlign: "center"}}>Transparency</p>
                                    <p className="homeSection1BodyText" style={{textAlign: "center"}}>There are no
                                        subscription costs associated with the Textura digital canvas or the Vieunite
                                        app
                                        for the entire lifetime of the product.</p>
                                </div>
                            </Col>
                        </Row>

                        <Row className="homeSection3Row">
                            <div className="homeSection2Background">
                                <div
                                    className="homeSection3TextWidth"
                                    style={{
                                        height: "100%",
                                        position: "relative",
                                        display: "flex",
                                        flexDirection: 'column-reverse',
                                        color: "white",
                                        padding: "2.5rem"
                                    }}>
                                    <button
                                        className="homeMainButton" style={{width: "100%"}}
                                        onClick={_ => window.location.href = "/art?page=artists"}
                                    >
                                        <a className={'homeMainButtonLnk homeOverV'}>
                                            LEARN MORE
                                        </a>
                                    </button>
                                    <p className="homeSection1BodyText">{HOME_TEXT["Home Section 2"]}</p>
                                    <p className="homeSection1TitleText">Huge collection of exceptional artwork.</p>
                                    <div className="homeSection3Height"></div>
                                </div>
                            </div>
                        </Row>

                        <Row style={{margin: '0 9vw 1rem 9wv'}}>
                            <h2 className="title-logo" style={{
                                marginTop: '4rem',
                                fontSize: '1.5rem',
                                lineHeight: '36px',
                                width: '100%',
                                textAlign: 'center'
                            }}
                            >
                                IN PARTNERSHIP WITH
                            </h2>
                        </Row>

                        <Row style={{margin: '2rem 9vw 6rem 9vw'}}>
                            <div className={'landingPartnershipContainer'} style={{width: "100%"}}>
                                <img onClick={() => {
                                    window.open("https://rbsa.org.uk/")
                                }}
                                     alt={'logo'} className={'logo-img landingLogo'} src={RBSALogo}
                                     style={{objectFit: 'contain', width: '350px'}}></img>

                                <img onClick={() => {
                                    window.open("https://www.nationalgalleries.org/")
                                }}
                                     alt={'logo'} className={'logo-img landingLogo'} src={NationalGalleryLogo}
                                     style={{objectFit: 'contain', width: '350px'}}></img>

                                <img onClick={() => {
                                    window.open("https://www.estorickcollection.com")
                                }}
                                     alt={'logo'} className={'logo-img landingLogo'} src={EstorikLogo}
                                     style={{objectFit: 'contain', width: '350px'}}></img>
                            </div>
                            {/*<Col*/}
                            {/*    span={12}*/}
                            {/*    lg={6}*/}
                            {/*    style={{display: "flex", justifyContent: "center", alignItems: "center"}}>*/}
                            {/*    <img onClick={() => {*/}
                            {/*        window.open("https://www.ukri.org/councils/innovate-uk/")*/}
                            {/*    }}*/}
                            {/*         alt={'logo'} className={'logo-img'} src={UKTILogo}></img>*/}
                            {/*</Col>*/}
                            {/*<Col*/}
                            {/*    span={12}*/}
                            {/*    lg={6}*/}
                            {/*    style={{display: "flex", justifyContent: "center", alignItems: "center"}}>*/}
                            {/*    <img onClick={() => {*/}
                            {/*        window.open("https://rbsa.org.uk/")*/}
                            {/*    }}*/}
                            {/*         alt={'logo'} className={'logo-img'} src={RBSALogo}></img>*/}
                            {/*</Col>*/}
                            {/*<Col*/}
                            {/*    span={12}*/}
                            {/*    lg={6}*/}
                            {/*    style={{display: "flex", justifyContent: "center", alignItems: "center"}}>*/}
                            {/*    <img onClick={() => {*/}
                            {/*        window.open("https://www.ntu.ac.uk/study-and-courses/academic-schools/art-and-design")*/}
                            {/*    }}*/}
                            {/*         alt={'logo'} className={'logo-img'} src={NTULogo}></img>*/}
                            {/*</Col>*/}
                            {/*<Col*/}
                            {/*    span={12}*/}
                            {/*    lg={6}*/}
                            {/*    style={{display: "flex", justifyContent: "center", alignItems: "center"}}>*/}
                            {/*    <img onClick={() => {*/}
                            {/*        window.open("https://royalsociety.org/")*/}
                            {/*    }}*/}
                            {/*         alt={'logo'} className={'logo-img'} src={RoyalLogo}></img>*/}
                            {/*</Col>*/}
                        </Row>

                        <Row style={{margin: '0 6vw 0 6vw'}}>
                            <Col span={24} md={12} style={{padding: "0 3vw 3rem 3vw"}}>
                                <img src={section3_1_img} alt={'preview'}
                                     style={{width: "100%", paddingBottom: "20px"}}/>
                                <p className="homeSection1TitleText">Access Curated Art and Artists.</p>
                                <p className="homeSection1BodyText">{HOME_TEXT["Home Section 4_1"]}</p>
                            </Col>
                            <Col span={24} md={12} style={{padding: "0 3vw 3rem 3vw"}}>
                                <img src={section3_2_img} alt={'preview'}
                                     style={{width: "100%", paddingBottom: "20px"}}/>
                                <p className="homeSection1TitleText">Display Personal Photos and Videos.</p>
                                <p className="homeSection1BodyText">{HOME_TEXT["Home Section 4_2"]}</p>
                            </Col>
                        </Row>

                        {/* Image gallery section */}
                        <Row>
                            <div className="homeTexturaExampleContainer-jack">
                                <p className="homeSection1TitleText" style={{color: "white", paddingBottom: "2vw"}}>
                                    See Textura in Person
                                </p>
                                <div className="example-section-container-jack">
                                    <div ref={sliderRef}
                                         className="example-section-slider-jack"
                                         style={{
                                             transform: windowWidth > 920 ?
                                                 `translateX(-${index * 100 / itemsPerPage}%)` :
                                                 `translateX(-${index * 80}vw)`,
                                         }}
                                         onTouchStart={handleDragStart}
                                         onTouchMove={handleDragMove}
                                         onTouchEnd={handleDragEnd}
                                    >
                                        {images.map((image, imageIndex) => (
                                            <div key={imageIndex} className="example-section-image-container-jack">
                                                <Col key={index}
                                                     onClick={() =>
                                                         handleImageClick(image.src, image.alt, image.mapEmbedUrl, image.mapTitle, image.mapAddress, image.mapCity, image.mapPostCode, image.directions, image.storeUrl, image.popupImg)}>
                                                    <img src={image.src} alt={image.alt} style={{cursor: 'pointer'}}
                                                         className="example-section-image-jack"/>
                                                </Col>
                                                <div className="example-section-overlay">
                                                    <div className="example-section-text">{image.alt}</div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    <div style={{
                                        display: 'flex',
                                        justifyContent: windowWidth > 920 ? 'space-between' : 'center',
                                        alignItems: 'center',
                                        marginTop: '20px'
                                    }}>
                                        <img className={'m-no'} src={leftScroll} alt="Scroll Left" onClick={slideLeft}
                                             style={{cursor: 'pointer'}}/>
                                        <div style={{display: 'flex', alignItems: 'center'}}>
                                            {Array.from({length: totalLines}).map((_, idx) => (
                                                <div key={idx} style={{margin: '0 2px'}}>
                                                    {activeLineIndex === idx ? <ScrollActive/> : <ScrollInactive/>}
                                                </div>
                                            ))}
                                        </div>
                                        <img className={'m-no'} src={rightScroll} alt="Scroll Right"
                                             onClick={slideRight}
                                             style={{cursor: 'pointer'}}/>
                                    </div>

                                </div>
                            </div>
                        </Row>

                        <Row>
                            <div className="homeSupportTextContainer">
                                <p className="homeSection1TitleText" style={{textAlign: "center"}}>Get Support</p>
                                <p className="homeSupportBody">If you have questions about the Textura digital canvas or
                                    Vieunite app that aren't
                                    answered here, or if you encounter any issues with your product, explore our
                                    dedicated support resources or reach out to us for assistance.</p>
                                <button
                                    className="homeSupportButton"
                                    onClick={_ => window.location.href = "/home/support"}
                                >
                                    <a className={'homeSupportButtonLnk homeOverV'}>
                                        SUPPORT
                                    </a>
                                </button>
                            </div>
                        </Row>
                    </Content>
                </Content>
            </Layout>

            {
                isMobile ?
                    <Modal
                        title={null}
                        visible={isModalVisible}
                        onOk={handleModalClose}
                        onCancel={handleModalClose}
                        footer={null}
                        // width={600}
                        // height={800}
                        className="custom-modal-Jack"
                        closeIcon={<CloseIcon/>}
                    >
                        <Row gutter={16} align='middle'>
                            <Col span={30} className="modal-directions">
                                <div className='Mo-MapTitle'>{modalContent.mapTitle}</div>
                                <div className='Mo-MapAddress'>{modalContent.mapAddress}</div>
                                <div className='Mo-MapCity'>{modalContent.mapCity}</div>
                                <div className='Mo-MapPostcode'>{modalContent.mapPostCode}</div>
                                <button
                                    className="homeMainButton black-border-hover-white Mo-MapButton"
                                    onClick={_ => window.open(modalContent.storeUrl, "_blank")}>
                                    <p className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                                        VISIT STORE WEBSITE
                                    </p>
                                </button>
                                <div className='Mo-MapButtonText'>Please check opening hours on their website</div>
                                <div className='Mo-popMap'>
                                    <iframe
                                        src={modalContent.mapEmbedUrl}
                                        width="100%"
                                        height="400"
                                        style={{
                                            border: '0',
                                            borderRadius: '1.438rem'
                                        }} // Added border radius as per design
                                        allowFullScreen=""
                                        loading="lazy"
                                    ></iframe>
                                </div>
                                {/*<div className="MapTexDic">Textura Directions</div>*/}
                                {/*<div className="MapTexDicContent">{modalContent.directions}</div>*/}
                                {modalContent.mapTitle === 'RBSA Gallery | Birmingham' ||
                                modalContent.mapTitle === 'Selfridges | London' ||
                                modalContent.mapTitle === 'Estorick Collection | London' ? (
                                    <>
                                        <div className="MapTexDic">Textura Directions</div>
                                        <div className="MapTexDicContent">{modalContent.directions}</div>
                                    </>
                                ) : null}
                                <img src={modalContent.popupImg} style={{width: '100%', marginTop: '1.25rem'}}/>
                            </Col>
                        </Row>
                    </Modal>
                    :
                    <Modal
                        title={null}
                        visible={isModalVisible}
                        onOk={handleModalClose}
                        onCancel={handleModalClose}
                        footer={null}
                        width={1200}
                        className="custom-modal-Jack"
                        closeIcon={<CloseIcon/>}
                    >
                        <Row gutter={16} align='middle'>
                            <Col span={8} className="modal-directions">
                                <div className='MapTitle'>{modalContent.mapTitle}</div>
                                <div className='MapAddress'>{modalContent.mapAddress}</div>
                                <div className='MapCity'>{modalContent.mapCity}</div>
                                <div className='MapPostcode'>{modalContent.mapPostCode}</div>
                                <button
                                    className=" homeMainButton black-border-hover-white "
                                    onClick={_ => window.open(modalContent.storeUrl, "_blank")}>
                                    <p className={'homeMainButtonLnk homeOverV'} style={{margin: 0}}>
                                        VISIT STORE WEBSITE
                                    </p>
                                </button>
                                <div className='MapButtonText'>Please check opening hours on their website</div>
                                {/*<div className="MapTexDic">Textura Directions</div>*/}
                                {/*<div className="MapTexDicContent">{modalContent.directions}</div>*/}
                                {modalContent.mapTitle === 'RBSA Gallery | Birmingham' ||
                                modalContent.mapTitle === 'Selfridges | London' ||
                                modalContent.mapTitle === 'Estorick Collection | London' ? (
                                    <>
                                        <div className="MapTexDic">Textura Directions</div>
                                        <div className="MapTexDicContent">{modalContent.directions}</div>
                                    </>
                                ) : null}
                                <img src={modalContent.popupImg} style={{width: '100%', marginTop: '1.25rem'}}/>
                            </Col>
                            <Col span={16} className="modal-map-Jack" style={{height: '62vh'}} align='middle'>
                                <iframe
                                    src={modalContent.mapEmbedUrl}
                                    width="100%"
                                    height="100%"
                                    style={{border: '0', borderRadius: '1.438rem'}} // Added border radius as per design
                                    allowFullScreen=""
                                    loading="lazy"
                                ></iframe>
                            </Col>
                        </Row>
                    </Modal>
            }
        </div>
    );
}

export default HomePage;